import React, { useEffect, useState } from "react";
import { selectCustomTheme } from "../../helpers/constants";
import { ThemeProvider } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { APIurls } from "../../api/apiConstant";
import { useAuth } from "../../store/AuthContext";
import { useQuery } from "react-query";
import DownloadExcel from '../../exportData/ExportExcel'
import pdfLogo from '../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../exportData/ExportPDF'
import { ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import { SlCheck } from "react-icons/sl";
import {
    IconButton,
} from "@material-tailwind/react";
import moment from "moment";
import UpdatePayOutModalStatus from "../AdminModules/PayOuts/UpdatePayOutStatusModal";

export default function AdminPayOuts() {

    const [city, setCity] = useState("");
    const [club, setClub] = useState("");
    const [singleDate, setSingleDate] = useState(() => {
        const obj1 = new Date();
        obj1.setDate(obj1.getDate() - 1);
        const convertedDate = obj1.getDate() < 10 ? `0${obj1.getDate()}` : obj1.getDate();
        const convertedMonth = obj1.getMonth() + 1 < 10 ? `0${obj1.getMonth() + 1}` : obj1.getMonth() + 1;
        return `${obj1.getFullYear()}-${convertedMonth}-${convertedDate}`;
    });
    const [clubDropdown, setClubDropdown] = useState([]);
    const [resetFilter, setResetFilter] = useState(false);
    const [searchData, setSearchData] = useState("");
    const [loading, setLoading] = useState(false);
    const [adminName, setAdminName] = useState("");
    const [filteredBookingData, setFilteredBookingData] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [payoutStatusModal, setPayoutStatusModal] = useState(false);
    const [clubMongoId, setClubMongoId] = useState("");

    const { getAccessToken, userRole } = useAuth();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])

    const fetchLocationsInPayouts = async () => {
        const token = await getAccessToken();
        const response = await fetch(`${APIurls.fetchAllLocationsForSubscriptions}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.message);
        }
        return result?.response?.cities;
    };

    const { data: locationsData, refetch } = useQuery("locationsDataPayouts", fetchLocationsInPayouts);
    // console.log("Locations in Admin Payouts--->", locationsData)

    const fetchAllClubsInPayouts = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAllClubsBasedLocation}?location=${city}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const result = await response.json();
                throw new Error(result?.message);
            }
            const result = await response.json();
            setClubDropdown(result?.response?.clubs);
            console.log("Clubs Based on Location In Payouts--->", result?.response);
        } catch (error) {
            console.log("Error while fecthing clubs based on Location in Payouts", error)
        }
    }

    useEffect(() => {
        fetchAllClubsInPayouts();
    }, [city])

    const fetchOnlineClubsForPayouts = async () => {
        setIsLoading(true);
        const token = await getAccessToken();
        const response = await fetch(`${APIurls.fetchOnlineClubsInPayOuts}?city=${city}&club=${club}&singleDate=${singleDate}&page=${currentPage}&limit=${recordsPerPage}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.message)
        }
        console.log("Online Clubs Data in Pay Outs--->", result?.response);
        setIsLoading(false);
        return result?.response;
    }

    const { data: onlineClubsData, refetch: clubRefetch } = useQuery(["onlineClubs", club, singleDate, currentPage, recordsPerPage], fetchOnlineClubsForPayouts);

    useEffect(() => {
        if (resetFilter) {
            refetch();
            setResetFilter(false);
        }
    }, [resetFilter]);

    useEffect(() => {
        setClub("");
    }, [city])

    const handleUpdateStatus = async (id, status) => {
        setClubMongoId(id);
        setPayoutStatusModal(true);
    }

    const fetchAdminDetails = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            setAdminName(result?.response?.name)
        } catch (error) {
            console.log("Error in fetching admin details", error);
        }
    }

    useEffect(() => {
        fetchAdminDetails();
    }, [])

    const handleResetFilter = () => {
        setCity("");
        setClub("");
        setSingleDate("")
        setResetFilter(true)
    };

    const handleSearch = (e) => {
        setSearchData(e.target.value);
    };

    useEffect(() => {
        let mapData = onlineClubsData?.payouts?.map((item) => {
            return { ...item }
        })
        if (searchData !== "") {
            mapData = mapData?.filter((item) =>
                item?.clubId?.ClubName?.toLowerCase().includes(searchData.toLowerCase())
            );
        }
        setFilteredBookingData(mapData)
    }, [onlineClubsData, searchData])

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                // padding: "10px 10px",
                color: "#4D4D4D",
                fontSize: "13px",
            },
        },
        head: {
            style: {
                // borderRadius: "6px 6px 0px 0px",
                // borderTop: "0.824px solid #E9E9E9",
                background: "#FFF",
                // fontSize: "15px",
            },
        },

        rows: {
            style: {
                // borderRadius: "6px 6px 0px 0px",
                // borderTop: "0.824px solid #E9E9E9",
                background: "#FFF",
                color: "#565656",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
            },
        },
        // pagination: {
        //    style: {
        //       boxShadow: "10px 5px 5px #ddd",
        //       marginBottom: "5px",
        //    },
        // },
        table: {
            style: {
                overflow: "visible",
                minWidth: "100px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto", // table scroll on x axis
            },
        },
    };

    const columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            center: true,
            width: "80px"
        },
        {
            name: "Club Name",
            selector: row => (
                <div>{row?.clubId?.ClubName}</div>
            ),
            center: true,
        },
        {
            name: "Total No.of Bookings",
            selector: row => (
                <NavLink to={`view/${row?._id}`}>
                    <div className="text-blue-500 border-b border-b-blue-500">{row?.totalBooking}</div>
                </NavLink>
            ),
            center: true,
        },
        {
            name: "Total Amount",
            selector: row => (
                <div>{row?.totalAmount}</div>
            ),
            center: true,
        },
        {
            name: "Date of Payment",
            selector: row => (
                moment(row?.date).format("MMM Do YY")
            ),
            center: true
        },
        {
            name: "Status",
            selector: row => (
                <div className={`${row?.status === "paid" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"} w-[7rem] flex justify-center items-center gap-[0.5rem] rounded-md font-semibold py-[0.4rem]`}>
                    <span className={`${row?.status === "paid" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                    <p className="capitalize">{row?.status}</p>
                </div>
            ),
            center: true
        },
        {
            name: "Remark",
            selector: row => (
                <div title={row?.remark}>{row?.remark?.length > 20 ? row?.remark?.slice(0, 20) + "..." : row?.remark || "-"}</div>
            ),
            center: true
        },

        (userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_PAYOUTS")) &&
        {
            name: "Actions",
            selector: row => (
                <div>
                    {row?.status === "unpaid" &&
                        <IconButton
                            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                            onClick={() => handleUpdateStatus(row?._id, row?.status)}
                        >
                            {row?.status === "unpaid" && <SlCheck className="text-[1.4rem] text-green-500 cursor-pointer" />}
                        </IconButton>
                    }
                </div>
            ),
            center: true
        }
    ]

    const convertImageToBase64 = async (imagePath) => {
        try {
            const response = await fetch(imagePath);
            const blob = await response.blob();

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error converting image to base64:', error);
            throw error;
        }
    };

    const handleDownloadPDF = async () => {
        setLoading(true);
        try {
            const base64String = await convertImageToBase64(gamebeezLogo);

            const pdfData = onlineClubsData?.payouts?.map((ele, index) => [
                index + 1,
                ele?.clubId?.ClubName,
                ele?.totalBooking,
                ele?.totalAmount,
                moment(ele?.date).format("MMM Do YY"),
                ele?.status,
            ]);

            const pdfColumns = ["SL", "Club Name", "Total No.of Bookings", "Total Amount", "Date of Payment", "Status"];

            await DownloadPDF(pdfData, pdfColumns, "Payouts.pdf", base64String, adminName);
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setLoading(false);
        }
    };

    const excelData = onlineClubsData?.payouts?.map((item, index) => [
        index + 1,
        item?.clubId?.ClubName,
        item?.totalBooking,
        item?.totalAmount,
        moment(item?.date).format("MMM Do YY"),
        item?.status,
        item?.remark || "-"
    ])

    const excelColumns = ["SL", "Club Name", "Total No.of Bookings", "Total Amount", "Date of Payment", "Status", "Remark"];

    const handleDirection = (direction) => {
        if (direction === "right") {
            const currPg = currentPage + 1;
            if (currentPage === onlineClubsData?.pagination?.totalPages) {
                setCurrentPage(onlineClubsData?.pagination?.totalPages);
            } else {
                setCurrentPage(currPg);
            }
        } else if (direction === "left") {
            const currPg = currentPage - 1;
            if (currentPage === 1) {
                setCurrentPage(1);
            } else {
                setCurrentPage(currPg);
            }
        }
    };

    return (
        <div className="bg-[#E6E6E6] px-[1.5rem] py-[1rem]">
            <div className="rounded-md bg-[#FFFFFF] px-[1rem] py-[0.5rem]">
                <div className="border-b border-[#EAECF0] text-[1.2rem] sm:text-[1.3rem] font-semibold pb-[0.5rem]">
                    Clubs Pay Outs
                </div>
                <ThemeProvider value={selectCustomTheme}>
                    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <form>
                        <div className="flex justify-between items-center mb-3 py-3 px-[1rem]">
                            <h3 className="text-black text-xl font-semibold">Filters</h3>
                            <button
                                type="button"
                                className="text-[0.8rem] sm:text-[1rem] border border-[#808080] max-xs:w-full text-[#000000] py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md"
                                onClick={handleResetFilter}
                            >
                                Reset Filters
                            </button>
                        </div>
                        <div className="flex gap-[3rem] items-baseline px-[2rem]">
                            <div className="flex flex-col gap-[1.5rem] flex-[2]">
                                <select
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
                                >
                                    <option hidden>Select City</option>
                                    {locationsData?.map((item, i) => (
                                        <option value={item} key={i}>{item}</option>
                                    ))}
                                </select>
                                <select
                                    value={club}
                                    onChange={(e) => setClub(e.target.value)}
                                    className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none"
                                >
                                    <option hidden>Select Club</option>
                                    {clubDropdown?.map((item) => (
                                        <option value={item?._id} key={item?._id}>{item?.ClubName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex flex-col gap-[1.5rem] flex-[2]">
                                <div className="border border-gray-300 rounded-md">
                                    <input
                                        type="date"
                                        className="w-full outline-none py-1 px-1"
                                        value={singleDate}
                                        onChange={(e) => setSingleDate(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="flex items-center px-[1rem] py-[2rem] justify-between">
                        <h2 className="text-black text-[1.3rem] font-semibold">All Clubs</h2>
                        <div className="flex items-center gap-2">
                            <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
                                <input
                                    type="text"
                                    className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                                    placeholder="Search by Club Name"
                                    onChange={handleSearch}
                                />
                                <Icon
                                    icon="ri:search-2-fill"
                                    className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
                                />
                            </div>
                            <div className='flex gap-3 items-center rounded-md'>
                                {loading ? (
                                    <p className="text-xs">Generating PDF...</p>
                                ) : (
                                    <img
                                        src={pdfLogo}
                                        className='w-[25px] cursor-pointer'
                                        onClick={handleDownloadPDF}
                                        alt="Generate PDF"
                                    />
                                )}
                                <FaFileCsv
                                    className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                                    onClick={() => DownloadExcel({ excelData, excelColumns })}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {isLoading ? <p className="text-xl text-center font-semibold">Loading...</p>
                            :
                            <DataTable
                                columns={columns}
                                customStyles={customStyles}
                                data={filteredBookingData}
                            />
                        }
                        {onlineClubsData?.pagination?.totalDocuments > 0 &&
                            <div className="flex justify-center items-center gap-2 py-2">
                                <button
                                    className={`${currentPage === 1 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                                    onClick={() => handleDirection("left")}
                                >Prev</button>
                                <span>
                                    {currentPage} / {Math.ceil(onlineClubsData?.pagination?.totalDocuments / recordsPerPage)}
                                </span>
                                <button
                                    className={`${currentPage === onlineClubsData?.pagination?.totalPages ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                                    onClick={() => handleDirection("right")}
                                >Next</button>
                                <div className="flex items-center">
                                    <span>Per Page:</span>
                                    <select
                                        value={recordsPerPage}
                                        onChange={(e) => { setRecordsPerPage(+e.target.value); setCurrentPage(1); }}
                                        className="border outline-none ml-2"
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={onlineClubsData?.pagination?.totalDocuments}>{onlineClubsData?.pagination?.totalDocuments}</option>
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                </ThemeProvider>
            </div>
            <ToastContainer />
            {payoutStatusModal &&
                <UpdatePayOutModalStatus
                    clubRefetch={clubRefetch}
                    setPayoutStatusModal={setPayoutStatusModal}
                    clubMongoId={clubMongoId}
                />
            }
        </div>
    );
}
