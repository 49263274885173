import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import moment from "moment";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { useQuery } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import DownloadExcel from '../../../../exportData/ExportExcel'
import pdfLogo from '../../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../../../exportData/ExportPDF'

export default function ViewCOCustomers() {

  const [loadingStates, setLoadingStates] = useState({});
  const [base64Logo, setBase64Logo] = useState("");
  const [loading, setLoading] = useState(false);
  const [adminName, setAdminName] = useState("");

  const { id } = useParams();
  const { getAccessToken } = useAuth();

  const fetchClubOwnerCustomerData = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchClubOwnerCustomerData}/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("View Club Owner Customer Data", result?.response?.customers);
    return result?.response?.customers.reverse();
  };
  const {
    data: customerData,
    isLoading,
    refetch,
  } = useQuery("clubSports", fetchClubOwnerCustomerData);

  const fetchAdminDetails = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      setAdminName(result?.response?.name)
    } catch (error) {
      console.log("Error in fecthing admin details", error);
    }
  }

  useEffect(() => {
    fetchAdminDetails();
  }, [])

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
        padding: "5px 0px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      id: "sl",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Customer ID",
      id: "customerId",
      selector: (row) => <p>{row.customerId}</p>,
      center: true,
      grow: 1,
    },
    {
      name: "User Name",
      id: "userName",
      selector: (row) => <p>{row.userName}</p>,
      center: true,
      grow: 1,
    },
    {
      name: "Mobile Number",
      id: "mobileNumber",
      selector: (row) => <p>{row.mobileNumber}</p>,
      center: true,
    },
    {
      name: "Email Address",
      id: "emailAddress",
      selector: (row) => <p>{row.email}</p>,
      center: true,
      grow: 1,
    },
    {
      name: "Date of Joining",
      id: "joiningDate",
      selector: (row) => <p>{moment(row.dateOfJoining).format("MMM Do YY")}</p>,
      center: true,
    },
    {
      name: "Offline Bookings",
      id: "offlineBookings",
      selector: (row) => <p>{row.offlineBookings}</p>,
      center: true,
    },
    {
      name: "Online Bookings",
      id: "onlineBookings",
      selector: (row) => <p>{row.onlineBookings}</p>,
      center: true,
    },
    {
      name: "Total Bookings",
      id: "totalBookings",
      selector: (row) => <p>{row.offlineBookings + row.onlineBookings}</p>,
      center: true,
    },
    {
      name: "Offline Booking Amount",
      id: "offlineBookingAmt",
      selector: (row) => <p>₹{row.offlineBookingsAmount}</p>,
      center: true,
    },
    {
      name: "Online Booking Amount",
      id: "onlineBookingAmt",
      selector: (row) => <p>₹{row.onlineBookingsAmount}</p>,
      center: true,
    },
    {
      name: "Total Amount",
      id: "totalAmount",
      selector: (row) => <p>₹{row.offlineBookingsAmount + row.onlineBookingsAmount}</p>,
      center: true,
    },
    {
      name: "Membership",
      id: "membership",
      selector: (row) => "Yes",
      center: true,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">
          <Link to={`view/${row.customerId}`}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
            </IconButton>
          </Link>
          <Link to={``}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
              <Icon
                icon="material-symbols:delete-rounded"
                className="text-[1.5rem] text-black"
              />
            </IconButton>
          </Link>
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  const convertImageToBase64 = async (imagePath) => {
    try {
      const response = await fetch(imagePath);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to base64:', error);
      throw error;
    }
  };

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const base64String = await convertImageToBase64(gamebeezLogo);
      setBase64Logo(base64String);

      const pdfData = customerData?.map((ele, index) => [
        index + 1,
        ele?.customerId,
        ele?.userName,
        ele?.mobileNumber,
        ele?.email,
        moment(ele?.dateOfJoining).format("MMM Do YY"),
        ele?.offlineBookings,
        ele?.onlineBookings,
        ele?.offlineBookings + ele?.onlineBookings,
        ele?.offlineBookingsAmount,
        ele?.onlineBookingsAmount,
        ele?.offlineBookingsAmount + ele?.onlineBookingsAmount,
      ]);

      const pdfColumns = ["SL", "Customer ID", "User Name", "Mobile No", "Email", "Date of Joining", "Offline Bookings", "Online Bookings", "Total Bookings", "Offline Booking Amount", "Online Booking Amount", "Total Amount"];

      await DownloadPDF(pdfData, pdfColumns, "Club Customers.pdf", base64String, adminName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
    setLoading(false);
  };

  const excelData = customerData?.map((item, index) => [
    index + 1,
    item?.customerId,
    item?.userName,
    item?.mobileNumber,
    item?.email,
    moment(item?.dateOfJoining).format("MMM Do YY"),
    item?.offlineBookings,
    item?.onlineBookings,
    item?.offlineBookings + item?.onlineBookings,
    item?.offlineBookingsAmount,
    item?.onlineBookingsAmount,
    item?.offlineBookingsAmount + item?.onlineBookingsAmount
  ])

  const excelColumns = ["SL", "Customer ID", "User Name", "Mobile No", "Email", "Date of Joining", "Offline Bookings", "Online Bookings", "Total Bookings", "Offline Booking Amount", "Online Booking Amount", "Total Amount"]

  return (
    <div className="bg-white rounded-md flex flex-col gap-[0.5rem]">
      <div className="flex items-center justify-between">
        <h2 className="text-[1.3rem] font-semibold">Customers</h2>
        <div className='flex gap-3 items-center rounded-md'>
          {loading ? (
            <p className="text-xs">Generating PDF...</p>
          ) : (
            <img
              src={pdfLogo}
              className='w-[25px] cursor-pointer'
              onClick={handleDownloadPDF}
              alt="Generate PDF"
            />
          )}
          <FaFileCsv
            className='w-[25px] h-[30px] text-green-800 cursor-pointer'
            onClick={() => DownloadExcel({ excelData, excelColumns })}
          />
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={customerData}
          pagination
          selectableRows
          progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <ToastContainer />
    </div>
  );
}
