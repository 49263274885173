import { clubOwnerSlice } from "./clubOwnerSlice";

export const clubOwnerApi = clubOwnerSlice.injectEndpoints({
  endpoints: (builder) => ({
    // ========== Club Owner Staff API Endpoints ===========
    getAllClubOwnerStaffs: builder.query({
      query: (id) => `/api/v2/admin/staff/${id}`,
      providesTags: ["ClubOwnerStaffs"],
    }),

    getSingleClubOwnerStaff: builder.query({
      query: (staffId) => `/api/v2/club/staff/${staffId}`,
      providesTags: ["ClubOwnerStaffs"],
    }),

    addClubOwnerStaff: builder.mutation({
      query: (clubOwnerStaffData) => {
        return {
          url: `/api/v2/create/staff-for-club`,
          method: "POST",
          body: clubOwnerStaffData,
        };
      },
      invalidatesTags: ["ClubOwnerStaffs"],
    }),

    updateClubOwnerStaff: builder.mutation({
      query: ({ clubStaffData, staffId }) => {
        return {
          url: `/api/v2/update/staff/${staffId}`,
          method: "PUT",
          body: clubStaffData,
        };
      },
      invalidatesTags: ["ClubOwnerStaffs"],
    }),

    deleteClubOwnerStaff: builder.mutation({
      query: ({ remark, staffId }) => {
        return {
          url: `/api/v2/staff/archive/${staffId}`,
          method: "POST",
          body: remark,
        };
      },
      invalidatesTags: ["ClubOwnerStaffs"],
    }),

    getClubOwnerStaffPermissions: builder.query({
      query: () => `api/clubs/permissions`,
      providesTags: ["Permissions"],
    }),

    getClubOwnerStaffRoles: builder.query({
      query: () => `api/v2/club/getCreatedRoles`,
      providesTags: ["StaffRoles"],
    }),

    //Rental API Endpoints
    getAllClubOwnerRentals: builder.query({
      query: (clubOwnerId) => `/api/v2/admin/rentals/${clubOwnerId}`,
    }),

    getSingleClubOwnerRental: builder.query({
      query: (rentalId) => `/api/v2/admin/getSpecificRental/${rentalId}`,
    }),

    //Canteen API Endpoints
    getAllClubOwnerCanteens: builder.query({
      query: (clubOwnerId) => `api/v2/admin/canteenTransactions/${clubOwnerId}`,
    }),

    getSingleClubOwnerCanteen: builder.query({
      query: (canteenId) => `api/v2/admin/getSpecificCanteen/${canteenId}`,
    }),

    //Dashboard API Endpoints
    getDashboardCardsAndJoinReports: builder.query({
      query: ({ connectionStatus, intervalType }) =>
        `api/v2/admin/getDashboardReports/${connectionStatus}/${intervalType}`,
    }),

    getDashBoardTopSportsReports: builder.query({
      query: ({ connectionStatus, intervalType }) =>
        `api/v2/admin/getDashBoardTopSports/${connectionStatus}?dateType=${intervalType}`,
    }),

    getDashBoardTopClubsReports: builder.query({
      query: ({ connectionStatus, intervalType }) =>
        `api/v2/admin/getDashBoardTopClubs/${connectionStatus}?dateType=${intervalType}`,
    }),

    //Reports/Bookings API Endpoints
    getTopBookingsReports: builder.query({
      query: ({ bookingStartDate, bookingEndDate, bookingActiveText }) =>
        `api/v2/admin/reports/bookings/cardsAndGraph?dateType=${bookingActiveText}&startDate=${bookingStartDate}&endDate=${bookingEndDate}`,
    }),

    getTopClubReports: builder.query({
      query: ({ clubStartDate, clubEndDate, clubActiveText }) =>
        `api/v2/admin/reports/bookings/topClubs?dateType=${clubActiveText}&startDate=${clubStartDate}&endDate=${clubEndDate}`,
    }),

    getTopSportReports: builder.query({
      query: ({ sportStartDate, sportEndDate, sportsActiveText }) =>
        `api/v2/admin/reports/bookings/topSports?dateType=${sportsActiveText}&startDate=${sportStartDate}&endDate=${sportEndDate}`,
    }),

    //Reports/Inventory API Endpoints
    getLatestIventoryReports: builder.query({
      query: ({
        latestInventoryStartDate,
        latestInventoryEndDate,
        latestInventoryActiveText,
      }) => {
        let query = null;
        if (latestInventoryActiveText) {
          query = `dateType=${latestInventoryActiveText}`;
        } else if (latestInventoryStartDate && latestInventoryEndDate) {
          query = `startInputDate=${latestInventoryStartDate}&endInputDate=${latestInventoryEndDate}`;
        }
        return `api/v2/admin/getInventoryReports?${query}`;
      },
    }),

    getPurchasedInventoryReports: builder.query({
      query: ({ puchasedInventoryActiveText }) =>
        `api/v2/admin/getInventoryPurchase/${puchasedInventoryActiveText}`,
    }),

    getTopIventoryReports: builder.query({
      query: ({
        topInventoryStartDate,
        topInventoryEndDate,
        topInventoryActiveText,
      }) => {
        let query = null;
        if (topInventoryActiveText) {
          query = `dateType=${topInventoryActiveText}`;
        } else if (topInventoryStartDate && topInventoryEndDate) {
          query = `startInputDate=${topInventoryStartDate}&endInputDate=${topInventoryEndDate}`;
        }
        return `api/v2/admin/getTopInventory?${query}`;
      },
    }),

    //Reports/Inventory API Endpoints
    getTopRentalsReports: builder.query({
      query: ({
        topRentalStartDate,
        topRentalEndDate,
        topRentalActiveText,
      }) => {
        let query = null;
        if (topRentalActiveText) {
          query = `dateType=${topRentalActiveText}`;
        } else if (topRentalStartDate && topRentalEndDate) {
          query = `startInputDate=${topRentalStartDate}&endInputDate=${topRentalEndDate}`;
        }
        return `api/v2/admin/getRentalsReports/${topRentalActiveText}`;
      },
    }),

    getRentedItemReports: builder.query({
      query: ({
        rentedItemStartDate,
        rentedItemEndDate,
        rentedItemActiveText,
      }) => {
        let query = null;
        if (rentedItemActiveText) {
          query = `dateType=${rentedItemActiveText}`;
        } else if (rentedItemStartDate && rentedItemEndDate) {
          query = `startInputDate=${rentedItemStartDate}&endInputDate=${rentedItemEndDate}`;
        }
        return `api/v2/admin/getMostRentedItem?${query}`;
      },
    }),

    //Reports/Expenses
    getHighestExpensesReports: builder.query({
      query: ({
        highestExpenseStartDate,
        highestExpenseEndDate,
        highestExpenseActiveText,
      }) => {
        let query = null;
        if (highestExpenseActiveText) {
          query = `dateType=${highestExpenseActiveText}`;
        } else if (highestExpenseStartDate && highestExpenseEndDate) {
          query = `startInputDate=${highestExpenseStartDate}&endInputDate=${highestExpenseEndDate}`;
        }
        return `api/v2/admin/getExpensesReports?${query}`;
      },
    }),

    getExpensesReports: builder.query({
      query: ({ expenseStartDate, expenseEndDate, expenseActiveText }) => {
        let query = null;
        if (expenseActiveText) {
          query = `dateType=${expenseActiveText}`;
        } else if (expenseStartDate && expenseEndDate) {
          query = `startInputDate=${expenseStartDate}&endInputDate=${expenseEndDate}`;
        }
        return `api/v2/admin/getExpenseRadicalChart?${query}`;
      },
    }),

    //Reports/Customer API Endpoints
    getTotalCustomerReports: builder.query({
      query: ({ totalCustomerActiveText }) =>
        `api/v2/admin/reports/customers/cardsAndGraph?dateType=${totalCustomerActiveText}`,
    }),

    getTopCustomerReports: builder.query({
      query: ({
        topCustomerStartDate,
        topCustomerEndDate,
        topCustomerActiveText,
      }) => {
        let query = null;
        if (topCustomerActiveText) {
          query = `dateType=${topCustomerActiveText}`;
        } else if (topCustomerStartDate && topCustomerEndDate) {
          query = `startDate=${topCustomerStartDate}&endDate=${topCustomerEndDate}`;
        }
        return `api/v2/admin/reports/customers/topClubs?${query}`;
      },
    }),

    // Offers API Endpoints
    getClubsBasedOnLocationsReports: builder.query({
      query: ({ selectedLocations }) => {
        const queryString = selectedLocations
          ?.map((state) => `state=${encodeURIComponent(state?.value)}`)
          ?.join("&");
        if (!queryString) {
          return 'api/v2/fetch/club-based-on-location?state=""';
        }
        return `api/v2/fetch/club-based-on-location?${queryString}`;
      },
    }),

    getAllClubOffers: builder.query({
      query: () => `api/v2/fetch-all-club-offer`,
      providesTags: ["ClubOffers"],
    }),

    getSingleClubOffer: builder.query({
      query: (offerId) => `api/v2/fetch-single-club-offer/${offerId}`,
      providesTags: ["ClubOffers"],
    }),

    createNewOffer: builder.mutation({
      query: (offerData) => {
        return {
          url: `api/v2/create-club-offer`,
          method: "POST",
          body: offerData,
        };
      },
      invalidatesTags: ["ClubOffers"],
    }),

    deleteClubOffer: builder.mutation({
      query: ({ remark, offerId }) => {
        return {
          url: `api/v2/delete-club-offer/${offerId}`,
          method: "DELETE",
          body: remark,
        };
      },
      invalidatesTags: ["ClubOffers"],
    }),

    updateClubOffer: builder.mutation({
      query: ({ updateData, clubOfferId }) => {
        return {
          url: `api/v2/update-club-offer/${clubOfferId}`,
          method: "PUT",
          body: updateData,
        };
      },
      invalidatesTags: ["ClubOffers"],
    }),

    // Banners API Endpoints
    getAllClubBanners: builder.query({
      query: () => `api/v2/all-banners`,
      providesTags: ["Banners"],
    }),

    getSingleClubBanner: builder.query({
      query: ({ bannerId }) => `api/v2/banner/${bannerId}`,
      providesTags: ["Banners"],
    }),

    getAllClubLocations: builder.query({
      query: () => `api/v2/fetch-all-club-location`,
    }),

    getClubsByLocations: builder.query({
      query: ({ selectedLocations }) => {

        const queryString = selectedLocations
          ?.map((state) => `state=${encodeURIComponent(state?.value)}`)
          ?.join("&");
        if (!queryString) {
          return 'api/v2/fetch/club-based-on-location?';
        }
        return `api/v2/fetch/club-based-on-location?${queryString}`;
      },
    }),

    getClubOffersByLocations: builder.query({
      query: ({ selectedLocations }) => {
        const queryString = selectedLocations
          ?.map((state) => `state=${encodeURIComponent(state?.value)}`)
          ?.join("&");
        if (!queryString) {
          return 'api/v2/fetch-all-offer-location?state=""';
        }
        return `/api/v2/fetch-all-offer-location?${queryString}`;
      },
    }),

    // Call API Conditionally for offers and clubs
    getClubsOrOffersConditionallyByLocations: builder.query({
      query: ({ selectedLocations, selectedBanner }) => {
        const queryString = selectedLocations
          ?.map((state) => `state=${encodeURIComponent(state?.value)}`)
          ?.join("&");

        if (!queryString) {
          return 'api/v2/fetch-all-offer-location?state=""';
        }

        if (selectedBanner.value === 'Offer') {
          return `/api/v2/fetch-all-offer-location?${queryString}`;
        } else if (selectedBanner.value === 'Club') {
          return `api/v2/fetch/club-based-on-location?${queryString}`;
        }
      },
    }),

    createNewClubBanner: builder.mutation({
      query: (bannerData) => {
        return {
          url: `api/v2/create-banner`,
          method: "POST",
          body: bannerData,
        };
      },
      invalidatesTags: ["Banners"],
    }),

    updateClubBanner: builder.mutation({
      query: ({ bannerId, formData }) => {
        return {
          url: `api/v2/banner/${bannerId}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Banners"],
    }),

    deleteClubBanner: builder.mutation({
      query: (bannerId) => {
        return {
          method: "DELETE",
          url: `api/v2/delete/banner/${bannerId}`,
        };
      },
      invalidatesTags: ["Banners"],
    }),

  }),
});

export const {
  useGetAllClubOwnerStaffsQuery,
  useGetSingleClubOwnerStaffQuery,
  useAddClubOwnerStaffMutation,
  useUpdateClubOwnerStaffMutation,
  useDeleteClubOwnerStaffMutation,
  useGetAllClubOwnerRentalsQuery,
  useGetSingleClubOwnerRentalQuery,
  useGetAllClubOwnerCanteensQuery,
  useGetSingleClubOwnerCanteenQuery,
  useGetClubOwnerStaffPermissionsQuery,
  useGetClubOwnerStaffRolesQuery,

  useGetDashboardCardsAndJoinReportsQuery,
  useGetDashBoardTopSportsReportsQuery,
  useGetDashBoardTopClubsReportsQuery,

  useGetTopBookingsReportsQuery,
  useGetTopClubReportsQuery,
  useGetTopSportReportsQuery,

  useGetLatestIventoryReportsQuery,
  useGetPurchasedInventoryReportsQuery,
  useGetTopIventoryReportsQuery,

  useGetTopRentalsReportsQuery,
  useGetRentedItemReportsQuery,

  useGetHighestExpensesReportsQuery,
  useGetExpensesReportsQuery,

  useGetTotalCustomerReportsQuery,
  useGetTopCustomerReportsQuery,

  useGetClubsBasedOnLocationsReportsQuery,
  useCreateNewOfferMutation,
  useGetAllClubOffersQuery,
  useDeleteClubOfferMutation,
  useGetSingleClubOfferQuery,
  useUpdateClubOfferMutation,

  useGetAllClubBannersQuery,
  useGetSingleClubBannerQuery,
  useGetAllClubLocationsQuery,
  useCreateNewClubBannerMutation,
  useUpdateClubBannerMutation,
  useDeleteClubBannerMutation,
  useGetClubsByLocationsQuery,
  useGetClubOffersByLocationsQuery,
  useGetClubsOrOffersConditionallyByLocationsQuery
} = clubOwnerApi;
