import React, { useState } from 'react'
import { Icon } from "@iconify/react";
import { AiOutlinePlus } from "react-icons/ai";
import DataTable from 'react-data-table-component';
import { IconButton } from "@material-tailwind/react";
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import { NavLink, useParams } from 'react-router-dom';
import OverViewAddTeamModal from './OverViewAddTeamModal';
import EditVenueModal from './EditVenueModal';
import OverViewEditTeamModal from './OverViewEditTeamModal';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useQuery } from 'react-query';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import DeletePartcipantModal from './DeletePartcipantModal';
import cameraImg from '../../../../assets/CameraImg.jpg'
import AboutTournamentModal from './AboutTournamentModal';
import { ThreeDots } from 'react-loader-spinner';
import OverViewEditPlayerModal from './OverViewEditPlayerModal';

export default function OverviewOfTournament() {

  const [AddTeamModal, setAddTeamModal] = useState(false)
  const [editVenueModal, setEditVenueModal] = useState(false)
  const [editTeamModal, setEditTeamModal] = useState(false)
  const [venueId, setVenueId] = useState("")
  const [selectionType, setSelectionType] = useState('Participant')
  const [participantId, setPartcipantId] = useState("")
  const [deleteParticipantModal, setDeletPartcipantModal] = useState(false)
  const [aboutTournamentModal, setAboutTournamentModal] = useState(false)
  const [aboutTournament, setAboutTournament] = useState("")
  const [isSponsorDelete, setIsSponsorDelete] = useState({})
  const [editPlayerModal, setEditPlayerModal] = useState(false)
  const [playerName, setPlayerName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [isVenueDelete, setIsVenueDelete] = useState({});

  const { getAccessToken } = useAuth();
  const { tournamentId } = useParams();

  const fetchTournamentDetails = async () => {

    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchOverViewTournamentData}/${tournamentId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result?.message)
    }
    return result?.response
  }
  const { data: OverViewTournamentDetails, refetch: tournamentRefetch, isLoading: tournamentDetailsLoading } = useQuery(["OverViewDetails", tournamentId], fetchTournamentDetails)
  console.log("OverView Tournament Details Data", OverViewTournamentDetails?.tournamentDetails)

  const fetchTournamentVenues = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchVenues}/${tournamentId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result?.response?.venues
  }
  const { data: venuesData, isLoading: isVenuesLoading, refetch } = useQuery(['venues', tournamentId], fetchTournamentVenues)
  // console.log("Venues Data", venuesData)

  const fetchParticipants = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchParticipantsList}/${tournamentId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    // console.log("Participants Data", result?.response?.name);
    // const participantLength = result?.response?.participants?.length
    // setPartcipantNumber(participantLength);
    setSelectionType(result?.response?.selectionType)
    return result?.response;
  };

  const { data: participantsData, isLoading, refetch: participantsrefetch } = useQuery(["fetchParticipantsData", tournamentId], fetchParticipants)
  // console.log("Participants Data",participantsData)

  const fetchAllSponsors = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchAllSponsorsOrOrganizers}/${tournamentId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message)
    }
    return result?.response?.Sponsors
  }

  const { data: sponsorsData, refetch: sponsorRefetch, isLoading: isSponsorLoading } = useQuery(["allSponsors", tournamentId], fetchAllSponsors)
  // console.log("Sponsors Data--->", sponsorsData)

  const handleDeleteSpecificSponsor = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    let isConfirm = confirm("Are you sure you want to Delete this Sponsor?");
    if (isConfirm) {
      try {
        setIsSponsorDelete((prevState) => ({
          ...prevState,
          [id]: true,
        }))
        const token = await getAccessToken();
        const response = await fetch(`${APIurls.deleteSpecificSponsor}/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        const result = await response.json();
        if (!response.ok) {
          toast.error(result.message);
          throw new Error(result.message);
        }
        console.log("Sponsor Deleted Successfully", result?.response);
        sponsorRefetch();
        toast.success("Sponsor Deleted Successfully");
        return;
      } catch (error) {
        console.log("Error while deleting sponsor", error)
      }
      setIsSponsorDelete((prevState) => (
        {
          ...prevState,
          [id]: false,
        }))
    }
  }

  const handleAddTeamShowModal = () => {
    setAddTeamModal(true);
  }

  const handleEditVenueModal = (id) => {
    setEditVenueModal(true);
    setVenueId(id);
  }

  const handleEditTeamModal = (id, name) => {
    setEditTeamModal(true);
    setTeamName(name);
    setPartcipantId(id);
  }

  const handleDeleteVenueModal = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    let isConfirm = confirm("Are you sure you want to Delete this Venue?");
    if (isConfirm) {
      try {
        setIsVenueDelete((prevState) => ({
          ...prevState,
          [id]: true,
        }))
        const token = await getAccessToken();
        const response = await fetch(`${APIurls.deletingSpecificVenue}/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        const result = await response.json();
        if (!response.ok) {
          toast.error("Failed to delete a Venue");
          throw new Error(result);
        }
        console.log("Venue Deleted Successfully", result);
        refetch();
        toast.success("Venue Deleted Successfully");
      } catch (error) {
        console.error("Error in deleting venue", error.message);
      }
      setIsVenueDelete((prevState) => ({
        ...prevState,
        [id]: true,
      }))
    }
  }

  const handleDeletePartcipantModal = (id) => {
    setPartcipantId(id);
    setDeletPartcipantModal(true);
  }

  const handleShowMoreModal = () => {
    setAboutTournamentModal(true);
    setAboutTournament(OverViewTournamentDetails?.tournamentDetails?.description);
  }

  const handleEditPlayerModal = (name) => {
    setEditPlayerModal(true);
    setPlayerName(name);
  }

  const columns1 = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px",
      center: true
    },
    {
      name: "Venue Name",
      selector: row => (
        <div>{row?.venueName}</div>
      ),
      center: true
    },
    {
      name: "Club ID",
      selector: row => (
        <div>{row?.venueClubId}</div>
      ),
      center: true,
      grow: 1.5
    },
    {
      name: "Club Location",
      selector: row => (
        <div>{row?.clubLocation?.City}</div>
      ),
      center: true
    },
    {
      name: "Date Added",
      selector: row => (
        <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Action",
      selector: row => (
        <div className="flex space-x-2">
          <div onClick={() => handleEditVenueModal(row?._id)}>
            <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black"
              style={{ borderRadius: "5.55px", border: "0.925px solid #D9D9D9", background: "#FFF" }}
            >
              <Icon icon="material-symbols:edit-rounded" className="text-[1.5rem]" />
            </IconButton>
          </div>
          {
            isVenueDelete[row?._id] ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#FB6108"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <div onClick={() => handleDeleteVenueModal(row?._id)}>
                <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black"
                  style={{ borderRadius: "5.55px", border: "0.925px solid #D9D9D9", background: "#FFF" }}
                >
                  <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem]" />
                </IconButton>
              </div>
            )}
        </div>
      ),
      center: true
    }
  ]

  const columns2 = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px",
      center: true
    },
    {
      name: "List of Teams",
      selector: row => (
        <div>{row?.name}</div>
      ),
      center: true
    },
    {
      name: "Date Added",
      selector: row => (
        <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Updated Date",
      selector: row => (
        <div>{moment(row?.updatedAt).format("MMM Do YY")}</div>
      )
    },
    {
      name: "Actions",
      selector: row => (
        <div className="flex gap-[0.3rem]">
          <IconButton
            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            onClick={() => handleEditTeamModal(row?._id, row?.name)}
          >
            <Icon
              icon="material-symbols:edit-rounded"
              className="text-[1.5rem]"
            />
          </IconButton>
          {participantsData?.selectionType === "team" ?
            <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/viewTeamParticipants/${row?._id}`}>
              <IconButton
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
              >
                <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
              </IconButton>
            </NavLink>
            :
            ""
          }
          <div onClick={() => handleDeletePartcipantModal(row?._id)}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            >
              <Icon
                icon="material-symbols:delete-rounded"
                className="text-[1.5rem]"
              />
            </IconButton>
          </div>
        </div>
      ),
      center: true
    }
  ]

  const columns3 = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      width: "80px"
    },
    {
      name: "Player Name",
      selector: row => (
        <div>
          <p>Name:- {row?.name}</p>
          <p>Phone:- {row?.phone === null ? "NA" : row?.phone}</p>
          <p>Email:- {row?.email === null ? "NA" : row?.email}</p>
        </div>
      ),
      center: true,
      // grow: 2.5
    },
    {
      name: "Date Added",
      selector: row => (
        <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Updated Date",
      selector: row => (
        <div>{moment(row?.updatedAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Action",
      selector: row => (
        <div className='flex items-center gap-2'>
          <div>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
              onClick={() => handleEditPlayerModal(row?._id?.toString())}
            >
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem]"
              />
            </IconButton>
          </div>
          <div onClick={() => handleDeletePartcipantModal(row?._id)}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            >
              <Icon icon="material-symbols:delete-rounded" className="text-[1.5rem]" />
            </IconButton>
          </div>
        </div>
      ),
      center: true,
      grow: 1.5
    }
  ]

  const columns4 = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px",
      center: true
    },
    {
      name: "Organizer/Sponsor Name",
      selector: row => (
        <div className='flex items-center'>
          {row?.name}
          {
            row?.defaultSponsor === true ? <p className='text-red-500'>*</p> : ""
          }
        </div>
      ),
      center: true,
      grow: 2.5
    },
    {
      name: "Date Added",
      selector: row => (
        moment(row?.addedDate).format("MMM Do YY")
      ),
      center: true
    },
    {
      name: "Updated Date",
      selector: row => (
        moment(row?.updatedDate).format("MMM Do YY")
      ),
      center: true
    },
    {
      name: "Action",
      selector: row => (
        <div className="flex gap-[0.6rem]">
          <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/editOrganizerDetails/${row?.sponsorID}`}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            >
              <Icon icon="material-symbols:edit-rounded" className="text-[1.5rem]" />
            </IconButton>
          </NavLink>
          {
            isSponsorDelete[row?.sponsorID] ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#FB6108"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => handleDeleteSpecificSponsor(row?.sponsorID)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black rounded-lg border border-[#D9D9D9]"
              >
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem] text-black"
                />
              </IconButton>
            )}
        </div>
      ),
      center: true,
      grow: 3
    }
  ]



  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
        // background: "#EAECF0"
      },
    },
    head: {
      style: {
        background: "#EAECF0",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto",
      },
    },
  };

  return (
    <>
      {tournamentDetailsLoading ? <p className='text-2xl font-semibold flex items-center justify-center min-h-[20vh]'>Loading...</p>
        :
        <div className='border border-gray-200 p-3 rounded-md'>
          <div className='flex justify-between px-4'>
            <p className='text-xl font-semibold max-lg:text-[18px] max-sm:!text-[16px]'>Tournament Details</p>
            <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/editOverViewTournament`}>
              <div className='flex items-center text-white bg-[#FB6108] px-4 py-2 rounded-md gap-2 cursor-pointer max-md:px-3 max-md:text-[14px]'>
                <button>Edit</button>
                <Icon icon="material-symbols:edit-rounded" className="text-[1.2rem]" />
              </div>
            </NavLink>
          </div>
          <div className='flex justify-between p-4 max-xl:grid max-xl:grid-cols-3 max-xl:gap-8 max-sm:grid max-sm:!grid-cols-1 max-md:!gap-6'>
            <div className='flex justify-center'>
              <img src={(Array.isArray(OverViewTournamentDetails?.tournamentDetails?.BannerImg) && OverViewTournamentDetails?.tournamentDetails?.BannerImg.length > 0) ? OverViewTournamentDetails?.tournamentDetails?.BannerImg[OverViewTournamentDetails?.tournamentDetails?.BannerImg.length - 1] : OverViewTournamentDetails?.tournamentDetails?.BannerImg === null ? cameraImg : JSON.stringify(OverViewTournamentDetails?.tournamentDetails?.BannerImg) === JSON.stringify([]) ? cameraImg : ""}
                className='w-[200px] h-[200px] object-fill cursor-pointer'
                alt="tournamentImg"
              />
            </div>
            <div className='flex flex-col gap-6'>
              <div className=''>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.mainCategoryName}</p>
                <p className='text-[#808080] font-medium text-sm'>Main Category Sport</p>
              </div>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.sportName}</p>
                <p className='text-[#808080] font-medium text-sm'>Sport</p>
              </div>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.tournamentName}</p>
                <p className='text-[#808080] font-medium text-sm'>Tournament Name</p>
              </div>
            </div>
            <div className='flex flex-col gap-6'>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.subCategoryName}</p>
                <p className='text-[#808080] font-medium text-sm'>Sub Category Sport</p>
              </div>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.fixingType}</p>
                <p className='text-[#808080] font-medium text-sm'>Fixing Type</p>
              </div>
              <div>
                <div className=''>
                  {OverViewTournamentDetails?.tournamentDetails?.description?.length > 20 ?
                    <div className='flex items-center gap-1 max-lg:flex max-lg:flex-wrap'>
                      <p>{OverViewTournamentDetails?.tournamentDetails?.description?.substring(0, 15)}...</p>
                      <p className='text-[0.7rem] font-medium hover:text-primary hover:cursor-pointer'
                        onClick={handleShowMoreModal}
                      >Show More</p>
                    </div>
                    :
                    <p className='font-medium text-base'>{OverViewTournamentDetails?.tournamentDetails?.description === null ? "NA"
                      : OverViewTournamentDetails?.tournamentDetails?.description}
                    </p>
                  }
                </div>
                <p className='text-[#808080] font-medium text-sm'>About Tournament</p>
              </div>
            </div>
            <div className='flex flex-col gap-6'>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.tournamentType}</p>
                <p className='text-[#808080] font-medium text-sm'>Tournament Type</p>
              </div>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.startDate === null ? "NA"
                  : moment(OverViewTournamentDetails?.tournamentDetails?.startDate).format('L')
                }</p>
                <p className='text-[#808080] font-medium text-sm'>Start Date</p>
              </div>
            </div>
            <div className='flex flex-col gap-6'>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.participants}</p>
                <p className='text-[#808080] font-medium text-sm'>Partcipant Teams</p>
              </div>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.endDate === null ? "NA"
                  : moment(OverViewTournamentDetails?.tournamentDetails?.endDate).format("L")
                }</p>
                <p className='text-[#808080] font-medium text-sm'>End Date</p>
              </div>
            </div>
            <div>
              <div>
                <p className='font-medium text-xl max-lg:text-[16px]'>{OverViewTournamentDetails?.tournamentDetails?.format}</p>
                <p className='text-[#808080] font-medium text-sm'>Format</p>
              </div>
            </div>
          </div>
        </div>
      }
      <div className='border border-gray-200 p-3 rounded-md'>
        <div className='flex justify-between items-center px-4 py-2'>
          <p className='text-xl font-semibold max-lg:text-[18px]'>Venue</p>
          <div className='flex gap-4'>
            <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/createVenue`}>
              <div className='flex items-center text-white bg-[#FB6108] py-2 px-4 rounded-md gap-1 cursor-pointer max-lg:!px-2'>
                <AiOutlinePlus className="text-[1.2rem] max-md:!text-[16px]" />
                <button className='max-md:text-[14px]'>Add Venue</button>
              </div>
            </NavLink>
          </div>
        </div>
        <DataTable
          data={venuesData}
          columns={columns1}
          customStyles={customStyles}
          pagination
          paginationPerPage={10}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
          progressPending={isVenuesLoading}

        />
      </div>
      <div className='border border-gray-200 p-3 rounded-md'>
        <div className='flex justify-between px-4 py-2'>
          <p className='text-xl font-semibold max-md:text-[18px]'>{participantsData?.selectionType === "team" ? "List of Teams" : "List of Players"}</p>
          <div className='flex items-center text-white bg-[#FB6108] px-4 py-2 rounded-md gap-1 cursor-pointer max-md:!px-2'
            onClick={handleAddTeamShowModal}
          >
            <button className='max-md:text-[13px]'>{participantsData?.selectionType === "team" ? "Add Team" : "Add Player"}</button>
            <AiOutlinePlus className="text-[1.2rem] max-md:!text-[15px]" />
          </div>
        </div>
        {
          participantsData?.selectionType === "team" ?
            <DataTable
              data={participantsData?.participants?.sort((a, b) => a?.teamNumber - b?.teamNumber)}
              columns={columns2}
              customStyles={customStyles}
              pagination
              paginationPerPage={10}
              selectableRows
              selectableRowsComponent={CustomCheckbox}
              progressPending={isLoading}
            />
            :
            <DataTable
              data={participantsData?.participants?.sort((a, b) => a?.playerNumber - b?.playerNumber)}
              columns={columns3}
              customStyles={customStyles}
              pagination
              paginationPerPage={10}
              selectableRows
              selectableRowsComponent={CustomCheckbox}
              progressPending={isLoading}
            />
        }
      </div>
      <div className='border border-gray-200 p-3 rounded-md'>
        <div className='flex items-center justify-between px-4 py-2 flex-wrap'>
          <p className='text-xl font-semibold max-md:text-[18px]'>Organizer/Sponsor</p>
          <div className='flex items-center gap-4'>
            <NavLink to={`/tournaments/tournamentDetails/${tournamentId}/addOrganizer`}>
              <button className='flex items-center px-3 py-2 gap-1 bg-primary rounded-md text-white max-md:text-[13px] max-md:!px-2'>
                <AiOutlinePlus />
                Add Organizer/Sponsor
              </button>
            </NavLink >
          </div>
        </div>
        <DataTable
          data={sponsorsData}
          columns={columns4}
          customStyles={customStyles}
          pagination
          paginationPerPage={5}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
          progressPending={isSponsorLoading}
        />
        <div className='flex items-center text-[#344054]'>Note: "<p className='text-red-500 text-[20px]'>*</p>" indicates the default organizer/sponsor</div>
      </div>
      <ToastContainer />
      {AddTeamModal && (
        <OverViewAddTeamModal
          setShowModal={setAddTeamModal}
          participantsrefetch={participantsrefetch}
          tournamentRefetch={tournamentRefetch}
          number={participantsData?.participants?.length}
          selectionType={selectionType}
          tournamentId={tournamentId}
        />
      )}
      {editVenueModal && (
        <EditVenueModal
          setEditVenueModal={setEditVenueModal}
          venueId={venueId}
          venueRefetch={refetch}
        />
      )}
      {editTeamModal && (
        <OverViewEditTeamModal
          setEditTeamModal={setEditTeamModal}
          participantId={participantId}
          selectionType={selectionType}
          refetch={participantsrefetch}
          name={teamName}
        />
      )}
      {
        editPlayerModal && (
          <OverViewEditPlayerModal
            setEditPlayerModal={setEditPlayerModal}
            id={playerName}
            refetch={participantsrefetch}
          />
        )}
      {deleteParticipantModal && (
        <DeletePartcipantModal
          partcipantModal={setDeletPartcipantModal}
          participantId={participantId}
          participantsrefetch={participantsrefetch}
          tournamentRefetch={tournamentRefetch}
          tournamentId={tournamentId}
        />
      )}
      {aboutTournamentModal && (
        <AboutTournamentModal
          aboutTournament={aboutTournament}
          setAboutTournamentModal={setAboutTournamentModal}
        />
      )}
    </>
  )
}
