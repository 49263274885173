import React, { useEffect, useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { PulseLoader } from 'react-spinners';

export default function OverViewEditTeamModal({ setEditTeamModal, participantId, selectionType, refetch, name }) {
  
  const [teamName, setTeamName] = useState("")
  const [showError, setShowError] = useState(false);
  const [isLoading,setIsLoading] = useState(false)

  const { getAccessToken } = useAuth();
  console.log("Selection type--->",selectionType)

  const fetchSpecificParticipantDetails = async () =>{
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchSpecificParticipantDetails}/${participantId}/${selectionType}`,{
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const result = await response.json();
    if(!response.ok){
      throw new Error(result.message)
    }
    setTeamName(result?.response?.participants?.name)
    return result?.response
  }
  const { data: participantDetails} = useQuery("specificPartcipantDetails",fetchSpecificParticipantDetails)
  console.log("Specific Partcipant Details",participantDetails)

  const handleEditTeam = async () => {
    if ((!teamName)) {
      setShowError(true);
      return;
    }
    try {
      setIsLoading(true)
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.editParticipantName}/${participantId}`,{
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ teamName, selectionType })
      })
      const result = await response.json();
      if (!response.ok) {
        console.log("Error in Updating Participant Name", result)
        toast.error("Error in Updating Participant Name")
      }
      console.log("Participant Name Updated Successfully", result)
      refetch();
      setEditTeamModal(false);
      toast.success("Participant Name Updated Successfully")
    }
    catch (error) {
      console.log("Error while updating team name",error);
    }
    setIsLoading(false);
  }

  useEffect(()=>{
    setTeamName(name);
  },[])

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[35%] relative">
        <div className="flex justify-between items-center">
          <h1 className="text-[20px] font-bold">{selectionType === "team" ? "Edit Team" : "Edit Player"}</h1>
          <IconButton
            onClick={() => setEditTeamModal(false)}
            className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
          <div className="flex flex-col gap-[0.5rem]">
            <input
              className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
              type="text"
              placeholder="Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            {!teamName && showError && (
              <p className="text-red-600 text-sm">This field is required*</p>
            )}
          </div>
        <div className="w-[100px] flex items-center justify-center text-white bg-[#FB6108] px-4 py-2 cursor-pointer rounded-md gap-1"
          onClick={handleEditTeam}
        >
          {
            isLoading ? <PulseLoader color="white" size={8}/> : 
              <div className="flex justify-center items-center gap-1">
                <button type="button" className="text-[0.8rem] sm:text-[1rem] font-semibold">
                  Save
                </button>
                <PiGreaterThan />
              </div>
          }
        </div>
      </div>
    </div>
  );
}
