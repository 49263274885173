import React, { useEffect, useRef, useState } from "react";
import { selectCustomTheme } from "../../helpers/constants";
import { Option, Select, ThemeProvider } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import DatePicker from "react-flatpickr";
import Flatpickr from "react-flatpickr";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../helpers/CustomCheckbox";
import DataTable from "react-data-table-component";
import { useAuth } from "../../store/AuthContext";
import { useQuery } from "react-query";
import { APIurls } from "../../api/apiConstant";
import moment from "moment";
import DownloadExcel from '../../exportData/ExportExcel'
import pdfLogo from '../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../exportData/ExportPDF'

export default function AdminBookings() {
  //   const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [club, setClub] = useState("");
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [date, setDate] = useState("");
  const [cityDropdown, setCityDropdown] = useState([]);
  const [clubDropdown, setClubDropdown] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [base64Logo, setBase64Logo] = useState("");
  const [loading, setLoading] = useState(false);
  const [adminName, setAdminName] = useState("")
  const searchRef = useRef("");
  const [searchData, setSearchData] = useState("");

  const { getAccessToken, userRole } = useAuth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  const fetchBookingData = async () => {
    const token = await getAccessToken();

    try {
      const res = await fetch(
        `${APIurls.fetchAllMainBookingData}?location=${city}&club=${club}&category=${category}&startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();

      if (!res.ok) {
        throw new Error(data.message);
      }
      console.log("DATA FOR BOOKINGS FETCHED SUCCESSFULLY", data?.response);
      setCityDropdown(data?.response?.cities);
      setClubDropdown(data?.response?.clubNames);
      setCategoryDropdown(data?.response?.categories);
      return data?.response?.allBookings.reverse();
    } catch (error) {
      console.error("API FAILED TO FETCH BOOKING DATA ", error.message);
    }
  };

  console.log("Categories------------------->", categoryDropdown)

  const {
    data: mainBookingData,
    isLoading,
    refetch,
  } = useQuery("MainBooking", fetchBookingData);
  console.log("Main Booking Data-----------------", mainBookingData)

  useEffect(() => {
    refetch();
  }, [city, club, category, startDate, endDate, refetch]);

  useEffect(() => {
    if (resetFilter) {
      refetch();
      setResetFilter(false);
    }
  }, [resetFilter, refetch]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      refetch();
      console.log("CALLING API AGAIN FOR BOTH DATES");
    }
  }, [startDate, endDate, refetch]);

  const resetFilters = () => {
    setCategory("");
    setCity("");
    setClub("");
    setStartDate("");
    setEndDate("");
    setDate("");
    console.log("ITS IN RESET FUNCTION");
    setResetFilter(true);
  };

  const handleDateChange = (selectedDates) => {
    setDate(selectedDates);
    console.log(selectedDates.length);
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  const handleSearchBar = () => {
    console.log(searchRef.current.value);
    setSearchData(searchRef.current.value);
  };

  const filteredBookingData = mainBookingData?.filter((booking) =>

    booking?.BookingID?.toLowerCase().includes(
      searchData?.toLowerCase().replace(/\s+/g, "")
    )
  );

  const fetchAdminDetails = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      setAdminName(result?.response?.name)
    } catch (error) {
      console.log("Error in fetching admin details", error);
    }
  }

  useEffect(() => {
    fetchAdminDetails();
  }, [])

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const data = [
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
  ];

  const convertImageToBase64 = async (imagePath) => {
    try {
      const response = await fetch(imagePath);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to base64:', error);
      throw error;
    }
  };

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const base64String = await convertImageToBase64(gamebeezLogo);
      setBase64Logo(base64String);

      const pdfData = mainBookingData?.map((item, index) =>
        [
          index + 1,
          item?.BookingID,
          item?.clubName,
          item?.clubCategory,
          item?.title,
          item?.discountType,
          item?.discountAmount,
          item?.bookingType,
          moment(item?.dateOfBooking).format("MMM Do YY"),
          moment(item?.duration?.SelectedDate).format("MMM Do YY"),
          moment(new Date(item?.duration?.timeSlots[0])).format("LT") + "-" + moment(new Date(item?.duration?.timeSlots[item?.duration?.timeSlots.length - 1])).format("LT"),
          item?.status
        ]
      )

      const pdfColumns = ["SL", 'Booking ID', "Clubs", "Category", "Arena", "Discount Type", "Discount Amount", "Booking Type", "Date of Booking", "Selected Date of Booking", "Duration", "Status"]

      await DownloadPDF(pdfData, pdfColumns, "Bookings.pdf", base64String, adminName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const excelData = mainBookingData?.map((item, index) => [
    index + 1,
    item?.BookingID,
    item?.clubName,
    item?.clubCategory,
    item?.title,
    item?.discountType,
    item?.discountAmount,
    item?.bookingType,
    moment(item?.dateOfBooking).format("MMM Do YY"),
    moment(item?.duration?.SelectedDate).format("MMM Do YY"),
    moment(new Date(item?.duration?.timeSlots[0])).format("LTS") + "-" + moment(new Date(item?.duration?.timeSlots[item?.duration?.timeSlots.length - 1])).format("LT"),
    item?.status
  ])

  const excelColumns = ["SL", 'Booking ID', "Clubs", "Category", "Arena", "Discount Type", "Discount Amount", "Booking Type", "Date of Booking", "Selected Date of Booking", "Duration", "Status"]

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Booking Id",
      selector: (row) => <p>{row.BookingID}</p>,
      grow: 3
    },
    {
      name: "Clubs",
      selector: (row) => (
        <>
          <div className="flex flex-col">{row.clubName}</div>
          {/* <div className="flex flex-col">Yevis</div>
          <div className="flex flex-col">Vasu</div> */}
        </>
      ),
      center: true,
      grow: 2
    },
    {
      name: "Category",
      selector: (row) => (
        <>
          <div> {row.clubCategory} </div>
          {/* <div> Team Sports </div> */}
        </>
      ),
      center: true,
      grow: 2
    },
    {
      name: "Arena",
      selector: (row) => <div>{row.title}</div>,
      center: true,
      grow: 1
    },
    {
      name: "Discount Type",
      selector: (row) => <div>{row.discountType}</div>,
      center: true,
      grow: 2
    },
    {
      name: "Discount Amount",
      selector: (row) => <div>{row.discountAmount}</div>,
      center: true,
      grow: 1
    },
    {
      name: "Booking Type",
      selector: (row) => <div>{row.bookingType}</div>,
      center: true,
      grow: 2
    },
    {
      name: "Date of Booking",
      selector: (row) => <div>{moment(row.dateOfBooking).format("MMM Do YY")}</div>,
      center: true,
      grow: 2
    },
    {
      name: "Selected Date of Booking",
      selector: (row) => <div>{moment(row.duration.SelectedDate).format("MMM Do YY")}</div>,
      center: true,
      grow: 2
    },
    {
      name: "Duration",
      selector: (row) => {
        return (
          <div>
            <p className="mt-2">{moment(new Date(row?.duration?.timeSlots[0])).format("LTS")}</p>
            <p className="mt-2">{moment(new Date(row?.duration?.timeSlots[row?.duration?.timeSlots.length - 1])).format("LTS")}</p>
          </div>
        );
      },
      center: true,
      grow: 3.5,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] px-[0.8rem] ${row.status.toLowerCase() === "paid"
              ? "text-[#25BF17] bg-[#D1F0CF]"
              : "text-[#FF0023] bg-[#FDCAD1]"
            }`}
        >
          <span
            className={`${row.status.toLowerCase() === "paid"
              ? "bg-[#25BF17]"
              : "bg-[#FF0023]"
              } h-[0.5rem] w-[0.5rem] rounded-full`}
          ></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      sortable: true,
      grow: 2.2
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">
          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("VIEW_BOOKINGS")) &&
            <Link to={`view/${row.BookingID}`}>
              <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
                <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
              </IconButton>
            </Link>
          }
          {/* <Link to={``}>
                        <IconButton
                            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                        >
                            <Icon icon="material-symbols:print"
                                className="text-[1.5rem] text-black"
                            />
                        </IconButton>
                    </Link> */}
        </div>
      ),
      center: true,
    },
  ];

  return (
    <div className="bg-[#E6E6E6] px-[1.5rem] py-[1rem]">
      <div className="rounded-md bg-[#FFFFFF] flex-col justify-center items-center px-[1rem] py-[0.5rem]">
        <div className="">
          <div className="border-b border-[#EAECF0] text-[1.2rem] sm:text-[1.3rem] font-semibold pb-[0.5rem]">
            Bookings
          </div>
        </div>

        <div className="flex flex-col font-[500]">
          <div className="flex flex-col gap-5">
            <ThemeProvider value={selectCustomTheme}>
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
              <form>
                <div className="mb-3 py-3 bg-white rounded-tl-[5px] rounded-tr-[5px] flex justify-between items-center">
                  <div className="text-black text-xl font-semibold  leading-tight ">
                    Filters
                  </div>
                  <div className="justify-start items-center gap-10 flex">
                    <button
                      onClick={() => resetFilters()}
                      type="button"
                      className="text-[0.8rem] sm:text-[1rem] border-[1.20px] border-[#808080] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] text-[#000000] py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md"
                    >
                      <span className="whitespace-nowrap">Reset Filters</span>
                    </button>
                    <button
                      type="submit"
                      className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md"
                    >
                      <span className="whitespace-nowrap">Filter</span>
                      <Icon icon="ic:baseline-greater-than" />
                    </button>
                  </div>
                </div>

                <div className="flex gap-[3rem] items-baseline px-[2rem]">
                  <div className="flex flex-col gap-[1.5rem] flex-[2]">
                    {/* <div className="flex flex-col gap-[0.5rem]">
                                            <Select label='Select State ' color='blue-gray' size='lg'
                                                className='text-[0.75rem] sm:text-[1rem] text-black font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none'
                                                value={state}
                                                onChange={(value) => setState(value)}
                                            >
                                                <Option value='Manager'>West Bengal</Option>
                                                <Option value='Employee'>Tamil Nadu</Option>
                                                <Option value='Sub Admin'>Jharkhand</Option>
                                            </Select>
                                        </div> */}
                    <div className="flex flex-col gap-[0.5rem]">
                      {/* <Select
                        label="Enter City"
                        color="blue-gray"
                        size="lg"
                        className="text-[0.75rem] sm:text-[1rem] text-black font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                        value={city}
                        onChange={(value) => setCity(value)}
                      >
                        {cityDropdown.map((div) => (
                          <Option value={`${div}`}>{div}</Option>
                        ))}
                      </Select> */}
                      <select
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="custom-select block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      >
                        <option hidden>Select City</option>
                        {cityDropdown?.map((div, i) => (
                          <option key={i} value={div} className="text-gray-700">
                            {div}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col gap-[0.5rem]">
                      {/* <Select
                            label="Select Club"
                            color="blue-gray"
                            size="lg"
                            className="text-[0.75rem] sm:text-[1rem] text-black font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                            value={club}
                            onChange={(value) => setClub(value)}
                          >
                            {clubDropdown.map((div, i) => (
                              <Option key={i} value={div}>
                                {div}
                              </Option>
                            ))}
                          </Select> */}
                      <select
                        value={club}
                        onChange={(e) => setClub(e.target.value)}
                        className="custom-select block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      >
                        <option hidden>Select Club</option>
                        {clubDropdown?.map((div, i) => (
                          <option key={i} value={div}>
                            {div}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[1.5rem] flex-[2]">
                    <div className="flex flex-col gap-[0.5rem]">
                      <select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="custom-select block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                      >
                        <option hidden>Select Category</option>
                        {categoryDropdown?.map((div, i) =>
                          div !== null ? (
                            <option key={i} value={div}>
                              {div}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>
                    <div className="flex flex-col gap-[0.5rem]">
                      <div className="relative">
                        {/* <DatePicker
                          className="h-[2.63rem] outline-none placeholder-[#7F7F7F] rounded-lg pl-3 text-[1rem] text-black bg-[#EFF1F999] font-medium w-full"
                          placeholder="Start Date"
                          // selected={startDate}
                          value={startDate}
                          onChange={(date) => setStartDate(date)}
                        /> */}

                        <Flatpickr
                          value={date}
                          className="custom-select block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 relative"
                          placeholder="Select Date"
                          options={dateOptions}
                          onChange={handleDateChange}
                        />
                        <Icon
                          className="absolute right-3 top-[1.2rem]"
                          icon="uiw:date"
                        />
                      </div>
                    </div>
                    {/* <div className="flex flex-col gap-[0.5rem]">
                      <div className="relative">
                        <DatePicker
                          className="h-[2.63rem] outline-none placeholder-[#7F7F7F] rounded-lg pl-3 text-[1rem] text-black bg-[#EFF1F999] font-medium w-full"
                          placeholder="End Date"
                          selected={endDate}
                          value={endDate}
                          onChange={(date) => setEndDate(date)}
                        />
                        <Icon
                          className="absolute right-3 top-[0.8rem]"
                          icon="uiw:date"
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </form>
              <div className="flex items-center gap-8 border-t border-[#EAECF0] py-[1.5rem] justify-between">
                <div className="flex items-center gap-12">
                  <h2 className="text-black text-[1.3rem] font-semibold whitespace-nowrap">
                    All Bookings
                  </h2>
                  <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
                    <input
                      type="text"
                      className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                      placeholder="Search by ID or name or email or phone"
                      onChange={handleSearchBar}
                      ref={searchRef}
                    />
                    <Icon
                      icon="ri:search-2-fill"
                      className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
                    />
                  </div>
                </div>
                <div className='flex gap-3 items-center rounded-md'>
                  {loading ? (
                    <p className="text-xs">Generating PDF...</p>
                  ) : (
                    <img
                      src={pdfLogo}
                      className='w-[25px] cursor-pointer'
                      onClick={handleDownloadPDF}
                      alt="Generate PDF"
                    />
                  )}
                  <FaFileCsv
                    className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                    onClick={() => DownloadExcel({ excelData, excelColumns })}
                  />
                </div>
              </div>
              <div>
                <DataTable
                  columns={columns}
                  customStyles={customStyles}
                  data={filteredBookingData}
                  pagination
                  selectableRows
                  paginationPerPage={10}
                  progressPending={isLoading}
                  selectableRowsComponent={CustomCheckbox}
                />
              </div>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
