import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import { NavLink, useOutletContext } from "react-router-dom";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { useQuery } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import ArchiveDeleteTournamentModal from "./ArchiveDeleteTournamentModal";
import { RxCross2 } from "react-icons/rx";
import { ThreeDots } from "react-loader-spinner";

export default function Archived() {

  const { getAccessToken, userRole } = useAuth();
  const archived = "archived";
  const [city, club, mainCategory, subCategory, sport] = useOutletContext();

  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [tournamentId, setTournamentId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [isRetriveTournament, setIsTournamentRetrive] = useState({});

  const fetchArchievedData = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchAllTournaments}/${archived}?location=${city}&clubOwnerID=${club}&mainCategoryID=${mainCategory}&subCategoryID=${subCategory}&sportID=${sport}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    return result?.response?.tournaments;
  };

  const {
    data: archivedData,
    isLoading,
    refetch,
  } = useQuery("archivedData", fetchArchievedData);
  console.log("archivedData Data", archivedData);

  useEffect(() => {
    refetch();
  }, [city, club, mainCategory, subCategory, sport, refetch]);

  const handleRecoverTournament = async (id) => {
    try {
      setIsTournamentRetrive((prevState) => ({
        ...prevState,
        [id]: true,
      }))
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.recoverTournament}/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (!response.ok) {
        console.log("Failed to Retrive Tournament", result);
        toast.error(result?.message);
      }
      console.log("Tournament Retrived Successfully", result);
      refetch();
      toast.success("Tournament Retrived Successfully");
    } catch (error) {
      console.log("Error in Retriving tournament", error);
    }
    setIsTournamentRetrive((prevState) => ({
      ...prevState,
      [id]: false,
    }))
  }

  const handleDeleteTournamentModal = (id) => {
    setTournamentId(id);
    setDeleteModal(true);
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Tournament ID",
      selector: (row) => <div>{row?.tournamentID}</div>,
      center: true,
      grow: 2,
    },
    {
      name: "Title",
      selector: (row) => <div>{row?.title}</div>,
      center: true,
      grow: 1.5,
    },
    {
      name: "Venue",
      selector: (row) => <div>{row?.venue}</div>,
      center: true,
      grow: 1.5,
    },
    {
      name: "Category",
      selector: (row) => (
        <div>
          <p>{row.category?.mainCategory}</p>
          <p>{row.category?.subCategory}</p>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Format",
      selector: (row) => <div>{row?.tournamentType}</div>,
      center: true,
      grow: 2.3,
    },
    {
      name: "Players",
      selector: (row) => <p>{row?.participants}</p>,
      center: true,
    },
    {
      name: "Duration",
      selector: (row) => (
        <div>
          <p>
            {row?.duration?.start === null ? "-" : moment(row?.duration?.start).format("MMM Do YY")}
          </p>
          <p>
            {row?.duration?.end === null ? "-" : moment(row?.duration?.end).format("MMM Do YY")}
          </p>
        </div>
      ),
      center: true,
      grow: 1.7,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-bold
          py-[0.4rem] w-[7rem] ${row?.tournamentStatus === "ACTIVE"
              ? "text-[#25BF17] bg-[#D1F0CF]"
              : row?.tournamentStatus === "PENDING"
                ? "text-[#FFCE06] bg-[#F1ECE3]"
                : "text-[#FF0023] bg-[#FDCAD1]"
            }`}
        >
          <span
            className={`${row?.tournamentStatus === "ACTIVE"
              ? "bg-[#25BF17]"
              : row?.tournamentStatus === "PENDING"
                ? "bg-[#FFCE06]"
                : "bg-[#FF0023]"
              } h-[0.5rem] w-[0.5rem] rounded-full`}
          ></span>
          <h2>{row?.tournamentStatus}</h2>
        </div>
      ),
      grow: 2.4,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-2">

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("VIEW_TOURNAMENTS")) &&
            <NavLink to={`/tournaments/tournamentDetails/${row?.tournamentID}`}>
              <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]">
                <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
              </IconButton>
            </NavLink>
          }

          {
            isRetriveTournament[row?.tournamentID] ? (
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#FB6108"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <div
                title="Retrive"
                onClick={() => handleRecoverTournament(row?.tournamentID)}
              >
                <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg">
                  <Icon icon="ooui:restore" className="text-[1.2rem] text-black" />
                </IconButton>
              </div>
            )}

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("DELETE_TOURNAMENTS")) &&
            <div onClick={() => handleDeleteTournamentModal(row?.tournamentID)}>
              <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg">
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem]"
                />
              </IconButton>
            </div>
          }
        </div>
      ),
      center: true,
      grow: 3,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  }

  useEffect(() => {
    let mapData = archivedData?.map((item) => {
      return { ...item };
    });
    mapData = mapData?.filter((item) => {
      return (
        item?.tournamentID
          .toLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        item?.title
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase())
      );
    });
    setFilteredData(mapData);
  }, [archivedData, searchInput]);

  return (
    <div className="p-6">
      <div className="flex justify-between flex-wrap gap-[1rem]">
        <p className="text-xl font-semibold max-lg:text-[18px]">
          Archived Tournaments
        </p>
        <div className="flex gap-4 max-md:w-full max-md:justify-center max-md:items-center">
          <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
            <input
              type="text"
              className="w-[200px] max-md:w-[160px] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
              placeholder="Search by ID or Title"
              value={searchInput}
              onChange={handleSearch}
            />
            {searchInput !== "" ?
              <RxCross2
                className="text-[20px] text-white bg-primary cursor-pointer rounded-md"
                onClick={() => setSearchInput("")}
              />
              :
              <Icon
                icon="ri:search-2-fill"
                className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
              />
            }
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        customStyles={customStyles}
        selectableRows
        selectableRowsComponent={CustomCheckbox}
        pagination
        paginationPerPage={10}
        progressPending={isLoading}
      />
      <ToastContainer />
      {deleteModal && (
        <ArchiveDeleteTournamentModal
          setDeleteModal={setDeleteModal}
          tournamentId={tournamentId}
          refetch={refetch}
        />
      )}
    </div>
  );
}
