import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';

export default function SpecificCustomerSubscription() {

  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const { getAccessToken } = useAuth();
  const [id] = useOutletContext();

  const fetchSpecificCustomerSubscriptions = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchAllSpecificCustomerSubscriptions}/${id}?limit=${recordsPerPage}&page=${currentPage}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    if (!response.ok) {
      const result = await response.json();
      throw new Error(result?.message);
    }
    const result = await response.json();
    console.log("Specific Customer Subscriptions--->", result?.response);
    return result?.response
  }

  const { data: customerSubscriptionData, isLoading, refetch } = useQuery(["SpecificCustomerSubscriptionData", id], fetchSpecificCustomerSubscriptions);

  useEffect(() => {
    refetch();
  }, [currentPage, recordsPerPage])

  const columns = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      center: true,
      width: "80px"
    },
    {
      name: "Name of Subscription",
      selector: row => (
        <div>{row?.arenaId?.Areana_name}</div>
      ),
      center: true
    },
    {
      name: "Mode of Subscription",
      selector: row => (
        <div>{row?.offlineSub === true ? "Offline" : "Online"}</div>
      ),
      center: true
    },
    {
      name: "Date of Payment",
      selector: row => (
        <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Renewal Date",
      selector: row => (
        <div>{moment(row?.willRenewed).format("MMM Do YY")}</div>
      ),
      center: true
    },
    {
      name: "Status",
      selector: row => (
        <div className={`${row?.isActive === true ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"} flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[7rem]`}>
          <span className={`${row?.isActive === true ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          {row?.isActive === true ? "Active" : "Inactive"}
        </div>
      ),
      center: true
    },
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const handleDirection = (direction) => {
    if (direction === "right") {
      const currPg = currentPage + 1;
      if (currentPage === customerSubscriptionData?.totalPages) {
        setCurrentPage(customerSubscriptionData?.totalPages);
      } else {
        setCurrentPage(currPg);
      }
    } else if (direction === "left") {
      const currPg = currentPage - 1;
      if (currentPage === 1) {
        setCurrentPage(1);
      } else {
        setCurrentPage(currPg);
      }
    }
  };

  return (
    <div className='border-t border-[#EAECF0] pt-[1.5rem] pb-6 px-6'>
      <header className='text-xl font-semibold'>Specific Customer Subscriptions</header>
      <DataTable
        columns={columns}
        data={customerSubscriptionData?.subscriptions}
        customStyles={customStyles}
        selectableRows
        selectableRowsComponent={CustomCheckbox}
        progressPending={isLoading}
      />
      {customerSubscriptionData?.totalRecords > 0 &&
        <div className="flex justify-center items-center gap-2 py-2">
          {/* Left Arrow */}
          <button
            className={`${currentPage === 1 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
            onClick={() => handleDirection("left")}
          >Prev</button>
          <span>
            {currentPage} / {Math.ceil(customerSubscriptionData?.totalRecords / recordsPerPage)}
          </span>
          <button
            className={`${currentPage === customerSubscriptionData?.totalPages || customerSubscriptionData?.totalPages === 0 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
            onClick={() => handleDirection("right")}
          >Next</button>
          <div className="flex items-center">
            <span>Per Page:</span>
            <select
              value={recordsPerPage}
              onChange={(e) => { setRecordsPerPage(e.target.value); setCurrentPage(1); }}
              className="border outline-none ml-2"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={customerSubscriptionData?.totalRecords}>{customerSubscriptionData?.totalRecords}</option>
            </select>
          </div>
        </div>
      }

    </div>
  )
}
