import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, Tooltip } from "recharts";
import { PieChart, Pie, Cell } from "recharts";
import "react-datepicker/dist/react-datepicker.css";
import "tailwindcss/tailwind.css";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { IconButton } from "@material-tailwind/react";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { useQuery } from "react-query";
import { Select, Option } from "@material-tailwind/react";
import { RxCross2 } from "react-icons/rx";

const data = [
  {
    name: "12 am",
    uv: 1000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "2 am",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "4 am",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "6 am",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "8 am",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "12 pm",
    uv: 3490,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "6 pm",
    uv: 2400,
    pv: 4300,
    amt: 2100,
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Canteen = () => {

  const [custActiveText, setCustActiveText] = useState("MONTH");
  const [paymActiveText, setPaymActiveText] = useState("MONTH");
  const [clubWiseActiveText, setClubWiseActiveTect] = useState("MONTH");
  const [custstartDate, setCustStartDate] = useState("");
  const [custendDate, setCustEndDate] = useState("")
  const [clubStartDate, setClubStartDate] = useState("")
  const [clubendDate, setClubEndDate] = useState("")
  const [paymStartDate, setPaymStartDate] = useState("")
  const [paymEndDate, setPaymEndDate] = useState("")
  const [percentageType, setPercentageType] = useState("amount")
  
  const { getAccessToken } = useAuth()

  const fetchTopCustomers = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchCustomersForCanteenReports}?${custstartDate && custendDate ? `startDate=${custstartDate}&endDate=${custendDate}` : `dateType=${custActiveText}`}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    // console.log("Response for Customers", result?.response);
    return result?.response?.topCustomersCanteens;
  };
  const { data: topCustomersData, isLoading, refetch } = useQuery(["topCustomers", custstartDate, custendDate, custActiveText], fetchTopCustomers);

  const fetchClubSales = async () => {
    const token = await getAccessToken();
    console.log("ClubWise ActiveText--------->", clubWiseActiveText)
    const response = await fetch(`${APIurls.fetchClubWiseCanteenSalesData}?${clubStartDate && clubendDate ? `startDate=${clubStartDate}&endDate=${clubendDate}` : `dateType=${clubWiseActiveText}`}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("Club Wise Cateen Sales Data", result?.response?.topClubsSales);
    return result?.response?.topClubsSales;
  };

  const { data: clubWiseSalesData, isLoading: isclubLoading, refetch: clubRefetch } = useQuery(["clubwiseSales", clubStartDate, clubendDate, clubWiseActiveText], fetchClubSales);

  const fetchPaymentData = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchPaymentDataCanteenReports}?${paymStartDate && paymEndDate ? `startDate=${paymStartDate}&endDate=${paymEndDate}` : `dateType=${paymActiveText}`}&percentageType=${percentageType}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("Canteen Payment Data", result?.response?.paymentModePercentage);
    return result?.response?.paymentModePercentage;
  };

  const { data: paymentData, refetch: paymRefetch, isLoading: isPaymentLoading } = useQuery(["canteenPaymentData", paymStartDate, paymEndDate, paymActiveText, percentageType], fetchPaymentData);

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#00000",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 600,
        width: "auto",
      },
    },

    table: {
      style: {
        overflow: "visible",
        minWidth: "570px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "visible", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      grow: 1,
    },
    {
      name: "Customer Name",
      selector: (row) => <div>{row?.name}</div>,
      // width: ""
      grow: 2.5,
      // center: true,
    },
    {
      name: "Total Purchased",
      selector: (row) => <div>{row?.totalPurchased}</div>,
      grow: 2,
    },
    {
      name: "Total Purchased Amount",
      selector: (row) => <div>{Math.round(row?.totalPurchasedAmount)}</div>,
      grow: 2.6,
    },
  ];

  const columns2 = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      // center: true,
      maxWidth: "0.3rem",
    },
    {
      name: "Club Name",
      id: "clubName",
      selector: (row) => <div>{row?.clubName}</div>,
      // center: true,
    },
    {
      name: "Total Sales",
      selector: (row) => <div>{row?.totalSales}</div>,
    },
    {
      name: "Total Selling Price",
      selector: (row) => <div>{row?.totalSellingPrice}</div>,
    },
    {
      name: "Whole Price",
      selector: (row) => <div>{row?.totalWholePrice}</div>,
    },
    {
      name: "Total Profit",
      selector: (row) => <div>{row?.totalProfit}</div>,
    },
  ];

  useEffect(() => {
    if (custstartDate !== "" || custendDate !== "") {
      setCustActiveText("")
    }
  }, [custstartDate, custendDate])

  useEffect(() => {
    if (custActiveText !== "") {
      setCustStartDate("")
      setCustEndDate("")
    }
  }, [custActiveText])

  useEffect(() => {
    if (paymStartDate !== "" || paymEndDate !== "") {
      setPaymActiveText("")
    }
  }, [paymStartDate, paymEndDate])

  useEffect(() => {
    if (paymActiveText !== "") {
      setPaymStartDate("")
      setPaymEndDate("")
    }
  }, [paymActiveText])

  useEffect(() => {
    if (clubStartDate !== "" || clubendDate !== "") {
      setClubWiseActiveTect("")
    }
  }, [clubStartDate, clubendDate])

  useEffect(() => {
    if (clubWiseActiveText !== "") {
      setClubStartDate("")
      setClubEndDate("")
    }
  }, [clubWiseActiveText])

  return (
    <>
      <div className="flex justify-start items-start p-6">
        <div className="flex flex-col w-full items-center border border-gray-200 p-4 pb-2">
          <div className="w-full flex justify-between items-start pb-6">
            <p className="text-slate-950 text-xl font-semibold max-lg:text-[18px]">
              Top Customers
            </p>
            <div className="flex items-center gap-3 max-lg:flex max-lg:flex-col">
              <div className="flex gap-2">
                <div className="flex items-center">
                  <input
                    type="date"
                    value={custstartDate}
                    onChange={(e) => setCustStartDate(e.target.value)}
                    className="w-28 border rounded px-2 py-1 outline-none"
                    placeholder="From"
                  />
                </div>

                {/* To */}
                <input
                  type="date"
                  value={custendDate}
                  onChange={(e) => setCustEndDate(e.target.value)}
                  className="w-28 border rounded px-2 py-1 outline-none"
                  placeholder="To"
                />
              </div>
              {/* Week Month Year */}
              <div className="flex justify-center items-end mx-2">
                <div
                  className={`px-4 py-1 ${custstartDate !== "" || custendDate !== "" ? "bg-[#e3e1e1]"
                    : custActiveText === "DAY" ? "bg-primary text-white" : "bg-[#e3e1e1]"
                    }  cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => setCustActiveText("DAY")}
                  onDoubleClick={() => setCustActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold">
                    Day
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${custstartDate !== "" || custendDate !== "" ? "bg-[#e3e1e1]" :
                    custActiveText === "WEEK" ? "bg-orange-800 text-white" : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => setCustActiveText("WEEK")}
                  onDoubleClick={() => setCustActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Week
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${custstartDate !== "" || custendDate !== "" ? "bg-[#e3e1e1]" :
                    custActiveText === "MONTH" ? "bg-orange-800 text-white" : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justif-start items-center`}
                  onClick={() => setCustActiveText("MONTH")}
                  onDoubleClick={() => setCustActiveText("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Month
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={topCustomersData}
            pagination
            paginationPerPage={10}
            progressPending={isLoading}
          />
        </div>
      </div>
      <div className="items-center gap-2 box-border border border-gray-200 p-4 mx-6">
        <div className="flex justify-between items-center">
          <p className="text-slate-950 text-xl font-semibold max-lg:text-[18px]">Payments</p>
          <div className="">
            {/* Dates */}
            <div className="flex items-center gap-4 max-xl:flex max-xl:flex-col">
              {/* From */}
              <div className="flex gap-2">
                <input
                  type="date"
                  value={paymStartDate}
                  onChange={(e) => setPaymStartDate(e.target.value)}
                  className="w-28 border rounded px-2 py-1 outline-none"
                  placeholder="From"
                />
                {/* To */}
                <input
                  type="date"
                  value={paymEndDate}
                  onChange={(e) => setPaymEndDate(e.target.value)}
                  className="w-28 border rounded px-2 py-1 outline-none"
                  placeholder="To"
                />
              </div>
              {/* Week Month Year */}
              <div className="flex justify-center items-end mx-2">
                <div
                  className={`${paymStartDate !== "" || paymEndDate !== "" ? "bg-[#e3e1e1]" : paymActiveText === "DAY"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1] text-black"
                    } cursor-pointer px-4 py-1 rounded-sm justify-start items-center`}
                  onClick={() => setPaymActiveText("DAY")}
                  onDoubleClick={() => setPaymActiveText("")}
                >
                  <div className="text-base font-semibold ">Day</div>
                </div>
                <div
                  className={`${paymStartDate !== "" || paymEndDate !== "" ? "bg-[#e3e1e1]" : paymActiveText === "WEEK"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1] text-black"
                    } cursor-pointer px-4 py-1 rounded-sm justify-start items-center`}
                  onClick={() => setPaymActiveText("WEEK")}
                  onDoubleClick={() => setPaymActiveText("")}
                >
                  <div className="text-base font-semibold ">Week</div>
                </div>
                <div
                  className={`${paymStartDate !== "" || paymEndDate !== "" ? "bg-[#e3e1e1]" : paymActiveText === "MONTH"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1] text-black"
                    } cursor-pointer px-4 py-1 rounded-sm justify-start items-center`}
                  onClick={() => setPaymActiveText("MONTH")}
                  onDoubleClick={() => setPaymActiveText("")}
                >
                  <div className=" text-base font-semibold ">Month</div>
                </div>
              </div>
              <div className="hover:text-[#FB6108] cursor-pointer">
                <Select
                  label="Percentage Type"
                  value={percentageType}
                  className=""
                  onChange={(value) => setPercentageType(value)}
                >
                  <Option value="amount" disabled={percentageType === "amount"}>
                    Amount
                  </Option>
                  <Option value="transactions">Transactions</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          {isPaymentLoading ? <p className="text-xl font-semibold">Loading...</p>
            :
            <PieChart width={1000} height={250}>
              <Pie
                data={paymentData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={110}
                fill="#8884d8"
                dataKey="percentage"
              >
                {paymentData?.map((entry, index) => (
                  <Cell
                    key={`cell - ${index} `}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          }
        </div>
        <div className="flex flex-col gap-3 w-full px-2">
          {paymentData?.map((data, i) => {
            return (
              <div className="flex justify-between items-center gap-2 w-full">
                <div className="flex items-center gap-4">
                  <div
                    className={`w-6 h-6 rounded`}
                    style={{ backgroundColor: COLORS[i % COLORS.length] }}
                  />
                  <div className="text-black text-base font-medium ">
                    {data.paymentMethod} ({data.percentage}%)
                  </div>
                </div>
                <div className="flex items-center">
                  {percentageType === "transactions" ? "" : <FaIndianRupeeSign />}
                  <div className="text-slate-950 text-base font-semibold ">
                    {data.total}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex">
        <div className="w-full items-center border border-gray-200 p-4 m-6">
          <div className="flex justify-between items-start">
            {/* 1st Div */}
            <p className="text-slate-950 text-xl font-semibold max-lg:text-[18px]">
              Club wise Canteen Sales
            </p>
            {/* 2nd Div  */}
            {/* Dates */}
            <div className="flex items-center gap-2 p-2 max-lg:flex max-lg:flex-col">
              {/* From */}
              <div className="flex gap-2">
                <input
                  type="date"
                  value={clubStartDate}
                  onChange={(e) => setClubStartDate(e.target.value)}
                  className="w-28 border  rounded px-2 py-1 outline-none"
                  placeholder="From"
                />
                {/* To */}
                <input
                  type="date"
                  value={clubendDate}
                  onChange={(e) => setClubEndDate(e.target.value)}
                  className="w-28 border rounded px-2 py-1 outline-none"
                  placeholder="To"
                />
              </div>
              <div className="flex justify-center items-end mx-2">
                <div
                  className={`px-4 py-1 ${clubStartDate !== "" || clubendDate !== "" ? "bg-[#e3e1e1]" : clubWiseActiveText === "DAY"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => setClubWiseActiveTect("DAY")}
                  onDoubleClick={() => setClubWiseActiveTect("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Day
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${clubStartDate !== "" || clubendDate !== "" ? "bg-[#e3e1e1]" : clubWiseActiveText === "WEEK"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => setClubWiseActiveTect("WEEK")}
                  onDoubleClick={() => setClubWiseActiveTect("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Week
                  </div>
                </div>
                <div
                  className={`px-4 py-1 ${clubStartDate !== "" || clubendDate !== "" ? "bg-[#e3e1e1]" : clubWiseActiveText === "MONTH"
                    ? "bg-orange-800 text-white"
                    : "bg-[#e3e1e1]"
                    } cursor-pointer rounded-sm justify-start items-center`}
                  onClick={() => setClubWiseActiveTect("MONTH")}
                  onDoubleClick={() => setClubWiseActiveTect("")}
                >
                  <div className="text-neutral-600 text-base font-semibold ">
                    Month
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns2}
            customStyles={customStyles}
            data={clubWiseSalesData}
            pagination
            paginationPerPage={10}
            progressPending={isclubLoading}
          />
        </div>
      </div>
    </>
  );
};

export default Canteen;
