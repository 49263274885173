import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { Link, NavLink, useOutletContext, useParams } from 'react-router-dom'
import inventoryImg from '../../../../assets/inventory.png'
import { useAuth } from '../../../../store/AuthContext'
import { APIurls } from '../../../../api/apiConstant'
import { useQuery } from 'react-query'
import moment from 'moment'

export default function ViewCOInventoryView() {

   const { id, inventoryId } = useParams();
   const { getAccessToken } = useAuth()
   const [clubName, clubID] = useOutletContext();

   const fetchClubOwnerInventoryById = async () => {
      const token = await getAccessToken()
      const response = await fetch(`${APIurls.fetchClubOwnerInventoryByIdData}/${inventoryId}`, {
         method: "GET",
         headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
         },
      })
      const result = await response.json();
      if (!response.ok) {
         throw new Error(result.message);
      }
      console.log("INVENTORY DATA", result?.response)
      return result?.response
   }

   const { data: inventoryDataById, isLoading, refetch } = useQuery('clubOwnerInventoryDataById', fetchClubOwnerInventoryById);

   console.log("Inventory Data ByID", inventoryDataById)

   return (
      <div className='bg-white flex flex-col gap-[1rem] rounded-md p-[1rem]'>
         <div className='flex gap-[0.5rem] items-center text-[#808080] font-semibold'>
            <Link to={"/club-owners"}>
               <h2 className='text-[1rem] font-semibold'>
                  Club List
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/${id}`}>
               <h2 className='text-[1rem]'>
                  {clubName}
                  <span className='text-[#808080] text-[0.9rem] font-normal'> {clubID} </span>
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <Link to={`/club-owners/view/${id}/inventory`}>
               <h2 className='text-[1rem]'>
                  Inventory
               </h2>
            </Link>
            <Icon icon="material-symbols:arrow-forward-ios" className="text-[1.1rem]" />
            <h2 className='text-[1.2rem] text-black font-bold'>
               {inventoryDataById?.inventoryId}
            </h2>
         </div>
         <div className='flex gap-[2rem] text-[1.2rem] font-medium border-b-2 border-[#989898]'>
            {/* <NavLink to={`/club-owners/view/:id/sports/view/:bookingId`} end> */}
            {/* {({ isActive }) => ( */}
            <h2 className={`pb-[0.3rem] font-bold text-primary border-b-2 border-primary`}>
               Inventory ID: {inventoryDataById?.inventoryId}
            </h2>
            {/* )} */}
            {/* </NavLink> */}
         </div>
         <div className='grid grid-cols-6 px-[0.6rem]'>
            <div className='col-span-3'>
               <h2 className='text-[1.15rem] font-bold my-[1rem]'>
                  Info
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem] place-content-between'>
                  <label className='text-[#858585] font-medium'>Inventory ID</label>
                  <h2 className='font-semibold'>{inventoryDataById?.inventoryId}</h2>
                  <label className='text-[#858585] font-medium'>Name of the Inventory</label>
                  <h2 className='font-semibold'>{inventoryDataById?.name}</h2>
                  <label className='text-[#858585] font-medium'>Category</label>
                  <h2 className='font-semibold'>{inventoryDataById?.category}</h2>
                  <label className='text-[#858585] font-medium'>Quantity</label>
                  <h2 className='font-semibold'>{inventoryDataById?.quantity}</h2>
                  <label className='text-[#858585] font-medium'>Per Quantity/ Item Cost</label>
                  <h2 className='font-semibold'>{inventoryDataById?.itemCost}</h2>
                  <label className='text-[#858585] font-medium'>Value of Inventory(Total Amount)</label>
                  <h2 className='font-semibold'>{inventoryDataById?.totalValue}</h2>
                  <label className='text-[#858585] font-medium'>Date of Purchased</label>
                  <h2 className='font-semibold'>{moment(inventoryDataById?.dateOfPurchase).format("MMM-DD-YYYY")}</h2>
                  <label className='text-[#858585] font-medium'>Warranty</label>
                  <h2 className='font-semibold'>{inventoryDataById?.warranty === true ? "Yes" : "No"}</h2>
                  <label className='text-[#858585] font-medium'>Warranty Duration</label>
                  <h2 className='font-semibold'>{inventoryDataById?.warrantyDuration}</h2>
                  <label className='text-[#858585] font-medium'>Remarks</label>
                  <h2 className='font-semibold'>{inventoryDataById?.remarks}</h2>
               </div>
            </div>
            <div className='col-span-2'>
               <h2 className='text-[1.15rem] font-bold my-[1rem]'>
                  Status
               </h2>
               <div className='grid grid-cols-2 gap-y-[1rem]'>
                  <label className='text-[#858585] font-medium'>Status</label>
                  <div className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] 
                     w-[6.5rem] ${inventoryDataById?.enabled ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
                     <span className={`${inventoryDataById?.enabled ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                     <h2>{inventoryDataById?.enabled === true ? "Active" : "Inactive"}</h2>
                  </div>
               </div>
            </div>
            <div className='col-span-1 my-[1rem]'>
               <img src={inventoryDataById?.img} alt="sports" className='rounded-md' />
            </div>
         </div>
      </div>
   )
}
