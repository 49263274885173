import React, { useState } from "react";
import { PiGreaterThan } from "react-icons/pi";
import { ThemeProvider } from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import uploadImage from "../../../../assets/upload-image.svg";
import { MdCloudUpload } from "react-icons/md";
import { selectCustomTheme } from "../../../../helpers/constants";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { RiDeleteBinFill } from "react-icons/ri";
import { PulseLoader } from 'react-spinners';
import { RotatingLines } from "react-loader-spinner";

export default function EditOverViewTournament() {
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [reqError, setReqError] = useState("");
    const [mainCategorySport, setMainCategorySport] = useState("");
    const [subCategorySport, setSubCategorySport] = useState("");
    const [selectType, setSelectType] = useState("");
    const [formatType, setFormatType] = useState("");
    const [fixingType, setFixingType] = useState("");
    const [handleStartDate, setHandleStartDate] = useState("");
    const [handleEndDate, setHandleEndDate] = useState("");
    const [aboutTournament, setAboutTournament] = useState("");
    const [participants, setParticipants] = useState(null);
    const [selectSport, setSelectSport] = useState("");
    const [tournamentName, setTournamentName] = useState("");
    const [selectedFile, setSelectedFile] = useState([]);
    const [previewImage, setPreviewImage] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [mainCategoryData, setMainCategoryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [status, setStatus] = useState("");
    const [selectSportOptions, setSelectSportOptions] = useState([]);
    const [scoreType, setScoreType] = useState("Top Score");
    const [scoreError, setScoreError] = useState(false);
    const [score, setScore] = useState("");
    const [scoreTypeOptions, setScoreTypeOptions] = useState([]);
    const [savingScore, setSavingScore] = useState(false);
    const [subCategoryError, setSubCategoryError] = useState(false);

    const { tournamentId } = useParams();
    const { getAccessToken } = useAuth();

    const fetchMainCategories = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchCategories}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const result = await response.json();
            // setIsLoading(false);
            if (!response.ok) {
                throw new Error(result?.message);
            }
            setMainCategoryData(result?.response?.CatArray)
            console.log("Main Categories--->", result?.response?.CatArray)
            // return result?.response?.CatArray;
        }
        catch (error) {
            console.log(error)
        }
    };
    const fetchTournamentDetails = async () => {
        try {
            const token = await getAccessToken();
            setIsLoading(true);
            const response = await fetch(
                `${APIurls.fetchOverViewTournamentData}/${tournamentId}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const result = await response.json();
            console.log("tournament details ", result?.response?.tournamentDetails);
            if (!response.ok) {
                throw new Error(result.message);
            }
            // BannerImg
            let resData = result?.response?.tournamentDetails;
            setMainCategorySport(resData?.mainCategoryID);
            setPreviewImage((prev) => {
                if (resData?.BannerImg?.length === 0 || resData?.BannerImg === '' || resData?.BannerImg === null || JSON.stringify(resData?.BannerImg) === JSON.stringify([])) {
                    return [];
                } else {
                    return [...resData?.BannerImg]
                }
            })
            setSubCategorySport(resData?.subCategoryID);
            setSelectType(resData?.tournamentType);
            setFormatType(resData?.format);
            setFixingType(resData?.fixingType);

            const obj1 = new Date(resData?.startDate);
            const convertedStartDate = obj1?.getDate() < 10 ? `0${obj1?.getDate()}` : obj1?.getDate();
            const convertedStartMonth = obj1?.getMonth() + 1 < 10 ? `0${obj1?.getMonth() + 1}` : obj1?.getMonth() + 1;
            setHandleStartDate(resData?.startDate === null ? "" : `${obj1?.getFullYear()}-${convertedStartMonth}-${convertedStartDate}`)

            const obj2 = new Date(resData?.endDate);
            const convertedEndDate = obj2?.getDate() < 10 ? `0${obj2?.getDate()}` : obj2?.getDate();
            const convertedEndMonth = obj2?.getMonth() + 1 < 10 ? `0${obj2?.getMonth() + 1}` : obj2?.getMonth() + 1;
            setHandleEndDate(resData?.endDate === null ? "" : `${obj2?.getFullYear()}-${convertedEndMonth}-${convertedEndDate}`)

            setAboutTournament(resData?.description);
            setParticipants(resData?.participants);
            setSelectSport(resData?.sportID);
            setTournamentName(resData?.tournamentName);
            setIsLoading(false);
            setStatus(result?.response?.tournamentDetails?.status)
            setScoreTypeOptions(resData?.scoreTypeNames)
            setScore(resData?.noOfMatchSets)
            setScoreType(resData?.scoreType)
            return result?.response?.tournamentDetails;
        } catch (error) {
            setIsLoading(false);
            console.log("error in fetching tournament details : ", error?.message);
            let message = "Error in getting tournament details " + error?.message;
            setReqError(message);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            await fetchTournamentDetails();
            await fetchMainCategories();
            //   setMainCategoryData(data);
            await fetchSportsData();
        })();
        // fetchMainCategories()
        return () => {
            console.log('images memory released')
            for (let img of previewImage) {
                if (img instanceof File) {
                    console.log('released of :', img);
                    URL.revokeObjectURL(img)
                }
            }
        }
    }, []);

    const fetchSportsData = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAllTournamentSports}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            setSelectSportOptions(result?.response?.tournamentSports)
        } catch (error) {
            console.log(error)
        }
    }

    const updateTournamentDetails = async (formData) => {
        try {
            const token = await getAccessToken();
            const response = await fetch(
                `${APIurls.updateTournamentDetails}/${tournamentId}`,
                {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                }
            );
            const result = await response.json();
            console.log("tournament update response ", result);
            if (result.code > 201) {
                toast.error(result?.message);
                throw new Error(result?.message);
            }
            toast.success("Tournament updated Successfully");
            return result?.response;
        }
        catch (error) {
            console.log(error)
        }
    };
    const handleSave = async (e) => {
        console.log("Checking error is there or not", !previewImage)
        e.preventDefault();
        try {
            console.log("Form Submitted");
            if (
                !selectType ||
                !formatType ||
                !fixingType ||
                !handleStartDate ||
                !aboutTournament ||
                !subCategorySport ||
                !participants ||
                !selectSport ||
                !tournamentName ||
                !handleEndDate ||
                !selectedFile
            ) {
                setShowError(true);
                setSubCategoryError(false);
                return;
            } else {
                setShowError(false);
                setSubCategoryError(false);
            }
            let gameType = selectType === "Team Match" ? "team" : "individual";

            let formatName = "";
            if (formatType === "Knockout") {
                formatName = "knockout";
            }
            if (formatType === "Double Elimination Bracket") {
                formatName = "double_elimination_bracket";
            }
            if (formatType === "Round Robbin") {
                formatName = "round_robbin";
            }
            const formData = new FormData();
            formData.append("name", tournamentName);
            formData.append("mainCategoryID", mainCategorySport);
            formData.append("subCategoryID", subCategorySport);
            formData.append("gameType", gameType);
            formData.append("fixingType", fixingType);
            formData.append("participants", participants);
            formData.append("formatType", formatName);
            formData.append("sportID", selectSport);
            formData.append("startDate", handleStartDate);
            formData.append("endDate", handleEndDate);
            formData.append("description", aboutTournament);
            const oldBannerImgUrls = [];
            const newSelectedFiles = [];
            for (let img of previewImage) {
                if (typeof img === "string") {
                    oldBannerImgUrls.push(img);
                } else if (img instanceof File) {
                    newSelectedFiles.push(img);
                }
            }
            formData.append("oldBannerImgUrls", JSON.stringify([...oldBannerImgUrls]));
            for (let file of newSelectedFiles) {
                formData.append("BannerImg", file);
            }
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            setIsSaving(true);
            const updatedResponse = await updateTournamentDetails(formData);
            setIsSaving(false);
            console.log("success response : ", updatedResponse);
            setTimeout(
                () => navigate(`/tournaments/tournamentDetails/${tournamentId}`),
                2500
            );
        } catch (error) {
            setIsSaving(false);
            console.log("error in saving tournament details :", error?.message);
        }
    };

    const handleUpdateScore = async () => {
        console.log('score of scoreType : ', score);
        if (!score) {
            setScoreError(true)
            return;
        } else if (score < 0) {
            setScoreError(true);
            toast.error('Enter Valid Positive Set Number');
            return;
        }

        const body = {
            scoreType: scoreType,
            noOfMatchSets: scoreType === "Top Score" ? 1 : scoreType === "Cricket" ? 1 : +score
        }
        try {
            setSavingScore(true)
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.updateScoreForMatch}/${tournamentId}`, {
                method: 'PUT',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(body)
            })
            const result = await response.json();
            if (!response.ok) {
                toast.error(result?.message);
                setSavingScore(false);
                throw new Error(result?.message)
            }
            console.log("Score Updated Successfully", result)
            toast.success(result.message)
        } catch (error) {
            console.log(error)
        }
        setSavingScore(false)
    }

    const handleFileInput = (event) => {
        let file = event.target.files[0];
        setSelectedFile((previous) => ([...previous, file]))
        // setPreviewImage((preview) => ([...preview, URL.createObjectURL(file)]))
        setPreviewImage((preview) => ([...preview, file]));
    };

    const triggerFileInput = () => {
        document.getElementById("fileInput").click();
    };
    useEffect(() => {
        const noSubmainCategory = mainCategoryData?.filter((item) => item?.subCategoriesId?.length === 0);
        let mainCat = mainCategoryData?.find((div) => div?._id === mainCategorySport);
        setSubCategories(mainCat?.subCategoriesId?.map((div) => {
            return { _id: div?._id, categoryName: div?.subCategoryName };
        }));
        if (noSubmainCategory.length > 0) {
            const mainCatEle = noSubmainCategory.find((mainCat) => mainCat?._id?.toString() === mainCategorySport?.toString());
            if (mainCatEle) {
                setSubCategoryError(true);
            } else {
                setSubCategoryError(false);
            }
        }
        setShowError(false);
    }, [mainCategorySport, mainCategoryData]);

    const handleImageDelete = (i) => {
        const filteredImageData = previewImage?.filter((item, index) => index !== i)
        setPreviewImage(filteredImageData)
    }
    const loadImageUrl = (image) => {
        // Check if the provided value is a URL or a File
        if (typeof image === "string") {
            // If it's a URL, set the image source directly
            return image;
        } else if (image instanceof File) {
            // If it's a File, create an object URL and set it as the image source
            const objectUrl = URL.createObjectURL(image);
            return objectUrl;
            // Clean up the object URL when the component unmounts
            //   return () => URL.revokeObjectURL(objectUrl);
        }
    };

    return (
        <>
            <ThemeProvider value={selectCustomTheme}>
                <div className="flex items-center flex-wrap">
                    <p
                        className="text-[#808080] font-semibold text-base cursor-pointer max-lg:text-[15px]"
                        onClick={() => navigate("/tournaments")}
                    >
                        Tournament
                    </p>
                    <PiGreaterThan className="text-[#808080]" />
                    <p
                        className="text-[#808080] font-semibold text-base cursor-pointer max-lg:text-[15px]"
                        onClick={() =>
                            navigate(`/tournaments/tournamentDetails/${tournamentId}`)
                        }
                    >
                        Overview of the Tournament
                    </p>
                    <PiGreaterThan className="text-[#808080]" />
                    <p className="text-xl font-semibold max-lg:text-[17px]">Tournament Details</p>
                </div>
                {isLoading ? <div className="flex justify-center items-center min-h-screen">
                    <RotatingLines
                        visible={true}
                        height="100"
                        width="100"
                        color="gray"
                        strokeColor="#FB6108"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                    :
                    <div>
                        <label className="px-10 font-semibold">Edit Score Type:</label>
                        <div className="flex items-center gap-[3rem] mb-3 mx-4 px-6 pt-[0.5rem] pb-[1rem] border-b-4 max-md:!flex-wrap max-md:!justify-end max-md:!gap-[1.3rem]">
                            <select
                                className="h-[40px] px-4 py-2 text-gray-700 bg-[#F6F7FB] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                size="lg"
                                value={scoreType}
                                onChange={(e) => setScoreType(e.target.value)}
                            >
                                <option className="bg-gray-500 text-white" disabled>Select Score Type</option>
                                {scoreTypeOptions?.map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </select>
                            <div className="">
                                <input
                                    type="number"
                                    className={`custom-number ${scoreType === "Top Score" ? "cursor-not-allowed" : scoreType === "Cricket" ? "cursor-not-allowed" : scoreType === "Football" ? "cursor-not-allowed" : ""} h-[40px] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none`}
                                    placeholder={`${scoreType === "Top Score" ? "Enter Top Score" : scoreType === "Best Of" ? "Enter Best of" : scoreType === "Race To" ? "Enter Race To" : "Others"}`}
                                    value={scoreType === "Top Score" ? 1 : scoreType === "Cricket" ? 1 : scoreType === "Football" ? 1 : score}
                                    onChange={(e) => setScore(e.target.value)}
                                    disabled={scoreType === "Top Score" || scoreType === "Cricket" || scoreType === "Football"}
                                    min="1"
                                    max="20"
                                />
                                {!score && scoreError && (
                                    <p className="text-xs text-red-500">*This field is required</p>
                                )}
                            </div>
                            <div className="flex items-center justify-center bg-primary text-white px-4 py-2 max-lg:px-2 rounded-md">
                                {savingScore ?
                                    <PulseLoader color="white" size={8} />
                                    :
                                    <button
                                        className="flex gap-1 items-center"
                                        onClick={handleUpdateScore}
                                    >
                                        Save <PiGreaterThan />
                                    </button>
                                }
                            </div>
                        </div>
                        <form onSubmit={handleSave}>
                            {!isLoading && (
                                <div className="flex flex-col">
                                    <div className="flex justify-end mb-[15px]">
                                        <button
                                            className={`flex items-center mr-[2rem] ${isSaving
                                                ? "bg-green-600 text-white cursor-not-allowed"
                                                : "bg-[#FB6108] text-white cursor-pointer"
                                                }  px-4 py-2 rounded-md gap-2 text-base float-right max-lg:px-2`}
                                            disabled={isSaving}
                                        >
                                            {isSaving ? <p>Saving...</p> : <p>Save</p>}
                                            <PiGreaterThan />
                                        </button>
                                    </div>
                                    <div className="flex gap-[3rem] px-[2rem] pb-[2rem] max-xl:grid max-xl:grid-cols-2 max-sm:!grid-cols-1">
                                        <div className="flex flex-col gap-[1.5rem] flex-[2]">
                                            <div>
                                                <select
                                                    className={`${status === 'PENDING' ? 'cursor-pointer' : 'cursor-not-allowed'} w-full px-4 py-2 text-gray-700 bg-[#F6F7FB] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500`}
                                                    size="lg"
                                                    defaultValue={'Select Main Category'}
                                                    value={mainCategorySport}
                                                    onChange={(e) => setMainCategorySport(e.target.value)}
                                                    disabled={status === "PENDING" ? false : true}
                                                >
                                                    <option value='' disabled className="bg-gray-500 text-white">Select Main Category</option>
                                                    {mainCategoryData?.map((category) => {
                                                        return (
                                                            <option key={category?._id} value={category?._id}>
                                                                {category?.categoryName}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                {subCategoryError &&
                                                    <p className="text-xs text-red-500">
                                                        Sub-Categories Not Available in this Main-Category
                                                    </p>
                                                }
                                            </div>
                                            <div>
                                                <select
                                                    className={`cursor-not-allowed w-full px-4 py-2 text-gray-700 bg-[#F6F7FB] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500`}
                                                    size="lg"
                                                    value={selectType}
                                                    onChange={(e) => setSelectType(e.target.value)}
                                                    disabled={true}
                                                >
                                                    <option value='' disabled>Select Type</option>
                                                    <option value="Team Match">Team Match</option>
                                                    <option value="Individual">Individual</option>
                                                </select>
                                                {!selectType && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <select
                                                    className={`cursor-not-allowed w-full px-4 py-2 text-gray-700 bg-[#F6F7FB] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500`}
                                                    size="lg"
                                                    value={formatType}
                                                    onChange={(e) => setFormatType(e.target.value)}
                                                    disabled={true}
                                                >
                                                    <option value='' disabled>Select Format</option>
                                                    <option value="Knockout">Knockout</option>
                                                    <option value="Double Elimination Bracket">
                                                        Double-Elimination Bracket
                                                    </option>
                                                    <option value="Round Robbin">Round Robbin</option>
                                                </select>
                                                {!formatType && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <select
                                                    className={`${status === 'PENDING' ? 'cursor-pointer' : 'cursor-not-allowed'} w-full px-4 py-2 text-gray-700 bg-[#F6F7FB] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500`}
                                                    size="lg"
                                                    value={fixingType}
                                                    onChange={(e) => setFixingType(e.target.value)}
                                                    disabled={status === "PENDING" ? false : true}
                                                >
                                                    <option value='' className="bg-gray-500 text-white" disabled>Select Fixing Type</option>
                                                    <option value="random">Random</option>
                                                    <option value="manual">Manual</option>
                                                    <option value="sequential">Sequential</option>
                                                    <option value="top_vs_bottom">Top Vs Bottom</option>
                                                    <option value="seeding">Seeding</option>
                                                </select>
                                                {!fixingType && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                            <div className="">
                                                <input
                                                    value={handleStartDate}
                                                    type="date"
                                                    onChange={(e) => setHandleStartDate(e.target.value)}
                                                    className="h-10 outline-none w-full border rounded px-2 py-1"
                                                    placeholder="Start Date"
                                                />
                                                {!handleStartDate && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <textarea
                                                    className="w-full p-3 bg-[#F6F7FB] outline-none"
                                                    placeholder="About Tournament"
                                                    value={aboutTournament}
                                                    rows={8}
                                                    onChange={(e) => setAboutTournament(e.target.value)}
                                                />
                                                {!aboutTournament && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-[1.5rem] flex-[2]">
                                            <div>
                                                {subCategories?.length > 0 ? (
                                                    <select
                                                        className={`${status === 'PENDING' ? 'cursor-pointer' : 'cursor-not-allowed'} w-full px-4 py-2 text-gray-700 bg-[#F6F7FB] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500`}
                                                        size="lg"
                                                        value={subCategorySport}
                                                        onChange={(e) => setSubCategorySport(e.target.value)}
                                                        disabled={status === "PENDING" ? false : true}
                                                    >
                                                        <option value='' className="bg-gray-500 text-white" disabled>Select Sub-Category</option>
                                                        {subCategories?.map((category) => {
                                                            return (
                                                                <option value={category?._id} key={category?._id}>
                                                                    {category?.categoryName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                ) : (
                                                    <select
                                                        className={`${status === 'PENDING' ? 'cursor-pointer' : 'cursor-not-allowed'} w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500`}
                                                    >
                                                        <option value='' disabled>Select Sub-Category</option>
                                                    </select>
                                                )}
                                                {!subCategorySport && showError && (
                                                    <p className="text-sm text-red-500">
                                                        This field is required
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    className={`bg-[#F6F7FB] ${status === 'PENDING' ? 'cursor-pointer' : 'cursor-not-allowed'} w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none`}
                                                    placeholder="Enter Number of Participants"
                                                    value={participants}
                                                    onChange={(e) => setParticipants(e.target.value)}
                                                    disabled={status === "PENDING" ? false : true}
                                                />
                                                {!participants && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <select
                                                    className={`${status === 'PENDING' ? 'cursor-pointer' : 'cursor-not-allowed'} w-full px-4 py-2 text-gray-700 bg-[#F6F7FB] border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500`}
                                                    size="lg"
                                                    value={selectSport}
                                                    onChange={(e) => setSelectSport(e.target.value)}
                                                    disabled={status === "PENDING" ? false : true}

                                                >
                                                    <option value='' className="bg-gray-500 text-white" disabled>Select Sport</option>
                                                    {selectSportOptions?.map((item, i) => (
                                                        <option value={item?._id} key={i}>{item?.name}</option>
                                                    ))}
                                                </select>
                                                {!selectSport && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                                                    placeholder="Tournament Name"
                                                    value={tournamentName}
                                                    onChange={(e) => setTournamentName(e.target.value)}
                                                />
                                                {!tournamentName && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                            <div className="">
                                                <input
                                                    value={handleEndDate}
                                                    type="date"
                                                    className="outline-none h-10 w-full border rounded px-2 py-1"
                                                    placeholder="End Date"
                                                    onChange={(e) => setHandleEndDate(e.target.value)}
                                                />
                                                {!handleEndDate && showError && (
                                                    <p className="text-sm text-red-500">
                                                        *This field is required
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-[30%] gap-[1.5rem] max-xl:w-[100%] max-xl:!flex">
                                            <div className="w-[100%] h-[235px] flex flex-col items-center justify-center bg-[#F4F5FA]">
                                                <div>
                                                    <div className="flex justify-center items-center">
                                                        <img src={uploadImage} className="w-10 " alt="upload img" />
                                                    </div>
                                                    <div
                                                        className="text-primary flex items-center gap-3 cursor-pointer justify-center"
                                                        onClick={triggerFileInput}
                                                    >
                                                        <MdCloudUpload className="w-[2rem] h-[2rem]" />
                                                        <p className="max-xl:text-[14px]">Upload Banner</p>
                                                    </div>
                                                    <p className="text-[#808080] text-sm text-center max-xl:text-[11px]">
                                                        Upload an image of Tournament
                                                    </p>
                                                    <p className="text-[0.8rem] text-[#808080] text-center max-xl:text-[11px]">
                                                        File Format <span className="text-black"> jpeg, png </span>
                                                        <br />
                                                        Recommended Size{" "}
                                                        <span className="text-black max-xl:text-[11px]"> 600x600 (1:1) </span>
                                                    </p>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        // accept="image/*"
                                                        multiple
                                                        className="hidden"
                                                        onChange={handleFileInput}
                                                    />
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-2 w-[100%] gap-4">
                                                {previewImage?.map((url, i) => {
                                                    return (
                                                        <div className="flex w-full h-[100px]" key={i}>
                                                            <div className="border border-gray-300 px-2 py-1">
                                                                <img
                                                                    src={loadImageUrl(url)}
                                                                    // alt="Selected"
                                                                    className="w-[100%] h-full" // Adjust width to make space for the delete button
                                                                    alt="img"
                                                                />
                                                            </div>
                                                            <RiDeleteBinFill
                                                                className="text-[1.5rem] text-red-700 cursor-pointer"
                                                                onClick={() => handleImageDelete(i)}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                                {selectedFile?.length === 0 && showError && (
                                                    <p className="text-sm text-red-600 mt-[-12px]">*This field is required</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                }
                <ToastContainer />
            </ThemeProvider>
        </>
    );
}