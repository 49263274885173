import React, { useEffect, useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { PulseLoader } from "react-spinners";

export default function OverViewEditPlayerModal({
  setEditPlayerModal,
  id,
  refetch,
}) {

  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  const { getAccessToken } = useAuth();

  const getSpecificParticipant = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.fetchSpecificParticipantDetails}/${id}/individual`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      console.log("specific participant player : ", result);
      if (!response.ok) {
        toast.error(result?.response?.message);
        throw new Error(result.message);
      }
      const participant = result?.response?.participants;
      setPlayerName(participant?.name);
      setEmail(participant?.email);
      setMobileNumber(participant?.phone);
      if (participant?.userMongoID) {
        setIsEditable(false);
      }
      return participant;
    } catch (error) {
      console.log(
        "Error in fetching Specific participant player : ",
        error?.message
      );
    }
  };

  const handleFetchPlayerDetails = async (e) => {
    e.preventDefault();
    if (mobileNumber === "") {
      setFetchError(true);
      return;
    }
    const mobileNoRegex = /^\+91\d{10}$/;
    if (!mobileNoRegex.test(mobileNumber)) {
      toast.error("Enter a Valid mobile Number ");
      return;
    }
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.fetchPlayersInfoByPhoneNumber}/${mobileNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      if (!response.ok) {
        toast.error(result?.response?.message);
        throw new Error(result.message);
      }
      setPlayerName(
        result?.response?.OfflineUSer_data?.FirstName +
        " " +
        result?.response?.OfflineUSer_data?.LastName
      );
      setEmail(result?.response?.OfflineUSer_data?.email);
      console.log("Players Details", result?.response);
      toast.success("Player Details fetched Successfully");
    } catch (error) {
      console.log("Error in fetching user by mobile number ", error?.message);
    }
  };

  useEffect(() => {
    (async () => {
      await getSpecificParticipant();
    })();
  }, []);

  const handleSavePlayer = async (e) => {
    e.preventDefault();
    if (mobileNumber === "" || !email || !playerName) {
      setShowError(true);
      return;
    }
    const mobileNoRegex = /^\+91\d{10}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!mobileNoRegex.test(mobileNumber)) {
      toast.error("Enter a Valid mobile number");
      return;
    }
    if (!emailRegex.test(email)) {
      toast.error("Enter a Valid Email Address");
      return;
    }
    console.log("all values validated");
    const participantDetails = {
      mobileNumber: mobileNumber,
      playerName: playerName,
      email: email,
    };

    try {
      const token = await getAccessToken();
      setLoader(true);
      const response = await fetch(
        `${APIurls.addOrEditParticipantPlayer}/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(participantDetails),
        }
      );
      const result = await response.json();
      if (!response.ok) {
        toast.error(result.message);
        throw new Error(result.message);
      }
      console.log("Participant Updated Successfully", result);
      toast.success("Participant Updated Successfully");
      refetch();
      setEditPlayerModal(false);
    } catch (error) {
      console.log("Error in saving Player Details : ", error?.message);
    }
    setLoader(false);
  };

  const formatMobileNumber = (number) => {
    number = number?.replace(/[^0-9+]/g, "");
    console.log("number : ", number);
    if (number?.startsWith("+91")) {
      setMobileNumber(number);
    } else {
      if (number?.includes("+")) {
        setMobileNumber(`+91`);
      } else {
        setMobileNumber(`+91` + number);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
      <div className="flex flex-col items-center gap-4 bg-white rounded-sm px-6 py-8 w-[40%] relative">
        {loader ? (
          <div className="flex justify-center items-center">
            <RotatingLines
              visible={true}
              height="30"
              width="30"
              // color="white"
              strokeColor="#FB6108"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div>
            <div className="flex justify-between items-center">
              <h1 className="text-[20px] font-bold">Edit Player</h1>
              <IconButton
                onClick={() => setEditPlayerModal(false)}
                className={
                  "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"
                }
                size="sm"
              >
                <Icon icon="material-symbols:close" className="text-[1.5rem]" />
              </IconButton>
            </div>
            <form>
              <div className="flex gap-[2rem] py-[2rem] max-lg:flex max-lg:flex-col max-lg:gap-[1rem]">
                <div className="flex flex-col gap-[0.75rem]">
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center bg-[#EFF1F9]">
                      <input
                        className={`${isEditable ? "" : "cursor-not-allowed"
                          } w-[100%] text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none`}
                        type="text"
                        placeholder="Mobile Number"
                        value={mobileNumber}
                        onChange={(e) => formatMobileNumber(e.target.value)}
                        maxLength={13}
                        disabled={isEditable ? false : true}
                      />
                      {isEditable && (
                        <button
                          className="text-sm px-2 py-1 text-primary border-2 border-primary rounded-md mr-1"
                          type="submit"
                          onClick={handleFetchPlayerDetails}
                        >
                          Fetch
                        </button>
                      )}
                    </div>
                    {!mobileNumber && fetchError && (
                      <p className="text-xs text-red-600">
                        *Please Enter Mobile Number
                      </p>
                    )}
                    {!mobileNumber && showError && (
                      <p className="text-red-600 text-xs">
                        *This field is required
                      </p>
                    )}
                  </div>
                  <div>
                    <input
                      className={` ${isEditable ? "" : "cursor-not-allowed"
                        } w-full text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none`}
                      type="text"
                      placeholder="Email Address"
                      value={email}
                      disabled={isEditable ? false : true}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {!email && showError && (
                    <p className="text-red-600 text-sm">
                      *This field is required
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-[0.75rem]">
                  <input
                    className="w-[100%] text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] placeholder:text-[#7F7F7F] bg-[#EFF1F9] outline-none"
                    type="text"
                    value={playerName}
                    placeholder="Player Name"
                    onChange={(e) => setPlayerName(e.target.value)}
                  />
                  {!playerName && showError && (
                    <p className="text-red-600 text-sm">
                      *This field is required
                    </p>
                  )}
                </div>
              </div>
              <div className="w-[135px] flex items-center justify-center text-white bg-[#FB6108] px-3 py-2 cursor-pointer rounded-md gap-1">
                <button
                  onClick={handleSavePlayer}
                  type="submit"
                  className="text-[0.8rem] sm:text-[1rem] font-semibold"
                >
                  Save
                </button>
                <PiGreaterThan />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
