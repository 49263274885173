import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList, Option, Select } from '@material-tailwind/react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import { APIurls } from '../../../../api/apiConstant';
import { useAuth } from '../../../../store/AuthContext';
import { useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import DownloadExcel from '../../../../exportData/ExportExcel';
import pdfLogo from '../../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../../../exportData/ExportPDF';


export default function ViewCOSports() {

   const [loadingStates, setLoadingStates] = useState({});
   const { id } = useParams();
   const [selectedRows, setSelectedRows] = useState([])
   const { getAccessToken } = useAuth();
   const [status, setStatus] = useState("")
   const [statusdata, setStatusData] = useState([])
   const [base64Logo, setBase64Logo] = useState("");
   const [loading, setLoading] = useState(false);
   const [adminName, setAdminName] = useState("");

   const fetchClubOwnerSports = async () => {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.viewClubOwnerSports}?ClubOwnerID=${encodeURIComponent(id)}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${token}`,
         }
      })
      const result = await response.json();
      if (!response.ok) {
         throw new Error(result.message);
      }
      console.log("View Club Owner Sports", result?.response?.clubSports);
      setStatusData(result?.response?.clubSports)
      return result?.response?.clubSports.reverse();
   }
   const { data: sportsData, isLoading, refetch } = useQuery('clubSports', fetchClubOwnerSports);

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            background: "#FFF",
         },
      },

      rows: {
         style: {
            background: "#FFF",
            color: "#565656",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
            padding: "5px 0px"
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };
   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: 'Image',
         id: "image",
         selector: row => (
            <img src={row.Areana_image} alt="arena" className='h-[45px] w-[90px] object-cover rounded-sm' />
         ),
         center: true,
         grow: 1,
      },
      {
         name: 'Arena ID',
         id: "arenaId",
         selector: row => row.AreanaID,
         center: true,
         grow: 2,
      },
      {
         name: 'Arena Name',
         id: "arenaName",
         selector: row => row.Areana_name,
         center: true,
      },
      {
         name: 'Type of Arena',
         id: "typeOfArena",
         selector: row => (
            <div>{row?.Areana_category?.categoryName}</div>
         ),
         center: true,
      },
      {
         name: 'Sub Category',
         id: "subCategory",
         selector: row => row?.Areana_sub_category?.subCategoryName,
         center: true,
      },
      {
         name: 'No of Boards',
         id: "numberOfBoards",
         selector: row => row.Areana_total_boards,
         center: true,
      },
      {
         name: "Status",
         id: "status",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[6rem] ${row.isActive === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
               <span className={`${row.isActive === "ACTIVE" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
               <h2>{row.isActive}</h2>
            </div>
         ),
         center: true,
         grow: 1.5,
         sortable: true,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex gap-[0.5rem]">
               {
                  loadingStates[row.AreanaID] ? (
                     <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#FB6108"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                     />
                  ) : (
                     <IconButton onClick={() => updateSportStatusHandler(row.AreanaID)}
                        className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                     >
                        {row.isActive === "ACTIVE" ? (
                           <Icon
                              icon="mdi:lock-off"
                              className="text-[1.6rem] text-black"
                           />
                        ) : (
                           <Icon
                              icon="mdi:lock-open"
                              className="text-[1.6rem] text-black"
                           />
                        )}
                     </IconButton>
                  )}
               <Link to={`view/${row.AreanaID}`}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="mdi:eye"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link>

               {/* <Link to={`edit/:id`}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="ic:baseline-edit"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link> */}
            </div>
         ),
         center: true,
         grow: 1.5,
      },
   ];

   const updateSportStatusHandler = async (arenaId) => {
      const token = await getAccessToken();
      try {
         setLoadingStates((prevState) => ({
            ...prevState,
            [arenaId]: true,
         }));
         const response = await fetch(`${APIurls.updateSportArenaStatus}/${encodeURIComponent(arenaId)}`, {
            method: 'PUT',
            headers: {
               Authorization: `Bearer ${token}`,
            }
         })
         if (!response.ok) {
            throw new Error("Error");
         }
         const result = await response.json();
         console.log("Update Arena Status Result", result);
         toast.success("Arena Status updated successfully!");
         refetch();
      } catch (error) {
         console.error("Failed to Update Arena Status!");
         toast.error("Failed to Update Arena Status!");
      }
      setLoadingStates((prevState) => ({
         ...prevState,
         [arenaId]: false,
      }));
   }

   const fetchAdminDetails = async () => {
      try {
         const token = await getAccessToken();
         const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
            method: 'GET',
            headers: {
               'Authorization': `Bearer ${token}`
            }
         })
         const result = await response.json();
         if (!response.ok) {
            throw new Error(result.message)
         }
         setAdminName(result?.response?.name)
      } catch (error) {
         console.log("Error in fetching admin details", error)
      }
   }

   useEffect(() => {
      fetchAdminDetails();
   }, [])

   const handleSelectStatus = (value) => {
      setStatus(value)
      setStatusData(sportsData?.filter((data) => data.isActive === value))
   }

   useEffect(() => {
      if (status === "clear_all") {
         setStatusData(sportsData)
      }
   }, [status])

   const handleSelectedRows = ({ selectedRows }) => {
      if (sportsData && selectedRows.length === sportsData.length) {
         setSelectedRows(selectedRows)
      } else if (selectedRows.length > 0) {
         setSelectedRows(selectedRows.reverse())
      } else {
         setSelectedRows(selectedRows)
      }
      console.log("selected rows", selectedRows)
   }

   const convertImageToBase64 = async (imagePath) => {
      try {
         const response = await fetch(imagePath);
         const blob = await response.blob();

         return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
               resolve(reader.result);
            };
            reader.onerror = (error) => {
               reject(error);
            };
            reader.readAsDataURL(blob);
         });
      } catch (error) {
         console.error('Error converting image to base64:', error);
         throw error;
      }
   };

   const handleDownloadPDF = async () => {
      setLoading(true);
      try {
         const base64String = await convertImageToBase64(gamebeezLogo);
         setBase64Logo(base64String);

         const pdfData = sportsData?.map((ele, index) => [
            index + 1,
            ele?.AreanaID,
            ele?.Areana_name,
            ele?.Areana_category?.categoryName,
            ele?.Areana_sub_category?.subCategoryName,
            ele?.Areana_total_boards,
            ele?.isActive
         ]);

         const pdfColumns = ["SL", "Arena ID", "Arena Name", "Type of Arena", "Sub-Category Name", "No.of Boards", "Status"];

         await DownloadPDF(pdfData, pdfColumns, "ViewCo Sports.pdf", base64String, adminName);
      } catch (error) {
         console.error("Error generating PDF:", error);
      }
      setLoading(false);
   };

   const excelData = sportsData?.map((item, index) => [
      index + 1,
      item?.Areana_image,
      item?.AreanaID,
      item?.Areana_name,
      item?.Areana_category?.categoryName,
      item?.Areana_sub_category?.subCategoryName,
      item?.Areana_total_boards,
      item?.isActive
   ])

   const excelColumns = ["SL", "Image", "Arena ID", "Arena Name", "Type of Arena", "Sub-Category Name", "No.of Boards", "Status"]

   return (
      <div className='bg-white rounded-md flex flex-col gap-[0.5rem]'>
         <div className='flex items-center justify-between'>
            <h2 className='text-[1.3rem] font-semibold'>
               Sports
            </h2>
            <div>
               <div className='flex items-center gap-[2rem]'>
                  {/* <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                     <Icon
                        icon='material-symbols:delete'
                        className='text-[1.5rem]'
                     />
                     <span className='text-[1rem] font-medium'>Remove</span>
                  </div> */}
                  <div className='flex gap-3 items-center rounded-md'>
                     {loading ? (
                        <p className="text-xs">Generating PDF...</p>
                     ) : (
                        <img
                           src={pdfLogo}
                           className='w-[25px] cursor-pointer'
                           onClick={handleDownloadPDF}
                           alt="Generate PDF"
                        />
                     )}
                     <FaFileCsv
                        className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                        onClick={() => DownloadExcel({ excelData, excelColumns })}
                     />
                  </div>
                  <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
                     <Select label='Change Status'
                        value={status}
                        onChange={(value) => handleSelectStatus(value)}
                     >
                        <Option value='ACTIVE' disabled={status === 'ACTIVE'}>ACTIVE</Option>
                        <Option value='INACTIVE' disabled={status === 'INACTIVE'}>INACTIVE</Option>
                        <Option value='clear_all' disabled={status === "clear_all"}>CLEAR ALL</Option>
                     </Select>
                  </div>
               </div>
            </div>
         </div>
         <div>
            <DataTable
               columns={columns}
               customStyles={customStyles}
               data={statusdata}
               pagination
               selectableRows
               progressPending={isLoading}
               selectableRowsComponent={CustomCheckbox}
               onSelectedRowsChange={handleSelectedRows}
            />
         </div>
         <ToastContainer />
      </div>
   )
}

