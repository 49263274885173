import React, { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { APIurls } from "../../../api/apiConstant";

export default function ArchiveDeleteTournamentModal({ setDeleteModal, tournamentId, refetch }) {

    const { getAccessToken } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteTournament = async () => {

        try {
            setIsLoading(true)
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.deleteTournamentAtArchive}/${tournamentId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
            });
            const result = await response.json();
            if (!response.ok) {
                toast.error("Failed to delete a Tournament");
                throw new Error(result);
            }
            console.log("Deleting a Tournament", result);
            refetch();
            setDeleteModal(false);
            toast.success("Tournament Deleted Successfully");
        } catch (error) {
            console.error("Error deleting tournament at Archive", error.message);
        }
        setIsLoading(false)
    }

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6]">
            <div className="flex flex-col gap-4 bg-white rounded-sm px-6 py-8 w-[30%] relative">
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 z-20">
                        <div className="loader border-t-4 border-orange-500 border-solid rounded-full w-12 h-12 animate-spin"></div>
                    </div>
                )}
                <div className="flex justify-between">
                    <h1 className="text-[20px] font-bold">Are you sure?</h1>
                    <IconButton
                        onClick={() => setDeleteModal(false)}
                        className={"shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"}
                        size="sm"
                    >
                        <Icon icon="material-symbols:close" className="text-[1.5rem]" />
                    </IconButton>
                </div>
                <div>
                    <p className="text-[16px] text-red-500">
                        This Tournament Will Be Deleted Permanently.
                    </p>
                </div>
                <div className="flex items-center justify-end">
                    <button
                        type="button"
                        className="w-full text-center justify-center text-[0.8rem] sm:text-[1rem] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[1rem] bg-red-400 text-white py-[0.6rem] px-[2rem] xs:px-[2rem] font-semibold"
                        onClick={handleDeleteTournament}
                    >
                        DELETE
                    </button>
                </div>
            </div>

        </div>
    );
}
