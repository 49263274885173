import { Icon } from '@iconify/react';
import { NavLink } from "react-router-dom"
import backWhiteArrowIcon from '../../assets/material-symbols_arrow-back-ios-new-rounded-white.svg';
import frontWhiteArrowIcon from '../../assets/material-symbols_arrow-front-ios-new-rounded.svg'
import { useState } from 'react';
import { useAuth } from "../../store/AuthContext";
import logo from "../../assets/GB-Final-Logo.png"

const AdminSidebar = ({ sidebarWidth, setSidebarWidth }) => {

  const [isHover, setIsHover] = useState(false);
  const [sportHover, setSportHover] = useState(false);
  const [subscriptionHover, setSubscriptionHover] = useState(false);

  const { logout, userRole } = useAuth();

  // const [sidebarWidth, setSidebarWidth] = useState(false)
  return (
    <div className={`${sidebarWidth ? 'flex items-start transition ease-linear duration-200 w-[8rem] bg-white relative shadow-6xl' : 'flex items-start w-[15rem] bg-white relative shadow-3xl transition ease-linear duration-200'}`}>

      <aside className="flex flex-col h-screen bg-white w-[15rem] px-4 justify-between overflow-auto gap-4 border-b border-[#EAECF0]">
        {/* Add Logo */}
        <div className='text-center'>
          <img src={logo} alt="logo" className='h-[85px] w-[85px] object-cover' />
        </div>
        <div className='flex flex-col'>
          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes('dashboard')) &&
            <NavLink to='/'
              end
              className={({ isActive, isPending }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }>
              <div className={`flex py-3 px-2  items-center hover:bg-[#FFDFCD] cursor-pointer hover:text-[#FB6108] ${sidebarWidth ? 'justify-center' : ''} mt-[-20px]`}>
                <Icon icon='material-symbols:dashboard' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Dashboard</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("clubs")) && (
            <NavLink to='/club-owners'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }>
              <div className={`flex  py-3 px-2 items-center hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} cursor-pointer hover:text-[#FB6108]`}>
                <Icon icon='material-symbols:shield-person' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Clubs</p>
              </div>
            </NavLink>
          )
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes('customers')) &&
            <NavLink to='/customers'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer  hover:text-[#FB6108]`}>
                <Icon icon='mdi:person-group' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Customers</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("bookings")) &&
            <NavLink to='/bookings'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer hover:text-[#FB6108]`}>
                <Icon icon='simple-line-icons:calender' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Bookings</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("tournaments")) &&
            <NavLink to='/tournaments'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer hover:text-[#FB6108]`}>
                <Icon icon='tabler:tournament' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Tournaments</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("categories")) &&
            <NavLink to='/categories'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer hover:text-[#FB6108]`}>

                <Icon icon='mdi:basketball' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Categories</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("staff")) &&
            <NavLink to='/staff'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer hover:text-[#FB6108]`}>
                <Icon icon='material-symbols:person' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Staff</p>
              </div>

            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("reports")) &&
            <NavLink to='/reports'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >

              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer hover:text-[#FB6108]`}>
                <Icon icon='tabler:file-report' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Reports</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes('offers') || userRole?.role?.subAdmin?.navbar?.includes('banners')) &&
            <NavLink to='/offersAndBanners'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer hover:text-[#FB6108]`}>
                <Icon icon='mdi:tag' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium w-[8.8rem]'}>Offers & Banners</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("notifications")) &&
            <NavLink to='/notifications'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer hover:text-[#FB6108]`}>
                <Icon icon='bxs:bell-plus' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Notifications</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes('support')) &&
            <NavLink to="https://dashboard.tawk.to/#/chat" target="blank" rel="noopener noreferrer"
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center cursor-pointer hover:text-[#FB6108]`}>
                <Icon icon='ic:baseline-contact-support' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>
                  Chat Support
                </p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("faqs")) &&
            <NavLink to='/faqs'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} hover:text-[#FB6108] items-center cursor-pointer`}>
                <Icon icon='mdi:frequently-asked-questions' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>FAQs</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("settings")) &&
            <NavLink to='/settings'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center hover:text-[#FB6108] cursor-pointer`}>
                <Icon icon='material-symbols:settings' className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Settings</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("amenities")) &&
            <NavLink to='/amenities'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              {({ isActive }) => (
                <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center hover:text-[#FB6108] cursor-pointer`}
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={sidebarWidth ? "24" : "15"} height={sidebarWidth ? "24" : "15"} viewBox="0 0 24 24" fill="none" className='hover:text-[#FB6108]'
                  >
                    <rect width="23.9999" height="24" fill={`${isActive || isHover ? "#FB6108" : "#4D4D4D"}`} />
                    <path d="M4.5058 16.504C4.2285 16.5038 3.95947 16.5958 3.74316 16.7647C3.52685 16.9336 3.3762 17.1693 3.31612 17.4328L3.11717 18.3063L2.5 18.504V19.9071H3.31206C3.31206 20.0644 3.37623 20.2152 3.49045 20.3263C3.60467 20.4375 3.75958 20.5 3.92111 20.5C4.08264 20.5 4.23755 20.4375 4.35177 20.3263C4.46599 20.2152 4.53016 20.0644 4.53016 19.9071H6.96635C6.96635 20.0644 7.03051 20.2152 7.14473 20.3263C7.25895 20.4375 7.41387 20.5 7.57539 20.5C7.73692 20.5 7.89184 20.4375 8.00606 20.3263C8.12028 20.2152 8.18444 20.0644 8.18444 19.9071H9.49999V19.2154C9.5012 19.02 9.42801 18.8311 9.29455 18.6851C9.16108 18.5391 8.97679 18.4464 8.77725 18.4249L7.64848 18.3024L7.44952 17.4289C7.38944 17.1653 7.2388 16.9297 7.02249 16.7608C6.80618 16.5919 6.53714 16.4999 6.25985 16.5L4.5058 16.504ZM6.15428 18.1087H4.53016V17.3182H6.15428V18.1087Z" fill="white" />
                    <path d="M19.9789 20.5V18.1375L20.5409 18.5918L21 17.8558L18.2296 15.5842C18.1625 15.5294 18.0823 15.5 18 15.5C17.9177 15.5 17.8375 15.5294 17.7704 15.5842L15 17.8558L15.4591 18.5918L16.0211 18.1375V20.5H17.4063V19.137H18.5937V20.5H19.9789Z" fill="white" />
                    <path d="M18.8825 7.35551C19.4106 6.98049 19.857 6.51181 20.1969 5.97546C20.3524 5.72808 20.4215 5.44041 20.3943 5.15355C20.3671 4.8667 20.2451 4.59534 20.0456 4.37824C19.9416 4.25986 19.8115 4.16461 19.6644 4.09925C19.5174 4.03388 19.3569 4 19.1945 4C19.0321 4 18.8717 4.03388 18.7246 4.09925C18.5775 4.16461 18.4474 4.25986 18.3435 4.37824L18.0001 4.7285L17.6567 4.37824C17.5528 4.25986 17.4227 4.16461 17.2756 4.09925C17.1285 4.03388 16.9681 4 16.8057 4C16.6433 4 16.4828 4.03388 16.3358 4.09925C16.1887 4.16461 16.0586 4.25986 15.9546 4.37824C15.7551 4.59534 15.6331 4.8667 15.6059 5.15355C15.5787 5.44041 15.6478 5.72808 15.8033 5.97546C16.1323 6.50865 16.5659 6.97711 17.0807 7.35551L17.9816 8L18.8825 7.35551Z" fill="white" />
                    <path d="M12 6V1.2V0H10.4H1.2H0V1.2V10.4V12H1.2H6.5L6 10.8H1.2C1.09391 10.8 0.992172 10.7579 0.917157 10.6828C0.842143 10.6078 0.8 10.5061 0.8 10.4V1.2C0.8 1.09391 0.842143 0.992172 0.917157 0.917157C0.992172 0.842143 1.09391 0.8 1.2 0.8H10.4C10.5061 0.8 10.6078 0.842143 10.6828 0.917157C10.7579 0.992172 10.8 1.09391 10.8 1.2V7L12 6Z" fill="white" />
                    <path d="M22.7998 0H13.5998H12V1.2V6L13.1998 7V1.2C13.1998 1.09391 13.2419 0.992172 13.3169 0.917157C13.392 0.842143 13.4937 0.8 13.5998 0.8H22.7998C22.9059 0.8 23.0076 0.842143 23.0826 0.917157C23.1576 0.992172 23.1998 1.09391 23.1998 1.2V10.4C23.1998 10.5061 23.1576 10.6078 23.0826 10.6828C23.0076 10.7579 22.9059 10.8 22.7998 10.8H17.9998L17.5 12H22.7999H23.9999L23.9998 10.4V1.2L23.9999 0H22.7998Z" fill="white" />
                    <path d="M10.8 22.8001C10.8 22.9061 10.7579 23.0079 10.6828 23.0829C10.6078 23.1579 10.5061 23.2001 10.4 23.2001H1.2C1.09391 23.2001 0.992172 23.1579 0.917157 23.0829C0.842143 23.0079 0.8 22.9061 0.8 22.8001V13.6001C0.8 13.494 0.842143 13.3922 0.917157 13.3172C0.992172 13.2422 1.09391 13.2001 1.2 13.2001H7L6.5 12H1.2H0V13.6001V22.8001V24L1.2 24.0001H10.4L12 24V22.8V18L10.8 17V22.8001Z" fill="white" />
                    <path d="M22.7999 12H17.5L17 13.2H22.7999C22.9059 13.2 23.0077 13.2422 23.0827 13.3172C23.1577 13.3922 23.1999 13.494 23.1999 13.6V22.8C23.1999 22.9061 23.1577 23.0079 23.0827 23.0829C23.0077 23.1579 22.9059 23.2 22.7999 23.2H13.5999C13.4938 23.2 13.392 23.1579 13.317 23.0829C13.242 23.0079 13.1999 22.9061 13.1999 22.8V17L12 18V22.8V24L13.5999 24H22.7999L24 24L23.9999 22.8V13.6V12H22.7999Z" fill="white" />
                    <path d="M8.2 4.8H6.4V3H4.8V4.8H3V6.4H4.8V8.2H6.4V6.4H8.2V4.8Z" fill="white" />
                    <path d="M16.8002 9.53595L16.3602 9.45995C14.7059 9.18236 13.1806 8.39176 12.0002 7.19995C10.8192 8.3903 9.29404 9.17946 7.6402 9.45595L7.2002 9.53195C7.3588 10.8638 7.79715 12.147 8.48651 13.2976C9.17586 14.4481 10.1006 15.4399 11.2002 16.208L12.0002 16.8L13.0042 16.024C14.0455 15.2581 14.9194 14.2876 15.5721 13.1719C16.2249 12.0562 16.6428 10.8189 16.8002 9.53595ZM12.0002 13.536C11.8877 13.6483 11.7352 13.7114 11.5762 13.7114C11.4172 13.7114 11.2647 13.6483 11.1522 13.536L10.0002 12.4L10.8482 11.552L11.5562 12.256L13.5362 10.276L14.4002 11.136L12.0002 13.536Z" fill="white"
                    />
                  </svg>
                  <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Amenities</p>
                </div>
              )}
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("roles")) &&
            <NavLink to='/roles'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center hover:text-[#FB6108] cursor-pointer`}>
                <Icon icon="eos-icons:role-binding" className={`${sidebarWidth && "text-[1.5rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Roles</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("tournaments")) &&
            <NavLink to='/tournamentSports'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              {({ isActive }) => (
                <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center  hover:text-[#FB6108] cursor-pointer`}
                  onMouseEnter={() => setSportHover(true)}
                  onMouseLeave={() => setSportHover(false)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={sidebarWidth ? "24" : "18"} height={sidebarWidth ? "24" : "20"} viewBox="0 0 24 24"
                  >
                    <path fill={`${isActive || sportHover ? "#FB6108" : "#4D4D4D"}`} d="M6 12C6 12.5304 5.78929 13.0391 5.41421 13.4142C5.03914 13.7893 4.53043 14 4 14C3.46957 14 2.96086 13.7893 2.58579 13.4142C2.21071 13.0391 2 12.5304 2 12C2 11.4696 2.21071 10.9609 2.58579 10.5858C2.96086 10.2107 3.46957 10 4 10C4.53043 10 5.03914 10.2107 5.41421 10.5858C5.78929 10.9609 6 11.4696 6 12ZM6 12H9C9.26522 12 9.51957 12.1054 9.70711 12.2929C9.89464 12.4804 10 12.7348 10 13V19C10 19.2652 9.89464 19.5196 9.70711 19.7071C9.51957 19.8946 9.26522 20 9 20H6M6 20C6 20.5304 5.78929 21.0391 5.41421 21.4142C5.03914 21.7893 4.53043 22 4 22C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20C2 19.4696 2.21071 18.9609 2.58579 18.5858C2.96086 18.2107 3.46957 18 4 18C4.53043 18 5.03914 18.2107 5.41421 18.5858C5.78929 18.9609 6 19.4696 6 20ZM2 4C2 4.53043 2.21071 5.03914 2.58579 5.41421C2.96086 5.78929 3.46957 6 4 6C4.53043 6 5.03914 5.78929 5.41421 5.41421C5.78929 5.03914 6 4.53043 6 4C6 3.46957 5.78929 2.96086 5.41421 2.58579C5.03914 2.21071 4.53043 2 4 2C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4ZM18 10C18 10.5304 18.2107 11.0391 18.5858 11.4142C18.9609 11.7893 19.4696 12 20 12C20.5304 12 21.0391 11.7893 21.4142 11.4142C21.7893 11.0391 22 10.5304 22 10C22 9.46957 21.7893 8.96086 21.4142 8.58579C21.0391 8.21071 20.5304 8 20 8C19.4696 8 18.9609 8.21071 18.5858 8.58579C18.2107 8.96086 18 9.46957 18 10Z" stroke={`${isActive || sportHover ? "#FB6108" : "#4D4D4D"}`} stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
                    <path fill={`${isActive || sportHover ? "#FB6108" : "#4D4D4D"}`} d="M6 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16H11M14 10H18" stroke={`${isActive || sportHover ? "#FB6108" : "#4D4D4D"}`} stroke-width="2" strokeLinecap="round" stroke-linejoin="round" />
                    <path fill={`${isActive || sportHover ? "#FB6108" : "#4D4D4D"}`} d="M22 19.4286H19.4286V22H18.5714V19.4286H16V18.5714H18.5714V16H19.4286V18.5714H22V19.4286Z" />
                  </svg>
                  <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Tournament Sports</p>
                </div>
              )}
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("payouts")) &&
            <NavLink to='/payOuts'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center hover:text-[#FB6108] cursor-pointer`}>
                <Icon icon="tabler:file-chart" style={{ width: "20px" }} className={`${sidebarWidth && "text-[1.2rem]"}`} />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Pay Outs</p>
              </div>
            </NavLink>
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.navbar?.includes("subscriptions")) &&
            <NavLink to='/subscriptions'
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }
            >
              {({ isActive }) => (
                <div className={`flex  py-3 px-2 hover:bg-[#FFDFCD] ${sidebarWidth ? 'justify-center' : ''} items-center  hover:text-[#FB6108] cursor-pointer`}
                  onMouseEnter={() => setSubscriptionHover(true)}
                  onMouseLeave={() => setSubscriptionHover(false)}
                >
                  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill={`${isActive || subscriptionHover ? "#FB6108" : "#4D4D4D"}`} d="M17.6003 3.60156C17.6003 2.80591 17.2842 2.04285 16.7216 1.48024C16.159 0.917633 15.3959 0.601562 14.6003 0.601562H3.80029C3.00464 0.601562 2.24158 0.917633 1.67897 1.48024C1.11636 2.04285 0.800293 2.80591 0.800293 3.60156V4.20156H17.6003V3.60156ZM17.6003 8.52156V5.40156H0.800293V14.4016C0.800293 15.1972 1.11636 15.9603 1.67897 16.5229C2.24158 17.0855 3.00464 17.4016 3.80029 17.4016H8.72029C8.08569 16.1593 7.86101 14.7479 8.07851 13.37C8.29601 11.9921 8.9445 10.7185 9.93089 9.73216C10.9173 8.74577 12.1908 8.09728 13.5687 7.87978C14.9466 7.66228 16.358 7.88696 17.6003 8.52156ZM14.6003 19.8016C16.0325 19.8016 17.406 19.2326 18.4187 18.2199C19.4314 17.2072 20.0003 15.8337 20.0003 14.4016C20.0003 12.9694 19.4314 11.5959 18.4187 10.5832C17.406 9.57049 16.0325 9.00156 14.6003 9.00156C13.1681 9.00156 11.7946 9.57049 10.7819 10.5832C9.76922 11.5959 9.20029 12.9694 9.20029 14.4016C9.20029 15.8337 9.76922 17.2072 10.7819 18.2199C11.7946 19.2326 13.1681 19.8016 14.6003 19.8016ZM14.0003 12.0016C14.0003 11.8424 14.0635 11.6898 14.176 11.5773C14.2886 11.4648 14.4412 11.4016 14.6003 11.4016C14.7594 11.4016 14.912 11.4648 15.0246 11.5773C15.1371 11.6898 15.2003 11.8424 15.2003 12.0016V13.8016H16.4003C16.5594 13.8016 16.712 13.8648 16.8246 13.9773C16.9371 14.0898 17.0003 14.2424 17.0003 14.4016C17.0003 14.5607 16.9371 14.7133 16.8246 14.8258C16.712 14.9383 16.5594 15.0016 16.4003 15.0016H14.6003C14.4412 15.0016 14.2886 14.9383 14.176 14.8258C14.0635 14.7133 14.0003 14.5607 14.0003 14.4016V12.0016Z" />
                  </svg>

                  <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Subscriptions</p>
                </div>
              )}
            </NavLink>
          }

          <div className={`${sidebarWidth ? 'justify-center' : ''} flex  mt-24 py-10`}>
            <div
              className={({ isActive }) =>
                isActive ? 'text-[#FB6108]' : 'text-[#000]'
              }

              onClick={() => logout()}
            >

              <div className={`flex items-center hover:bg-[#FFDFCD]  hover:text-[#FB6108] py-3 px-2 cursor-pointer`}>
                <Icon icon='material-symbols:logout' />
                <p className={sidebarWidth ? 'hidden' : 'pl-3 text-base font-medium'}>Logout</p>
              </div>
            </div>

          </div>
        </div>

      </aside>
      <button className='bg-orange-900 p-2 absolute right-[-13px] top-4 rounded-full flex items-center justify-center' onClick={() => setSidebarWidth((prev) => !prev)}>
        {
          sidebarWidth ? <img src={backWhiteArrowIcon} alt="" /> : <img src={frontWhiteArrowIcon} alt="" />
        }

      </button>
    </div>
  )
}

export default AdminSidebar;