// export let baseUrl = "http://localhost:4000";
export let baseUrl = "https://stagingapiv2.gamebeez.in";

// const type = "STAGEMAIN";
const type = "PROD";
// const type = "DEV";

if (type === "PROD") {
  baseUrl = "https://apiv2.gamebeez.in";
} else if (type === "STAGEMAIN") {
  baseUrl = "https://stagingapiv2.gamebeez.in";
} else {
  // baseUrl = "https://stagingapiv2.gamebeez.in";
  baseUrl = "http://localhost:4000";
}

export const APIurls = {
  getRoles: `${baseUrl}/api/v2/get-roles`,

  fetchDashBoardStats: `${baseUrl}/api/v2/fetch-dashboard-stats`,

  createClubOwner: `${baseUrl}/api/v2/create-club-owners`,
  fetchClubs: `${baseUrl}/api/v2/fetch-clubOwners`,
  fetchClubById: `${baseUrl}/api/v2/fetch-ClubOwner`,
  updateClubStatus: `${baseUrl}/api/v2/club-status-update`,
  viewClubOwnerSports: `${baseUrl}/api/v2/club-sports`,
  getClubSportById: `${baseUrl}/api/v2/sport`,
  updateSportArenaStatus: `${baseUrl}/api/v2/areana-status-update`,
  updateClubById: `${baseUrl}/api/v2/admin/editClub`,
  fetchClubOwnerDetails: `${baseUrl}/api/v2/fetch-clubOwner-info`,
  fetchAllCoClubOwners: `${baseUrl}/api/v2/get-all/co-owner`,
  addCoOwner: `${baseUrl}/api/v2/add/co-owner`,
  updateCoClubOwner: `${baseUrl}/api/v2/update/co-owner-email`,
  deleteCoClubOwner: `${baseUrl}/api/v2/delete/co-owner`,
  fetchAllDeletedCoClubOwners: `${baseUrl}/api/v2/get-deleted/co-owner`,
  recoverCoClubOwnerFromArchive: `${baseUrl}/api/v2/create-deleted/co-owner`,
  updateClubOwnerEmail: `${baseUrl}/api/v2/edit-clubOwner-email`,
  viewClubTournaments: `${baseUrl}/api/v2/admin/viewClub/tournament`,

  fetchCustomers: `${baseUrl}/api/v2/all-customers`,
  fetchCustomerById: `${baseUrl}/api/v2/fetch-single/customer`,

  createCategories: `${baseUrl}/api/v2/create-category`,
  fetchCategories: `${baseUrl}/api/v2/fetch-categories`,
  fetchCategoryById: `${baseUrl}/api/v2/fetch-category`,
  updateCategory: `${baseUrl}/api/v2/update/category`,

  createSubCategories: `${baseUrl}/api/v2/create-sub-category`,
  fetchSubCategories: `${baseUrl}/api/v2/fetch-all-sub-categories`,
  fetchSubCategoriesByMainCategoryId: `${baseUrl}/api/v2/fetch-sub-categories`,
  fetchSubCategoryById: `${baseUrl}/api/v2/single-sub-category`,
  updateSubCategory: `${baseUrl}/api/v2/update-subcategory`,
  updateSubCategoryHowToPlay: `${baseUrl}/api/v2/update-subcategory-htp`,
  deleteSubCategoryById: `${baseUrl}/api/v2/delete/subcategory`,

  createStaff: `${baseUrl}/api/v2/create-sub-admin`,
  fetchSubadminsById: `${baseUrl}/api/v2/subadmin`,
  editSubadmin: `${baseUrl}/api/v2/subadmin/edit`,
  fetchSubadmins: `${baseUrl}/api/v2/subadmins`,
  fetchSubAdminRoles: `${baseUrl}/api/v2/sub-admin-permission`,
  fetchAdminOrSubAdminDetails: `${baseUrl}/api/v2/admin/getAdminDetails`,
  updateAdminOrSubAdminDetails: `${baseUrl}/api/v2/admin/updateAdminDetails`,

  createNotification: `${baseUrl}/api/v2/add/notification`,
  fetchNotifications: `${baseUrl}/api/v2/notifications/all`,
  deleteNotification: `${baseUrl}/api/v2/notification/delete`,
  fetchNotificationById: `${baseUrl}/api/v2/notification`,
  updateNotification: `${baseUrl}/api/v2/update/notification`,

  createFAQ: `${baseUrl}/api/v2/create-faq`,
  fetchFAQs: `${baseUrl}/api/v2/all-faqs`,
  deleteFAQ: `${baseUrl}/api/v2/delete-faq`,
  fetchFAQById: `${baseUrl}/api/v2/single-faq`,
  updateFAQ: `${baseUrl}/api/v2/update-faq`,

  addAmenities: `${baseUrl}/api/v2/create/amenities`,
  fetchAmenities: `${baseUrl}/api/v2/all/amenities`,
  fetchActiveAmenities: `${baseUrl}/api/v2/active/amenities`,
  fetchAmenityById: `${baseUrl}/api/v2/amenities`,
  updateAmenity: `${baseUrl}/api/v2/update/amenity`,
  archiveAmenity: `${baseUrl}/api/v2/archive/amenity`,
  toggleAmenityStatus: `${baseUrl}/api/v2/update/amenity/status`,

  fetchClubOwnerDashBoardData: `${baseUrl}/api/v2/admin/dashboard`,
  fetchClubOwnerInventoryData: `${baseUrl}/api/v2/admin/inventory`,
  fetchClubOwnerBookingData: `${baseUrl}/api/v2/admin/bookings`,
  fetchClubOwnerInventoryByIdData: `${baseUrl}/api/v2/admin/specificInventory`,
  fetchClubOwnerExpensesData: `${baseUrl}/api/v2/admin/getAllExpenses`,
  fetchSpecificExpenseData: `${baseUrl}/api/v2/admin/getSpecificExpense`,
  fetchClubOwnerCustomerData: `${baseUrl}/api/v2/admin/customers`,
  fetchAllMainBookingData: `${baseUrl}/api/v2/allClubs/allBookings`,
  fetchSpecificCustomerBookingDataById: `${baseUrl}/api/v2/admin/specificCustomer/bookings`,
  fetchCustomerBookingData: `${baseUrl}/api/v2/admin/specificCustomer/bookings`,
  fetchSpecificCustomerBookingData: `${baseUrl}/api/v2/admin/specificBooking`,
  fetchAllLocationsOffersandBanners: `${baseUrl}/api/v2/fetch/club-based-on-location`,
  fetchCustomersForCanteenReports: `${baseUrl}/api/v2/admin/reports/canteen/getTopCustomersCanteens`,
  fetchClubWiseCanteenSalesData: `${baseUrl}/api/v2/admin/reports/canteen/getTopClubsSales`,
  fetchPaymentDataCanteenReports: `${baseUrl}/api/v2/admin/reports/canteen/getCanteensPayments`,
  updateCustomerdisableStatus: `${baseUrl}/api/v2/disable-users`,
  fetchAllSpecificCustomerSubscriptions: `${baseUrl}/api/v2/arena-subscribed/customer`,

  creationOfRole: `${baseUrl}/api/v2/admin/createRole`,
  fetchRoles: `${baseUrl}/api/v2/admin/getCreatedRoles`,
  updatingRole: `${baseUrl}/api/v2/admin/updateRole`,
  fetchSpecificRoleData: `${baseUrl}/api/v2/admin/getSpecificRole`,
  updateStatusOfRole: `${baseUrl}/api/v2/admin/updateRoleStatus`,
  deletingRole: `${baseUrl}/api/v2/admin/deleteRole`,

  tournamentCreation: `${baseUrl}/api/v2/admin/tournament`,
  fetchAllTournaments: `${baseUrl}/api/v2/admin/tournament/tournamentStatus`,
  fetchOverViewTournamentData: `${baseUrl}/api/v2/admin/tournament/overview`,
  fetchParticipantsList: `${baseUrl}/api/v2/admin/getParticipants`,
  fetchAllDropDownVenues: `${baseUrl}/api/v2/admin/venue/getDropDownForVenues`,
  creatingVenue: `${baseUrl}/api/v2/admin/venue/createVenue`,
  fetchVenues: `${baseUrl}/api/v2/admin/venue/getVenues`,
  editVenue: `${baseUrl}/api/v2/admin/venue/editVenue`,
  specificVenue: `${baseUrl}/api/v2/admin/venue/getSpecificVenue`,
  deletingSpecificVenue: `${baseUrl}/api/v2/admin/venue/deleteVenue`,
  fetchGameFixations: `${baseUrl}/api/v2/admin/tournament/getRoundsAndMatches`,
  fetchRoundModalDataToArranged: `${baseUrl}/api/v2/admin/tournament/getRoundMatches/manualFixing`,
  setArrangedTeamsIntoMatches: `${baseUrl}/api/v2/admin/tournament/setRoundMatches/manualFixing`,
  fetchMatchDetailsById: `${baseUrl}/api/v2/admin/tournament/matches/getMatch`,
  updateWinnerById: `${baseUrl}/api/v2/admin/tournament/knockout/updateWinner`,
  addPartcipantBasedOnFormat: `${baseUrl}/api/v2/admin/tournament/addParticipant`,
  deletePartcipantBasedOnFormat: `${baseUrl}/api/v2/admin/tournament/removeParticipant`,
  deleteTournamentById: `${baseUrl}/api/v2/admin/tournament`,
  EditMatchDetailsInGameFixtures: `${baseUrl}/api/v2/admin/edit-match-details`,
  fetchPlayersInfoByPhoneNumber: `${baseUrl}/api/v2/admin/exist-user/fetch`,
  fetchDetailsOfSpecificTeam: `${baseUrl}/api/v2/admin/fetch-participants-details`,
  createPlayersForTeam: `${baseUrl}/api/v2/admin/add-players-teams`,
  deleteParticipantFromTeam: `${baseUrl}/api/v2/admin/remove-player`,
  editParticipantName: `${baseUrl}/api/v2/admin/edit-teamName`,
  updateTournamentDetails: `${baseUrl}/api/v2/admin/tournament/updateTournament`,
  fetchSpecificParticipantDetails: `${baseUrl}/api/v2/admin-fetch-specificParticipant`,
  recoverTournament: `${baseUrl}/api/v2/admin/tournament/recoverTournament`,
  deleteTournamentAtArchive: `${baseUrl}/api/v2/admin/tournament/archivedDelete`,
  updateWinnerForDoubleKnockoutById: `${baseUrl}/api/v2/admin/tournament/double/updateWinner`,
  updateWinnerForRoundRobbinById: `${baseUrl}/api/v2/admin/tournament/RoundRobbin/updateWinner`,
  getPointsTableForRoundRobbinById: `${baseUrl}/api/v2/admin/tournament/RoundRobbin/pointsTable`,
  fetchAllTournamentSports: `${baseUrl}/api/v2/admin/tournament/sports`,
  createSportForTournament: `${baseUrl}/api/v2/admin/tournament/sports`,
  updateSportNameForTournament: `${baseUrl}/api/v2/admin/tournament/sports/editName`,
  updateSportStatusForTournament: `${baseUrl}/api/v2/admin/tournament/sports/updateStatus`,
  fetchCitiesForFilteringTournaments: `${baseUrl}/api/v2/admin/tournament/getCitiesAndClubs`,
  updateScoreForMatch: `${baseUrl}/api/v2/admin/tournament/updateScoreType`,
  updateTournamentMatchSetsNumber: `${baseUrl}/api/v2/admin/tournament/match/updateSetsNumber`,
  updateTournamentMatchScores: `${baseUrl}/api/v2/admin/tournament/match/updateScores`,
  updateTournamentMatchCricketScores: `${baseUrl}/api/v2/admin/tournament/match/updateCricketScores`,
  createSponsorOrOrganizerForTournament: `${baseUrl}/api/v2/admin/tournament/sponsors`,
  fetchAllSponsorsOrOrganizers: `${baseUrl}/api/v2/admin/tournament/sponsors`,
  editSpecificSponsorOrOrganizer: `${baseUrl}/api/v2/admin/tournament/sponsors/updateSponsor`,
  fetchSpecificOrganizer: `${baseUrl}/api/v2/admin/tournament/sponsors/getSponsor`,
  deleteSpecificSponsor: `${baseUrl}/api/v2/admin/tournament/sponsors/removeSponsor`,
  addOrEditParticipantPlayer: `${baseUrl}/api/v2/admin/tournament/participant/player/edit`,
  viewClubTournaments: `${baseUrl}/api/v2/admin/viewClub/tournament`,
  saveMatchTossWinner: `${baseUrl}/api/v2/admin/tournament/match/tossWinner`,
  saveFootballScores: `${baseUrl}/api/v2/admin/tournament/match/updateFootballScores`,

  fetchDropDownsInPayOuts: `${baseUrl}/api/v2/dropdown-payouts`,
  fetchOnlineClubsInPayOuts: `${baseUrl}/api/v2/fetch-online-booking/payout`,
  fetchSpecificClubPayOut: `${baseUrl}/api/v2/get-payouts-online-booking`,
  updatePayOutStatus: `${baseUrl}/api/v2/update-online-booking/payout`,

  fetchAllLocationsForSubscriptions: `${baseUrl}/api/v2/fetch-all-club-location`,
  fetchAllClubsBasedLocation: `${baseUrl}/api/v2/fetch/club-for-location`,
  fetchAllSubscriptions: `${baseUrl}/api/v2/fetch-new-subscription/payout`,
  updateStatusOfSubscriptionPayout: `${baseUrl}/api/v2/update-subscription/payout`,
  fetchSpecificClubSubscription: `${baseUrl}/api/v2/get-payouts-subscription`,
};