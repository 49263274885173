import React, { useEffect, useState } from 'react'
import { Option, Select, Switch, ThemeProvider } from '@material-tailwind/react';
import { selectCustomTheme } from '../../../helpers/constants';
import { Link, useOutletContext } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';


export default function CreateFAQ() {
   const [setUpdateFAQList] = useOutletContext();
   const [showError, setShowError] = useState(false);
   const [categorySelect, setCategorySelect] = useState("");
   const [sendTo, setSendTo] = useState({
      customers: false,
      clubOwners: false,
      all: false,
   });
   const [isSendToAdded, setIsSendToAdded] = useState(false);
   const [loaderBtn, setLoaderBtn] = useState(false);
   const { register, handleSubmit, formState: { errors }, reset } = useForm();
   const { getAccessToken, userRole } = useAuth();

   const switchToggleHandler = (e, key) => {
      // console.log(e.target.checked);
      // console.log("switch", e.target.value, key);
      if (key === "all" && (sendTo.customers || sendTo.clubOwners)) {
         setSendTo((prevState) => {
            const updatedObj = {
               ...prevState,
               customers: false,
               clubOwners: false,
               all: true,
            }
            setIsSendToAdded(Object.values(updatedObj).some(value => value === true));
            return updatedObj;
         })
      } else if (key !== "all" && sendTo.all && e.target.checked) {
         setSendTo((prevState) => {
            const updatedObj = {
               ...prevState,
               [key]: true,
               all: false,
            }
            setIsSendToAdded(Object.values(updatedObj).some(value => value === true));
            return updatedObj;
         })
      }
      else {
         setSendTo((prevState) => {
            const updatedObj = {
               ...prevState,
               [key]: !prevState[key],
            }
            setIsSendToAdded(Object.values(updatedObj).some(value => value === true));
            return updatedObj;
         })
      }
   }

   const onSubmit = async (data) => {
      if (!(categorySelect && isSendToAdded)) {
         setShowError(true);
         return;
      } else {
         setShowError(false);
      }
      const { question, answer } = data;
      const token = await getAccessToken()
      try {
         setLoaderBtn(true);
         const response = await fetch(APIurls.createFAQ, {
            method: "POST",
            body: JSON.stringify({
               "category": categorySelect,
               "question": question,
               "answer": answer,
               "status": "active",
               "sendTo": {
                  "customers": sendTo.customers,
                  "clubOwners": sendTo.clubOwners,
                  "all": sendTo.all,
               }
            }),
            headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
            }
         })
         if (!response.ok) {
            if (response.status === 400) {
               const errorData = await response.json();
               console.log("Error Data:", errorData);
               toast.error(errorData.message);
               throw new Error(errorData.message);
            } else {
               toast.error("Failed to Create FAQ");
               throw new Error("Failed to Create FAQ");
            }
         }
         const result = await response.json();
         console.log("Create FAQ result", result);
         toast.success("FAQ is Created Successfully!");
         setUpdateFAQList(true);
         reset();
         setCategorySelect("");
         setSendTo({
            customers: false,
            clubOwners: false,
            all: false,
         })
         setIsSendToAdded(false);
         // navigate("/admin/agents")
      } catch (error) {
         console.log(error.message);
         //toast.error("Failed to Create Agent!")
      }
      setLoaderBtn(false);
   }

   return (
      <ThemeProvider value={selectCustomTheme}>
         {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("CREATE_FAQS")) &&
            <form onSubmit={handleSubmit(onSubmit)}
               className='flex flex-col gap-[1.5rem]'>
               <div className="flex items-center justify-between">
                  <h1 className="text-[1.2rem] sm:text-[1.3rem] font-semibold">
                     Add New FAQ
                  </h1>
                  {
                     loaderBtn ? (
                        <ThreeDots
                           height="50"
                           width="50"
                           radius="9"
                           color="#FB6108"
                           ariaLabel="three-dots-loading"
                           wrapperStyle={{}}
                           wrapperClassName=""
                           visible={true}
                        />
                     ) : (
                        <button type='submit'
                           className="text-[0.8rem] sm:text-[1rem] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[0.4rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md font-medium">
                           <span className="whitespace-nowrap">Submit</span>
                           <Icon
                              icon="material-symbols:add"
                              className="text-[1.2rem] sm:text-[1.4rem]"
                           />
                        </button>
                     )
                  }
               </div>
               <div className='flex gap-[3rem] items-baseline px-[2rem]'>
                  <div className='flex flex-col gap-[1.5rem] flex-[2]'>
                     <div className='flex flex-col gap-[0.5rem]'>
                        <Select label='Select Category' color='blue-gray' size='lg'
                           className='text-black'
                           value={categorySelect}
                           onChange={(value) => setCategorySelect(value)}
                        >
                           <Option value='account'>Account</Option>
                           <Option value='booking'>Booking</Option>
                        </Select>
                        {showError && !categorySelect && (
                           <p className="text-sm text-[#E92215]">
                              *This field is required
                           </p>
                        )}
                     </div>
                     <div className="flex flex-col gap-[0.5rem]">
                        <input
                           className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                           type="text"
                           placeholder="Enter Question"
                           {...register("question", {
                              required: "*This field is required.",
                           })}
                        />
                        {errors.question && (
                           <p className="text-sm text-[#E92215]">
                              {errors.question.message}
                           </p>
                        )}
                     </div>
                     <div className="flex flex-col gap-[0.5rem]">
                        <textarea rows={7}
                           className="text-[0.75rem] sm:text-[1rem] font-[500] py-[0.6rem] px-[1rem] bg-[#F6F7FB] placeholder:text-[#7F7F7F] rounded-md outline-none"
                           type="text"
                           placeholder="Enter Answer"
                           {...register("answer", {
                              required: "*This field is required.",
                           })}
                        />
                        {errors.answer && (
                           <p className="text-sm text-[#E92215]">
                              {errors.answer.message}
                           </p>
                        )}
                     </div>
                  </div>
                  <div className='flex-[1]'>
                     <div className={`grid grid-cols-4 mb-[1.3rem] gap-x-[1rem]`}>
                        <label htmlFor="customers"
                           className={`col-span-3 text-[#808080] text-[1rem] font-medium  ${sendTo.customers && "text-[black] font-semibold"}`}>
                           Customers Only
                        </label>
                        <Switch
                           id="customers"
                           ripple={false}
                           className="h-full w-full checked:bg-primary col-span-1"
                           containerProps={{
                              className: "w-11 h-6",
                           }}
                           circleProps={{
                              className: "before:hidden left-0.5 border-none",
                           }}
                           checked={sendTo.customers}
                           onChange={(e) => switchToggleHandler(e, "customers")}
                        />
                     </div>
                     <div className={`grid grid-cols-4 mb-[1.3rem] gap-x-[1rem]`}>
                        <label htmlFor="customers"
                           className={`col-span-3 text-[#808080] text-[1rem] font-medium  ${sendTo.clubOwners && "text-[black] font-semibold"}`}>
                           Club Owners Only
                        </label>
                        <Switch
                           id="clubOwners"
                           ripple={false}
                           className="h-full w-full checked:bg-primary col-span-1"
                           containerProps={{
                              className: "w-11 h-6",
                           }}
                           circleProps={{
                              className: "before:hidden left-0.5 border-none",
                           }}
                           checked={sendTo.clubOwners}
                           onChange={(e) => switchToggleHandler(e, "clubOwners")}
                        />
                     </div>
                     <div className={`grid grid-cols-4 mb-[1.3rem] gap-x-[1rem]`}>
                        <label htmlFor="customers"
                           className={`col-span-3 text-[#808080] text-[1rem] font-medium  ${sendTo.all && "text-[black] font-semibold"}`}>
                           All
                        </label>
                        <Switch
                           id="all"
                           ripple={false}
                           className="h-full w-full checked:bg-primary col-span-1"
                           containerProps={{
                              className: "w-11 h-6",
                           }}
                           circleProps={{
                              className: "before:hidden left-0.5 border-none",
                           }}
                           checked={sendTo.all}
                           onChange={(e) => switchToggleHandler(e, "all")}
                        />
                     </div>
                     {showError && !isSendToAdded && (
                        <p className="text-sm text-[#E92215]">
                           *Select atleast one of the above
                        </p>
                     )}
                  </div>
               </div>
            </form>
         }
      </ThemeProvider>
   )
}
