import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import { IconButton } from '@material-tailwind/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import { FcCancel } from "react-icons/fc";
import { GoCheckCircle } from "react-icons/go";
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useQuery } from 'react-query';
import moment from 'moment';
import SportEditModal from './SportEditModal';
import { toast } from 'react-toastify';

export default function ManageSports({ updateSport, setUpdateSport }) {

  const [showModal, setShowModal] = useState(false)
  const [sportId, setSportId] = useState('')
  const [sportName, setSportName] = useState("")
  const { getAccessToken, userRole } = useAuth();

  const fetchSports = async () => {

    const token = await getAccessToken()
    const response = await fetch(`${APIurls.fetchAllTournamentSports}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message)
    }
    console.log("Fetched Sports Data", result?.response?.tournamentSports)
    // setSportName(result?.response?.tournamentSports[0].name)
    return result?.response?.tournamentSports
  }

  const { data: sportsData, refetch, isLoading } = useQuery("sportsdata", fetchSports)

  if (updateSport) {
    refetch();
    setUpdateSport(false);
  }

  const handleShowModal = (id, name) => {
    setSportName(name)
    setSportId(id)
    setShowModal(true)
  }

  const handleUpdateSport = async (id) => {

    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.updateSportStatusForTournament}/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
      const result = await response.json();
      if (result.code > 200) {
        toast.error(result.message)
        console.log(result.message)
      }
      console.log("Sport updated successfully", result)
      toast.success("Sport Status updated successfully")
      refetch();
    }
    catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "80px",
      center: true
    },
    {
      name: "Sport Name",
      selector: row => (
        <div>{row?.name}</div>
      ),
      grow: 2.0,
      center: true
    },
    {
      name: "Date & Time",
      selector: row => (
        <div>
          {moment(row?.createdAt).format("MMM Do YY")}{","}{moment(row?.createdAt).format('LT')}
        </div>
      ),
      center: true
    },
    {
      name: "Status",
      selector: row => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[6rem] ${row?.status === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-red-400 bg-red-50"}`}>
          <span className={`${row?.status === "ACTIVE" ? "bg-[#25BF17]" : "bg-red-400"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row?.status}</h2>
        </div>
      ),
      center: true
    },
    {
      name: "Actions",
      selector: row => (
        <div className="flex items-center gap-[0.5rem]">
          <IconButton
            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
            onClick={() => handleShowModal(row?.sportID, row?.name)}
          >
            <Icon
              icon="material-symbols:edit-rounded"
              className="text-[1.5rem]"
            />
          </IconButton>

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_SPORTS_STATUSES")) ?
            (<IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
              onClick={() => handleUpdateSport(row?.sportID)}
            >
              {
                row?.status === 'ACTIVE' ? <FcCancel className='text-[1.5rem]' /> : <GoCheckCircle className='text-[1.5rem] text-green-500' />
              }
            </IconButton>)
            :
            "-"
          }
        </div>
      ),
      center: true
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  return (
    <div>
      <div className='flex justify-between pb-[1rem]'>
        <p className="text-[1.2rem] sm:text-[1.3rem] font-semibold">Manage Sports</p>
        {/* <div className='flex items-center gap-3'> */}
        {/* <div className='flex items-center gap-1 hover:text-primary'>
            <Icon icon='material-symbols:delete' className='text-[1.5rem]' />
            <p>Remove</p>
          </div> */}
        {/* <Select label='Change Status'
            value={status}
            onChange={(value) => handleSelectStatus(value)}
          >
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value='HOLD'>HOLD</Option>
            <Option value='clear_all'>CLEAR ALL</Option>
          </Select> */}
        {/* </div> */}
      </div>
      <DataTable
        columns={columns}
        data={sportsData}
        customStyles={customStyles}
        pagination
        paginationPerPage={10}
        selectableRows
        selectableRowsComponent={CustomCheckbox}
        progressPending={isLoading}
      />
      {
        showModal && (
          <SportEditModal
            setShowModal={setShowModal}
            sportId={sportId}
            sportName={sportName}
            setIsRefetch={refetch}
          />
        )}
    </div>
  )
}
