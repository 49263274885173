import { Link, useParams } from "react-router-dom";
import {
   Button,
   IconButton,
   Menu,
   MenuHandler,
   MenuItem,
   MenuList,
} from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useAuth } from './../../../../store/AuthContext';
import { APIurls } from "../../../../api/apiConstant";
import { useQuery } from "react-query";
import moment from "moment";
import DownloadExcel from "../../../../exportData/ExportExcel";
import pdfLogo from '../../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../../../exportData/ExportPDF';

export default function ViewCOInventory() {
   // const [loadingStates, setLoadingStates] = useState({});
   const [base64Logo, setBase64Logo] = useState("");
   const [loading, setLoading] = useState(false);
   const [adminName, setAdminName] = useState("")
   const { id } = useParams();

   const { getAccessToken } = useAuth();

   const fetchClubOwnerInventory = async () => {
      try {
         const token = await getAccessToken();
         const response = await fetch(`${APIurls.fetchClubOwnerInventoryData}/${id}`, {
            method: 'GET',
            headers: {
               Authorization: `Bearer ${token}`,
               'Content-Type': "application/json"
            }
         })
         const result = await response.json();
         if (!response.ok) {
            throw new Error(result.message);
         }
         console.log("View Club Owner Inventory", result?.response?.inventory);
         return result?.response?.inventory.reverse();
      }
      catch (error) {
         console.error(error.message, "Error in fetching inventory of clubs")
      }
   }
   const { data: inventoryData, isLoading, refetch } = useQuery('clubOwnerInventoryById', fetchClubOwnerInventory);

   const fetchAdminDetails = async () => {
      try {
         const token = await getAccessToken();
         const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
            method: 'GET',
            headers: {
               'Authorization': `Bearer ${token}`
            }
         })
         const result = await response.json();
         if (!response.ok) {
            throw new Error(result.message)
         }
         setAdminName(result?.response?.name)
      } catch (error) {
         console.log("Error in fecthing admin details", error);
      }
   }

   useEffect(() => {
      fetchAdminDetails();
   }, [])

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            background: "#FFF",
         },
      },

      rows: {
         style: {
            background: "#FFF",
            color: "#565656",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
            padding: "5px 0px"
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };

   const columns = [
      {
         name: "SL",
         id: "sl",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: 'ID',
         id: "inventoryId",
         selector: row => row?.ID,
         center: true,
         grow: 3.5,
      },
      {
         name: 'Equipment Name',
         id: "equipmentName",
         selector: row => row?.equipmentName,
         center: true,
         grow: 2.8,
      },
      {
         name: 'Equipment Type',
         id: "equipmentType",
         selector: row => row?.equipmentType,
         center: true,
         grow: 4
      },
      {
         name: 'Date of Purchased',
         id: "purchasedDate",
         selector: row => <div>{moment(row?.dateOfPurchase).format("MMM Do YY")}</div>,
         center: true,
         grow: 2.5,
      },
      {
         name: 'Total Amount',
         id: "totalAmount",
         selector: row => row?.totalAmount,
         center: true,
         grow: 1,
      },
      {
         name: 'Warranty',
         id: "warranty",
         selector: row => row?.warranty,
         center: true,
      },
      {
         name: 'Remarks',
         id: "remarks",
         selector: row => row?.remarks,
         center: true,
         grow: 1,
      },
      {
         name: "Status",
         id: "status",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.3rem] w-[6rem] ${row?.status ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
               <span className={`${row?.status ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
               <h2>{row?.status === true ? "Active" : "Inactive"}</h2>
            </div>
         ),
         center: true,
         grow: 3,
         sortable: true,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex gap-[0.5rem]">
               <Link to={`view/${row?.ID}`}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="mdi:eye"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link>
               {/* <Link to={``}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="ic:round-print"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link> */}
            </div>
         ),
         center: true,
         grow: 1,
      },
   ];

   const convertImageToBase64 = async (imagePath) => {
      try {
         const response = await fetch(imagePath);
         const blob = await response.blob();

         return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
               resolve(reader.result);
            };
            reader.onerror = (error) => {
               reject(error);
            };
            reader.readAsDataURL(blob);
         });
      } catch (error) {
         console.error('Error converting image to base64:', error);
         throw error;
      }
   };

   const handleDownloadPDF = async () => {
      setLoading(true);
      try {
         const base64String = await convertImageToBase64(gamebeezLogo);
         setBase64Logo(base64String);

         const pdfData = inventoryData?.map((ele, index) => [
            index + 1,
            ele?.ID,
            ele?.equipmentName,
            ele?.equipmentType,
            moment(ele?.dateOfPurchase).format("MMM Do YY"),
            ele?.totalAmount,
            ele?.warranty,
            ele?.remarks,
            ele?.status === true ? "Active" : "Inactive",
         ]);

         const pdfColumns = ["SL", "ID", "Equipment Name", "Equipment Type", "Date of Purchase", "Total Amount", "Warranty", "Remarks", "Status"];

         await DownloadPDF(pdfData, pdfColumns, "Club Inventory.pdf", base64String, adminName);
      } catch (error) {
         console.error("Error generating PDF:", error);
      } finally {
         setLoading(false);
      }
   };

   const excelData = inventoryData?.map((item, index) => [
      index + 1,
      item?.ID,
      item?.equipmentName,
      item?.equipmentType,
      moment(item?.dateOfPurchase).format("MMM Do YY"),
      item?.totalAmount,
      item?.warranty,
      item?.remarks,
      item?.status === true ? "Active" : "Inactive",
   ])

   const excelColumns = ["SL", "ID", "Equipment Name", "Equipment Type", "Date of Purchase", "Total Amount", "Warranty", "Remarks", "Status"];


   return (
      <div className='bg-white rounded-md flex flex-col gap-[0.5rem]'>
         <div className='flex items-center justify-between px-[1rem]'>
            <h2 className='text-[1.3rem] font-semibold'>
               Inventory
            </h2>
            <div className='flex gap-3 items-center rounded-md'>
               {loading ? (
                  <p className="text-xs">Generating PDF...</p>
               ) : (
                  <img
                     src={pdfLogo}
                     className='w-[25px] cursor-pointer'
                     onClick={handleDownloadPDF}
                     alt="Generate PDF"
                  />
               )}
               <FaFileCsv
                  className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                  onClick={() => DownloadExcel({ excelData, excelColumns })}
               />
            </div>
         </div>
         <div>
            <DataTable
               columns={columns}
               customStyles={customStyles}
               data={inventoryData}
               pagination
               selectableRows
               progressPending={isLoading}
               selectableRowsComponent={CustomCheckbox}
            />
         </div>
         <ToastContainer />
      </div>
   )
}
