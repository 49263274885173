import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { toast } from 'react-toastify';

export default function EditThreeButton({ matchID, tournamentType, setIsRefetch, participantID, matchData }) {

  const [anchorEl, setAnchorEl] = useState(null);

  const { getAccessToken } = useAuth();

  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  console.log("Participant Id", participantID)
  // console.log("Match Data", matchData);
  let participantId;

  const handleClose = (option) => {
    setAnchorEl(null);

    if (option === "Win") {
      participantId = participantID;
    } else if (option === "No Show") {
      if (participantID === matchData?.participantA_Id) {
        console.log("Opposite Participant Id", matchData?.participantB_Id)
        participantId = matchData?.participantB_Id
      } else if (participantID === matchData?.participantB_Id) {
        console.log("Opposite Participant Id", matchData?.participantA_Id)
        participantId = matchData?.participantA_Id
      }
    }
    updateWinner();
  };

  const updateWinner = async () => {
    try {
      const token = await getAccessToken();
      const url =
        tournamentType === "Knockout"
          ? APIurls.updateWinnerById
          : tournamentType === "Double Elimination Bracket"
            ? APIurls.updateWinnerForDoubleKnockoutById
            : APIurls.updateWinnerForRoundRobbinById;

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ matchID: matchID, winnerID: participantId }),
      });

      const result = await response.json();
      if (result?.code > 201) {
        console.log("Winner not updated ", result?.message);
        toast.error(result?.message);
        return;
      }
      setIsRefetch(true);
      console.log("Winner Updated Successfully", result);
      toast.success("Winner Updated Successfully");
    } catch (error) {
      console.log("Error in Updating Winner", error);
      toast.error("Error updating winner");
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose("")}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {["Win", "No Show"].map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleClose(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
