import React from "react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { useQuery } from "react-query";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const UpdateBooking = () => {

  const { customerId, bookingId } = useParams();
  const navigate = useNavigate();
  const { getAccessToken } = useAuth();

  console.log("VIEW BOOKING ID", bookingId);

  const fetchClubOwnerBookingData = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchSpecificCustomerBookingData}/${bookingId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("FETCH SPECIFIC BOOKING DATA", result?.response);
    return result?.response;
  };
  const {
    data: specificBookingData,
    isLoading,
    refetch,
  } = useQuery("customersSpecificBookings", fetchClubOwnerBookingData);

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Sports",
      selector: (row) => (
        <div className="flex flex-col">
          {specificBookingData?.booking?.sport}
        </div>
      ),
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Court Details",
      id: "dob",
      selector: (row) => (
        <div className="flex flex-col">
          <p>Name: {specificBookingData?.booking?.boardDetails?.boardName}</p>
          <p>No: {specificBookingData?.booking?.boardDetails?.boardNo}</p>
          <p>Id: {specificBookingData?.booking?.boardDetails?.boardsUID}</p>
        </div>
      ),
      center: true,
    },

    {
      name: "Date of Booking",
      id: "date_of_Booking",
      selector: (row) => (
        <div>
          {moment(specificBookingData?.booking?.dateOfBooking).format(
            "MMM Do YY"
          )}
        </div>
      ),
      center: true,
    },
    {
      name: "Time slot",
      id: "time_slot",
      selector: (row) => (
        <div>
          {specificBookingData?.booking?.timeSlots?.map((div) => {
            const newDate = div
            return (
              <div>
                <p>{moment(newDate).format("LT")}</p>
              </div>
            );
          })}
        </div>
      ),
      center: true,
    },
    {
      name: "Booking Type",
      id: "booking_type",
      selector: (row) => (
        <div className=" px-[16px] py-1 bg-[#94FF824D] bg-opacity-30 rounded-md flex justify-center items-center gap-2.5">
          <div className="text-[#1CC500] text-sm font-medium">
            {specificBookingData?.booking?.bookingType}
          </div>
        </div>
      ),
      center: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#000019",
        fontSize: "13px",
        background: "#F6F8FC",
      },
    },
    head: {
      style: {
        borderRadius: "6px 6px 6px 6px",
        // border: "0.824px solid #E9E9E9",
        // background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        // background: "#FFF",
        color: "#00000",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 600,
        width: "auto",
      },
    },

    table: {
      style: {
        overflow: "visible",
        maxWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columnsForRentals = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "RentalID",
      selector: (row) => <div className="flex flex-col">{row?.RentalID}</div>,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Court Details",
      id: "dob",
      selector: (row) => (
        <div className="flex flex-col">
          {row.players?.map((div) => {
            return (
              <div>
                <p>Player Name: {div?.name}</p>
                <p>Email: {div?.name}</p>
                <p>MobileNo: {div?.phone}</p>
              </div>
            );
          })}
        </div>
      ),
      center: true,
    },
    {
      name: "Start Date",
      id: "startDate",
      selector: (row) => <div>{moment(row.startDate).format("MMM Do YY")}</div>,
      center: true,
    },
    {
      name: "End Date",
      id: "endDate",
      selector: (row) => <div>{moment(row.endDate).format("MMM Do YY")}</div>,
      center: true,
    },
    {
      name: "Total Amount",
      id: "totalAmount",
      selector: (row) => <div>{row.totalAmount}</div>,
      center: true,
    },
    {
      name: "Rental Status",
      id: "rental_status",
      selector: (row) => (
        <div className=" px-[16px] py-1 bg-[#94FF824D] bg-opacity-30 rounded-md flex justify-center items-center gap-2.5">
          <div className="text-[#1CC500] text-sm font-medium">
            {row?.isCompleted === true && "Completed"}
          </div>
        </div>
      ),
      center: true,
    },
  ];

  const data = [
    {
      question: "How to Login?",
      answer: "Go to Header section and click on login button",
    },
  ];

  if (isLoading) {
    return (
      <div class="flex items-center justify-center pt-4 min-h-screen">
        <div class="w-10 h-10 border-4 border-t-orange-500 border-gray-200 rounded-full animate-spin"></div>
      </div>
    )
  }

  return (
    <div className="bg-white">
      <div className="flex gap-8 justify-between items-center pt-[1.5rem] pb-[24px] mx-6">
        <div className="flex items-center">
          <h2 className="text-[#808080] text-[16px] font-semibold whitespace-nowrap cursor-pointer"
            onClick={() => navigate("/customers")}
          >
            Customers
          </h2>
          <Icon
            icon="f7:greaterthan"
            className="h-[16px] w-[16px] text-[#808080]"
          />
          <p>{specificBookingData?.customerDetails?.name}</p>
          <div
            className="cursor-pointer"
            onClick={() => navigate(`/customers/view/${customerId}`)}
          >
            <span className="text-[#808080] text-[16px] font-semibold whitespace-nowrap">
            </span>
            <span className="text-zinc-500 text-base font-normal text-[#808080]">

              (Bookings)
            </span>
          </div>
          <Icon
            icon="f7:greaterthan"
            className="h-[16px] w-[16px] text-[#808080]"
          />
          <div>
            <span className="text-black text-[20px] font-[600] whitespace-nowrap">
              {bookingId}{" "}
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-between mx-6 py-5">
        <div className="flex gap-1">
          <label className="text-[#808080] text-xl">Customer ID:</label>
          <p>{customerId}</p>
        </div>
        <div className="">
          <h2 className="text-[1.15rem] font-bold my-[1rem]">
            Payment Details
          </h2>
          <div className="grid grid-cols-2 gap-y-[1rem] gap-x-[2rem]">
            <label className="text-[#858585] font-medium">Payment Mode</label>
            <h2 className="font-semibold">
              {specificBookingData?.paymentDetails?.paymentMode}
            </h2>
            <label className="text-[#858585] font-medium">Status</label>
            <div
              className={`flex justify-center items-center gap-[0.5rem] text-[0.9rem] rounded-md font-semibold py-[0.2rem] w-[6rem] ${specificBookingData?.paymentDetails?.status?.toLowerCase() ===
                "paid"
                ? "text-[#25BF17] bg-[#D1F0CF]"
                : "text-[#FF0023] bg-[#FDCAD1]"
                }`}
            >
              <h2>{specificBookingData?.paymentDetails?.status}</h2>
            </div>
            <label className="text-[#858585] font-medium">
              Payment Method{" "}
            </label>
            <h2 className="font-semibold">
              {specificBookingData?.paymentDetails?.paymentMethod}
            </h2>
            {/* <label className="text-[#858585] font-medium">Transaction ID</label>
    <h2 className="font-semibold">TRA123DSD2231</h2> */}
            <label className="text-[#858585] font-medium">Total Amount</label>
            <h2 className="font-semibold">
              ₹ {Math.round(specificBookingData?.paymentDetails?.totalAmount)}
            </h2>
            {/* <label className="text-[#858585] font-medium">Remarks</label> */}
          </div>
        </div>
      </div>
      <div className="flex-col items-start justify-start mx-6 ">
        <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
          Booking Details
        </div>
        <DataTable columns={columns} customStyles={customStyles} data={data} />
      </div>
      <div className="flex-col items-start justify-start mx-6 mt-11 ">
        <div className="text-slate-950 text-lg font-bold mx-4 mb-5">
          Rent Details
        </div>
        <DataTable
          columns={columnsForRentals}
          customStyles={customStyles}
          data={specificBookingData?.rentals}
        />
      </div>
    </div>
  );
};

export default UpdateBooking;
