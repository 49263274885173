import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DownloadPDF from '../../../exportData/ExportPDF';
import DownloadExcel from '../../../exportData/ExportExcel';
import CircleLoader from 'react-spinners/CircleLoader';

export default function NotificationsList({ updateNotificationsList, setUpdateNotificationsList }) {
   const [loadingStates, setLoadingStates] = useState({});
   const [selectedRows, setSelectedRows] = useState([])
   const { getAccessToken, userRole } = useAuth();

   const fetchNotificationsData = async () => {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchNotifications}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${token}`,
         }
      })
      const result = await response.json();
      if (!response.ok) {
         throw new Error(result.message);
      }
      console.log("Notifications List", result?.response?.Notifications);
      return result?.response?.Notifications.reverse();
   }
   const { data: notificationsData, isLoading, refetch } = useQuery('notifications', fetchNotificationsData);

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            // padding: "10px 10px",
            color: "#4D4D4D",
            fontSize: "13px",
         },
      },
      head: {
         style: {
            background: "#FFF",
         },
      },

      rows: {
         style: {
            background: "#FFF",
            color: "#565656",
            padding: "5px 0px",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };
   const columns = [
      {
         name: "SL",
         selector: (row, index) => index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: "Title",
         id: "title",
         selector: (row) => (
            <h2 className="whitespace-break-spaces text-center">
               {row.title}
            </h2>
         ),
         center: true,
         grow: 4,
      },
      {
         name: "Description",
         id: "description",
         selector: (row) => (
            <h2 className="whitespace-break-spaces text-center">
               {row.description}
            </h2>
         ),
         center: true,
         grow: 8,
      },
      {
         name: "Created At",
         id: "createdAt",
         selector: (row) => moment(row.createdAt).format("DD-MM-YYYY, hh:mm A"),
         center: true,
         grow: 5,
      },
      {
         name: "Send To",
         id: "sendTo",
         selector: (row) => (
            <div className="flex flex-col">
               <span>{row?.sendTo?.clubOwner && "Club Owners"}</span>
               <span>{row?.sendTo?.customers && "Customers"}</span>
            </div>
         ),
         center: true,
         grow: 3,
      },
      {
         name: "Image",
         id: "image",
         selector: (row) => (
            <img src={row.notificationImg} alt="Notification"
               className="h-[50px] w-[65px] bg-center rounded-sm" />
         ),
         center: true,
         grow: 4,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex items-center gap-[0.5rem]">
               {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_NOTIFICATIONS")) ?
                  <Link to={`edit/${row._id}`}>
                     <IconButton
                        className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
                     >
                        <Icon
                           icon="material-symbols:edit-rounded"
                           className="text-[1.5rem]"
                        />
                     </IconButton>
                  </Link>
                  :
                  "-"
               }

               {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("DELETE_NOTIFICATIONS")) ?
                  (loadingStates[row._id] ? (
                     <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#FB6108"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                     />
                  ) : (
                     <IconButton onClick={() => deleteNotificationHandler(row._id)}
                        className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
                     >
                        <Icon
                           icon="material-symbols:delete-rounded"
                           className="text-[1.5rem]"
                        />
                     </IconButton>
                  ))
                  :
                  "-"
               }
               <Link to={``}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white  text-black border border-[#D9D9D9] rounded-lg"
                  >
                     <Icon
                        icon="fluent:send-24-filled"
                        className="text-[1.5rem]"
                     />
                  </IconButton>
               </Link>
            </div>
         ),
         center: true,
         grow: 4,
      },
   ]

   const deleteNotificationHandler = async (id) => {
      // eslint-disable-next-line no-restricted-globals
      let isConfirm = confirm("Are you sure you want to Delete this Notification?");
      if (isConfirm) {
         const token = await getAccessToken();
         try {
            setLoadingStates((prevState) => ({
               ...prevState,
               [id]: true,
            }))
            const response = await fetch(`${APIurls.deleteNotification}/${id}`, {
               method: 'DELETE',
               headers: {
                  Authorization: `Bearer ${token}`,
               }
            });
            if (!response.ok) {
               toast.error("Failed to Delete Notification");
               const result = await response.json();
               throw new Error(result);
            }
            const result = await response.json();
            console.log("Notification Deleted Response", result);
            toast.success("Notification Deleted Successfully");
            refetch();
         } catch (error) {
            console.error(error.message);
         }
         setLoadingStates((prevState) => (
            {
               ...prevState,
               [id]: false,
            }))
      }
   }

   if (updateNotificationsList) {
      refetch();
      setUpdateNotificationsList(false);
   }

   const handleSelectedRows = ({ selectedRows }) => {
      if (notificationsData && selectedRows.length === notificationsData.length) {
         setSelectedRows(selectedRows)
      } else if (selectedRows.length > 0) {
         setSelectedRows(selectedRows.reverse())
      } else {
         setSelectedRows(selectedRows)
      }
      console.log("Selected Rows", selectedRows)
   }

   return (
      <div className='flex flex-col gap-[0.5rem]'>
         <div className='flex justify-between items-center border-t border-[#EAECF0] pt-[1.5rem]'>
            <div className='flex items-center gap-[3rem]'>
               <h2 className='text-black text-[1.3rem] font-semibold'>Manage Notifications</h2>
               <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
                  <input
                     type="text"
                     className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                     placeholder="Search by Title"
                  />
                  <Icon icon="ri:search-2-fill"
                     className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer" />
               </div>
            </div>
            <div className='flex items-center gap-[2rem]'>
               {/* <div className='flex items-center gap-[0.4rem] hover:text-primary cursor-pointer'>
                  <Icon
                     icon='material-symbols:delete'
                     className='text-[1.5rem]'
                  />
                  <span className='text-[1rem] font-medium'>Remove</span>
               </div> */}
               {/* <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
                  <p className='text-[1rem] font-medium'>Change Status</p>
                  <Icon
                     icon='mingcute:down-line'
                     className='text-[1.5rem]'
                  />
               </div> */}
            </div>
         </div>
         <div>
            <DataTable
               columns={columns}
               customStyles={customStyles}
               data={notificationsData}
               pagination
               selectableRows
               paginationPerPage={10}
               progressPending={isLoading}
               selectableRowsComponent={CustomCheckbox}
               onSelectedRowsChange={handleSelectedRows}
            />
         </div>
         <ToastContainer />
      </div>
   )
}


