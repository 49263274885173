import React, { useEffect, useState } from 'react'
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useQuery } from 'react-query'
import { useAuth } from "../../../store/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { APIurls } from "../../../api/apiConstant";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import CreateMainCategory from './CreateMainCategory';
import {
  IconButton,
  Option,
  Select,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import EditMainCategory from './EditMainCategory';
import CircleLoader from 'react-spinners/CircleLoader';
import { RxCross2 } from "react-icons/rx";
import DownloadExcel from '../../../exportData/ExportExcel'
import pdfLogo from '../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../../exportData/ExportPDF'

export default function MainCategoryList() {

  const { getAccessToken, userRole } = useAuth();

  const [categoryData, setCategoryData] = useState([]);
  const [isRefetch, setIsRefetch] = useState(false);
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState("");
  const [addCategory, setAddCategory] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [status, setStatus] = useState("")
  const [statusdata, setStatusData] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [filteredData, setFilteredData] = useState([]);
  const [base64Logo, setBase64Logo] = useState("");
  const [loading, setLoading] = useState(false);
  const [adminName, setAdminName] = useState("")

  const showAddCategoryPopUp = () => {
    setAddCategory((prev) => !prev)
  }

  const popUpScreenState = (show) => {
    setAddCategory(show)
  }

  const showEditCategoryHandler = (id) => {
    setShowEditCategory(true);
    setEditCategoryId(id);
    console.log("CATEGORY ID", id);
  }

  const getCategories = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchCategories}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    console.log("MAIN CATEGORIES", result?.response?.CatArray?.reverse())
    return result;
  }

  const { data, isLoading, error, isSuccess, refetch } = useQuery('fetchCategories', getCategories)

  if (isRefetch) {
    refetch();  // when component get mounted after category is created.
    setIsRefetch(false);  // refetch() only once.
  }
  useEffect(() => {
    if (isSuccess) {
      const categoryDataToShow = data?.response?.CatArray?.map((item, index) => {
        return { mainCategory: item.categoryName, createdDate: item.createdAt, status: item.isActive, categoryId: item.categoryId }
      })
      setCategoryData(categoryDataToShow.reverse());
      setStatusData(categoryDataToShow.reverse())
    }
  }, [data])

  const fetchAdminDetails = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      setAdminName(result?.response?.name)
    } catch (error) {
      console.log("Error in fetching admin details", error);
    }
  }

  useEffect(() => {
    fetchAdminDetails();
  }, [])

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        // padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //    style: {
    //       boxShadow: "10px 5px 5px #ddd",
    //       marginBottom: "5px",
    //    },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: 'Main Category',
      selector: row => row?.mainCategory,
      center: true,
      grow: 1,
    },
    {
      name: 'Created Date & Time',
      selector: (row) => moment(row.createdDate).format("DD-MM-YYYY, hh:mm A"),
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[7rem] ${row?.status === true ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row?.status === true ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row?.status === true ? "Active" : "Inactive"}</h2>
        </div>
      ),
      center: true,
      grow: 1,
      sortable: true,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex items-center gap-2">

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_CATEGORIES")) &&
            <IconButton onClick={() => showEditCategoryHandler(row.categoryId)}
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
            >
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem] text-black"
              />
            </IconButton>
          }
          {/* <Link to={"edit/:id"}>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                  >
                     <Icon
                        icon="material-symbols:delete-rounded"
                        className="text-[1.5rem] text-black"
                     />
                  </IconButton>
               </Link> */}
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  const handleSelectedRows = ({ selectedRows }) => {
    if (categoryData && selectedRows?.length === categoryData?.length) {
      setSelectedRows(selectedRows)
    } else if (selectedRows?.length > 0) {
      setSelectedRows(selectedRows.reverse())
    } else {
      setSelectedRows(selectedRows)
    }
    console.log("selectedRows", selectedRows)
  }

  const handleSelectStatus = (value) => {
    setStatus(value)
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  useEffect(() => {
    let mapData = categoryData?.map((item) => {
      return { ...item };
    });
    if (searchInput !== "") {
      mapData = mapData.filter((item) => {
        return item?.mainCategory?.toLowerCase().includes(searchInput.toLowerCase());
      });
    }
    if (status !== "") {
      if (status === "clear_all") {
        setFilteredData(mapData);
      }
      else if (status === "active") {
        mapData = mapData?.filter((item) => item?.status === true);
      } else if (status === "inactive") {
        mapData = mapData?.filter((item) => item?.status === false)
      }
    }
    setFilteredData(mapData);
  }, [categoryData, searchInput, status]);

  const convertImageToBase64 = async (imagePath) => {
    try {
      const response = await fetch(imagePath);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to base64:', error);
      throw error;
    }
  };

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const base64String = await convertImageToBase64(gamebeezLogo);
      setBase64Logo(base64String);

      const pdfData = categoryData?.map((item, index) =>
        [
          index + 1,
          item?.mainCategory,
          moment(item?.createdDate).format("DD-MM-YYYY, hh:mm A"),
          item?.status === true ? "Active" : "Inactive",
        ]
      )

      const pdfColumns = ["SL", 'Main Category', 'Created Date & Time', "Status"]

      await DownloadPDF(pdfData, pdfColumns, "Main Categories.pdf", base64String, adminName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const excelData = categoryData?.map((item, index) => [
    index + 1,
    item?.mainCategory,
    moment(item?.createdDate).format("DD-MM-YYYY, hh:mm A"),
    item?.status === true ? "Active" : "Inactive",
  ])

  const excelColumns = ["SL", 'Main Category', 'Created Date & Time', "Status"]


  return (
    <div className='flex flex-col gap-[0.5rem]'>
      <div className='flex justify-between items-center px-[1rem]'>
        <h2 className='text-black text-[1.3rem] font-semibold'>Manage Categories</h2>
        <div className='flex items-center gap-[2rem]'>
          <div className='flex gap-3 items-center rounded-md'>
            {loading ? (
              <p className="text-xs">Generating PDF...</p>
            ) : (
              <img
                src={pdfLogo}
                className='w-[25px] cursor-pointer'
                onClick={handleDownloadPDF}
                alt="Generate PDF"
              />
            )}
            <FaFileCsv
              className='w-[25px] h-[30px] text-green-800 cursor-pointer'
              onClick={() => DownloadExcel({ excelData, excelColumns })}
            />
          </div>
          <div className="flex py-[0.5rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
            <input
              type="text"
              className="w-[15rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
              placeholder="Search by Category Name"
              value={searchInput}
              onChange={handleSearch}
            />
            {searchInput !== "" ?
              <RxCross2
                className='text-[1.5rem] bg-primary text-white rounded-md cursor-pointer'
                onClick={() => setSearchInput("")}
              />
              :
              <Icon
                icon="ri:search-2-fill"
                className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
              />
            }
          </div>
          <div className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
            <Select label='Change Status'
              value={status}
              onChange={(value) => handleSelectStatus(value)}
            >
              <Option value='active' disabled={status === "active"}>Active</Option>
              <Option value='inactive' disabled={status === "inactive"}>Inactive</Option>
              <Option value='clear_all' disabled={status === "clear_all"}>Clear All</Option>
            </Select>
          </div>
          {/* Change Status Menu */}
          {/* <div>
                  <Menu>
                     <MenuHandler>
                        <button className='flex items-center gap-[0.3rem] hover:text-[#FB6108] cursor-pointer'>
                           <span className='text-[1rem] font-medium'>Change Status</span>
                           <Icon
                              icon='mingcute:down-line'
                              className='text-[1.5rem]'
                           />
                        </button>
                     </MenuHandler>
                     <MenuList>
                        <MenuItem>
                           <h2>
                              ACTIVE
                           </h2>
                        </MenuItem>
                        <hr className="my-[0.1rem]" />
                        <MenuItem>
                           <h2>
                              INACTIVE
                           </h2>
                        </MenuItem>
                     </MenuList>
                  </Menu>
               </div> */}
          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("CREATE_CATEGORIES")) &&
            <div>
              <button onClick={showAddCategoryPopUp}
                className="text-[0.8rem] sm:text-[1rem] font-medium flex items-center justify-center max-xs:w-full gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                <span className="whitespace-nowrap">Create Category</span>
                <Icon
                  icon="material-symbols:add"
                  className="text-[1.2rem] sm:text-[1.5rem]"
                />
              </button>
            </div>
          }
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={filteredData}
          pagination
          selectableRows
          paginationPerPage={10}
          progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
          onSelectedRowsChange={handleSelectedRows}
        />
      </div>
      {addCategory ? <CreateMainCategory setPopUpScreenVisibility={popUpScreenState} setIsRefetch={setIsRefetch} /> : ""}
      {showEditCategory && <EditMainCategory setShowEditCategory={setShowEditCategory} editCategoryId={editCategoryId} setIsRefetch={setIsRefetch} />}
      <ToastContainer />
    </div>
  )
}


