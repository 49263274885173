import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";
import { APIurls } from "../../../../api/apiConstant";
import { useAuth } from "../../../../store/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import TournamentDeleteModal from "../../Tournaments/TournamentDeleteModal";
import { PiLessThanBold } from "react-icons/pi";

export default function ViewCOTournaments() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tournamentId, setTournamentId] = useState("");

  const navigate = useNavigate();
  const { getAccessToken } = useAuth();

  const getClubTournamentsByClubID = async () => {
    try {
      // setIsLoading(true);
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.viewClubTournaments}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }
      console.log("View Club Owner Tournaments Data", result);
      // setIsLoading(false);
      const allTournaments = result?.response?.tournaments.sort(
        (a, b) => b?.createdAt - a?.createdAt
      );
      setTournaments(allTournaments);
      return result;
    } catch (error) {
      console.log(
        "Error In fetching Specific Club Tournaments : " + error?.message
      );
      // setIsLoading(false);
      toast.error(error?.message);
    }
  };

  const handleDeleteTournamentModal = (id) => {
    setTournamentId(id);
    setDeleteModal(true);
  };
  useEffect(() => {
    (async () => {
      await getClubTournamentsByClubID();
    })();
  }, []);

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Tournament ID",
      selector: (row) => <div>{row?.tournamentID}</div>,
      center: true,
      grow: 2.5,
    },
    {
      name: "Title",
      selector: (row) => <div>{row?.title}</div>,
      center: true,
      grow: 2.5,
    },
    {
      name: "No.of Venues",
      selector: (row) => <div>{row?.venue}</div>,
      center: true,
      grow: 2.5,
    },
    {
      name: "Category",
      selector: (row) => (
        <div>
          <p>{row?.category?.mainCategory}</p>
          <p>{row?.category?.subCategory}</p>
          <p>{row?.category?.sportName}</p>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Type of Game",
      selector: (row) => <div>{row?.gameType}</div>,
      center: true,
      grow: 2.5,
    },
    {
      name: "Format",
      selector: (row) => <div>{row?.tournamentType}</div>,
      center: true,
      grow: 2,
    },
    {
      name: "Players",
      selector: (row) => <p>{row?.participants}</p>,
      center: true,
    },
    {
      name: "Duration",
      selector: (row) => (
        <div>
          <p>
            {row?.duration?.start === null
              ? "-"
              : moment(row?.duration?.start).format("MMM Do YY")}
          </p>
          <p>
            {row?.duration?.end === null
              ? "-"
              : moment(row?.duration?.end).format("MMM Do YY")}
          </p>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[7rem]  ${row?.tournamentStatus === "ACTIVE"
              ? "text-[#25BF17] bg-[#D1F0CF]"
              : row?.tournamentStatus === "PENDING"
                ? "text-[#FFCE06] bg-[#F1ECE3]"
                : "text-[#FF0023] bg-[#FDCAD1]"
            }`}
        >
          <span
            className={`${row?.tournamentStatus === "ACTIVE"
              ? "bg-[#25BF17]"
              : row?.tournamentStatus === "PENDING"
                ? "bg-[#FFCE06]"
                : "bg-[#FF0023]"
              } h-[0.5rem] w-[0.5rem] rounded-full`}
          ></span>
          <h2>{row?.tournamentStatus}</h2>
        </div>
      ),
      grow: 3,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-[0.3rem]">
          <NavLink to={`/tournaments/tournamentDetails/${row?.tournamentID}`}>
            <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer">
              <Icon icon="mdi:eye" className="text-[1.5rem] text-black" />
            </div>
          </NavLink>
          <NavLink
            to={`/tournaments/tournamentDetails/${row?.tournamentID}/editOverViewTournament`}
          >
            <div className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer">
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem] text-black"
              />
            </div>
          </NavLink>
          {true && (
            <div
              className="border-2 border-[#EAECF0] p-1 rounded-md cursor-pointer"
              onClick={() => handleDeleteTournamentModal(row?.tournamentID)}
            >
              <Icon
                icon="material-symbols:delete-rounded"
                className="text-[1.5rem] text-black"
              />
            </div>
          )}
        </div>
      ),
      center: true,
      grow: 3,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        // fontSize: "15px",
      },
    },
    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "5px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    // pagination: {
    //   style: {
    //     boxShadow: "10px 5px 5px #ddd",
    //     marginBottom: "5px",
    //   },
    // },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };

  return (
    <div className="bg-white rounded-md flex flex-col gap-[0.5rem]">
      <div className="flex items-center justify-between">
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={() => navigate('/tournaments')}
        >
          <PiLessThanBold className="text-[16px] font-semibold text-[#808080]" />
          <h2 className="text-lg font-semibold text-[#808080]">
            Tournaments{""}
          </h2>
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={tournaments}
          pagination
          selectableRows
          // progressPending={}
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <ToastContainer />
      {deleteModal && (
        <TournamentDeleteModal
          deleteModal={setDeleteModal}
          tournamentId={tournamentId}
          refetch={getClubTournamentsByClubID}
        />
      )}
    </div>
  );
}
