import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { IconButton, Option, Select } from '@material-tailwind/react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../../helpers/CustomCheckbox';
import { APIurls } from '../../../../api/apiConstant';
import { useAuth } from '../../../../store/AuthContext';
import { useQuery } from 'react-query';
import { ToastContainer } from 'react-toastify';
import DownloadExcel from '../../../../exportData/ExportExcel'
import pdfLogo from '../../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../../../exportData/ExportPDF'
import { RxCross2 } from 'react-icons/rx';

export default function BookingsList() {

    const [totalClubs, setTotalClubs] = useState();
    const [searchKey, setSearchKey] = useState("")
    const [bookingType, setBookingType] = useState("")
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [adminName, setAdminName] = useState("");
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [bookingData, setBookingData] = useState([]);

    const { customerId } = useParams();
    const { getAccessToken } = useAuth();

    const fetchSpecificCustomerBookingData = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchSpecificCustomerBookingDataById}/${customerId}?page=${currentPage}&limit=${recordsPerPage}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const result = await response.json();
                throw new Error(result.message);
            }
            const result = await response.json();
            console.log("bookingData", result?.response)
            setBookingData(result?.response);
            setIsLoading(false);
            return result?.response;
        } catch (error) {
            console.log("Error while fetching specific customer bookings", error);
        }
    }

    useEffect(() => {
        console.log("Inside Use Effect")
        fetchSpecificCustomerBookingData();
    }, [currentPage, recordsPerPage])

    const fetchAdminDetails = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            setAdminName(result?.response?.name)
        } catch (error) {
            console.log("Error in fetching admin details", error);
        }
    }

    useEffect(() => {
        fetchAdminDetails();
    }, [])

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                // padding: "10px 10px",
                color: "#4D4D4D",
                fontSize: "13px",
            },
        },
        head: {
            style: {
                // borderRadius: "6px 6px 0px 0px",
                // borderTop: "0.824px solid #E9E9E9",
                background: "#FFF",
                // fontSize: "15px",
            },
        },

        rows: {
            style: {
                // borderRadius: "6px 6px 0px 0px",
                // borderTop: "0.824px solid #E9E9E9",
                background: "#FFF",
                color: "#00000",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 600,
                width: "auto",
            },
        },

        table: {
            style: {
                overflow: "visible",
                maxWidth: "1200px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto", // table scroll on x axis
            },
        },
    };

    const columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            // center: true,
            width: "5%"
        },
        {
            name: "Booking ID",
            selector: row => <div>
                {row.BookingId}
            </div>,
            center: true,
            grow: 2,
        },
        {
            name: 'Date of Booking',
            id: "dob",
            selector: row => (
                <div>{moment(row.BookingDate).format("MMM Do YY")}</div>
            ),
            grow: 1.5,
            center: true
        },

        {
            name: "Time Slot",
            id: "time_slot",
            selector: (row, i) => {
                const newDate = new Date(row?.BookingTime[0]?.endTime);
                return (
                    <div>
                        <p>Date : {moment(row.selectedDate).format("MMM Do YY")}</p>
                        <p>Time:{moment(newDate).format("LT")}</p>
                    </div>
                );
            },
            center: true,
            grow: 2,
        },
        {
            name: 'Sports',
            id: "sports",
            selector: row => (
                <div>
                    {row?.sport}
                </div>
            ),
            grow: 1.5,
            center: true,
        },
        {
            name: 'Total Amount',
            id: "total_bookings",
            selector: row => (
                <div>
                    {Math.round(row?.totalBookingAmount) || "-"}
                </div>
            ),
            grow: 1.4,
            center: true,
        },
        {
            name: "Booking Type",
            id: "BookingType",
            selector: row => (
                <div>
                    {row?.BookingType}
                </div>
            ),
            grow: 1.5,
            center: true
        },
        {
            name: "Status",
            id: "status",
            selector: (row) => (
                <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
            py-[0.4rem] w-[7rem] ${row.status === "PAID" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
                    <h2>{row.status === "PAID" ? "PAID" : "PENDING"}</h2>
                </div>
            ),
            center: true,
            grow: 1.7,
            sortable: true,
        },
        {
            name: "Actions",
            id: "action",
            cell: (row) => (
                <div className="flex gap-[0.5rem]">

                    <NavLink to={`/customers/view/${customerId}/booking/${row.BookingId}`}>
                        <IconButton
                            className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                        >
                            <Icon icon="mdi:eye"
                                className="text-[1.5rem] text-black"
                            />
                        </IconButton>
                    </NavLink>
                </div>
            ),
            center: true,
            grow: 1,
        },

    ];

    const handleInputChange = (event) => {
        setSearchKey(event.target.value)
    }

    const handleBookingChange = (value) => {
        setBookingType(value)
    }

    useEffect(() => {
        let mapData = bookingData?.bookings?.map((item) => {
            return { ...item };
        });
        if (searchKey !== "") {
            mapData = mapData?.filter((item) => {
                return item?.BookingId?.toLowerCase().includes(searchKey.toLowerCase()) || item?.sport?.toLowerCase().includes(searchKey.toLowerCase());
            });
        }
        if (bookingType !== "") {
            mapData = mapData?.filter((item) => item?.BookingType === bookingType);
        }
        setFilteredData(mapData);
    }, [searchKey, bookingType, bookingData]);

    useEffect(() => {
        if (bookingType === "Clear All") {
            setFilteredData(bookingData?.bookings)
        }
    }, [bookingType])

    const convertImageToBase64 = async (imagePath) => {
        try {
            const response = await fetch(imagePath);
            const blob = await response.blob();

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error converting image to base64:', error);
            throw error;
        }
    };

    const handleDownloadPDF = async () => {
        setLoading(true);
        try {
            const base64String = await convertImageToBase64(gamebeezLogo);

            const pdfData = bookingData?.bookings?.map((item, index) =>
                [
                    index + 1,
                    item?.BookingId,
                    moment(item?.BookingDate).format("MMM Do YY"),
                    moment(item?.selectedDate).format("MMM Do YY"),
                    moment(new Date(item?.BookingTime[0]?.endTime)).format("LT"),
                    item?.sport,
                    item?.totalBookingAmount,
                    item?.BookingType,
                    item?.status
                ]
            )

            const pdfColumns = ["SL", 'Booking ID', 'Date of Booking', 'Date', 'Time', 'Sports', 'Total Amount', 'Booking Type', 'Status']

            await DownloadPDF(pdfData, pdfColumns, "Specific Customer Bookings.pdf", base64String, adminName);
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setLoading(false);
        }
    };

    const excelData = bookingData?.bookings?.map((item, index) => [
        index + 1,
        item?.BookingId,
        moment(item?.BookingDate).format("MMM Do YY"),
        moment(item?.selectedDate).format("MMM Do YY"),
        moment(new Date(item?.BookingTime[0]?.endTime)).format("LT"),
        item?.sport,
        item?.totalBookingAmount,
        item?.BookingType,
        item?.status
    ])

    const excelColumns = ["SL", 'Booking ID', 'Date of Booking', 'Date', 'Time', 'Sports', 'Total Amount', 'Booking Type', 'Status']

    const handleDirection = (direction) => {
        if (direction === "right") {
            const currPg = currentPage + 1;
            if (currentPage === bookingData?.totalPages) {
                setCurrentPage(bookingData?.totalPages);
            } else {
                setCurrentPage(currPg);
            }
        } else if (direction === "left") {
            const currPg = currentPage - 1;
            if (currentPage === 1) {
                setCurrentPage(1);
            } else {
                setCurrentPage(currPg);
            }
        }
    };

    return (
        <>
            <div className='flex flex-col gap-[0.5rem] mx-6 '>
                <div className='w-[96%] flex items-center justify-between'>
                    <div className='flex items-center gap-[16rem]'>
                        <h2 className='text-black text-[16px] font-[500] whitespace-nowrap'>Bookings</h2>
                    </div>
                    <div className='flex items-center gap-[1rem]'>
                        <div className="flex p-[6px] gap-[10px] font-bold bg-[#FFF]  border-[#DFDFDF] border-[1px] rounded-[4px]">
                            <Icon icon="circum:search" className=" text-3xl  text-black text-[1.5rem] rounded-[5px] cursor-pointer" />
                            <input
                                type="text"
                                className="w-[200px] h-[24px]  placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
                                placeholder="Search by Booking Id or Sport"
                                onChange={handleInputChange}
                            />
                            {searchKey !== "" &&
                                <RxCross2
                                    className='text-[1.3rem] bg-primary text-white rounded-md cursor-pointer'
                                    onClick={() => setSearchKey("")}
                                />}
                        </div>
                        <div className='flex items-center p-[8px]'>
                            <Select label="Select Booking Type"
                                value={bookingType}
                                onChange={(value) => handleBookingChange(value)}
                            >
                                <Option value="Online" disabled={bookingType === "Online"}>Online</Option>
                                <Option value="OFFLINE" disabled={bookingType === "OFFLINE"}>Offline</Option>
                                <Option value='Clear All' disabled={bookingType === "Clear All"}>Clear All</Option>
                            </Select>
                        </div>
                        <div className='flex gap-3 items-center rounded-md'>
                            {loading ? (
                                <p className="text-xs">Generating PDF...</p>
                            ) : (
                                <img
                                    src={pdfLogo}
                                    className='w-[25px] cursor-pointer'
                                    onClick={handleDownloadPDF}
                                    alt="Generate PDF"
                                />
                            )}
                            <FaFileCsv
                                className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                                onClick={() => DownloadExcel({ excelData, excelColumns })}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {isLoading ? <p className="text-xl font-semibold text-center">Loading...</p>
                        :
                        <DataTable
                            columns={columns}
                            customStyles={customStyles}
                            data={filteredData}
                        />
                    }
                    {bookingData?.totalBookings > 0 &&
                        <div className="flex justify-center items-center gap-2 py-2">
                            <button
                                className={`${currentPage === 1 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                                onClick={() => handleDirection("left")}
                            >Prev</button>
                            <span>
                                {currentPage} / {Math.ceil(bookingData?.totalBookings / recordsPerPage)}
                            </span>
                            <button
                                className={`${currentPage === bookingData?.totalPages ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                                onClick={() => handleDirection("right")}
                            >Next</button>
                            <div className="flex items-center">
                                <span>Per Page:</span>
                                <select
                                    value={recordsPerPage}
                                    onChange={(e) => { setRecordsPerPage(+e.target.value); setCurrentPage(1); }}
                                    className="border outline-none ml-2"
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={30}>30</option>
                                    <option value={bookingData?.totalBookings}>{bookingData?.totalBookings}</option>
                                </select>
                            </div>
                        </div>
                    }
                </div>
                <div className=" h-[48px] bg-white border-b border-gray-200" />
                <ToastContainer />
            </div>
        </>
    )
}

