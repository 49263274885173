import React, { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { PiGreaterThan } from "react-icons/pi";
import { LuPlus } from "react-icons/lu";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import trophy from "../../../../assets/winnerImg.jpg";
import { RiDeleteBinFill } from "react-icons/ri";
import ChangesSavePopupModel from "./OnChangeScoreModel";

export default function AddScoreGameFixturesModal({
  setAddScoreModal,
  matchID,
  status,
  setIsRefetch,
  tournamentType,
}) {
  const { getAccessToken } = useAuth();
  const [showError, setShowError] = useState(false);
  const [winner, setWinner] = useState("");
  const [announceWinner, setAnnounceWinner] = useState(false);
  const [noOfSets, setNoOfSets] = useState(1);
  const [participantsScores, setParticipantsScores] = useState([{}]);
  const [winsA, setWinsA] = useState(0);
  const [winsB, setWinsB] = useState(0);
  const [isSavingScores, setIsSavingScores] = useState(false);
  const [winnerPlaceholder, setWinnerPlaceholder] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [cricketWinnerStatus, setCricketWinnerStatus] = useState("");
  const [cricketWinner, setCricketWinner] = useState("");
  const [cricketResultObj, setCricketResultOb] = useState({
    winByNumber: 0,
    winByName: "",
  });
  const [footballResultObj, setFootballResultObj] = useState({
    winTeam: "",
    choice: "",
  });
  const [changesSaveModel, setChangesSaveModel] = useState(false);
  const [onSaveModel, setOnSaveModel] = useState(false);
  const [footballTime, setFootballTime] = useState(0);

  const fetchMatchDetails = async () => {
    const token = await getAccessToken();
    const response = await fetch(
      `${APIurls.fetchMatchDetailsById}/${matchID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    setWinner(result?.response?.matchDetails?.winnerID);
    if (
      result?.response?.matchDetails?.teamA === null &&
      result?.response?.matchDetails?.teamB === null
    ) {
      setAddScoreModal(false);
      toast.error("Participants are not assigned in this match");
    }
    const matchDetails = result?.response?.matchDetails;
    console.log("MATCH_DETAILS : ", matchDetails);
    // console.log("MATCH_DETAILS  sets: ", matchDetails?.noOfSets);
    const winChoice =
      matchDetails?.tossWinnerChoice === "Choice of Side"
        ? "Choice Of Court Side"
        : matchDetails?.tossWinnerChoice;
    matchDetails.tossWinnerChoice = winChoice;
    setFootballResultObj({
      winTeam: matchDetails?.tossWinnerName,
      choice: winChoice,
    });
    if (matchDetails?.scoreType === "Football") {
      if (matchDetails?.scoreA[0] > matchDetails?.scoreB[0]) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (matchDetails?.scoreA[0] < matchDetails?.scoreB[0]) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (matchDetails?.scoreA[0] === matchDetails?.scoreB) {
        setWinnerPlaceholder("");
      }
      setFootballTime(matchDetails?.matchDuration);
      let footballScores = [...participantsScores];
      footballScores[0] = {
        valueA: matchDetails?.scoreA[0],
        valueB: matchDetails?.scoreB[0],
        nameA: matchDetails?.teamA,
        nameB: matchDetails?.teamB,
        keyA: "pointsA",
        keyB: "pointsB",
      };
      let footballStates = [
        "fouls",
        "free_hits",
        "penalty_hits",
        "yellow_cards",
        "red_cards",
      ];
      matchDetails?.FootballA?.map((value, index) => {
        const foulName = footballStates[index]
          ?.split("_")
          ?.map((value) => value[0]?.toUpperCase() + value.slice(1))
          .join(" ");
        footballScores[index + 1] = {
          valueA: value,
          valueB: matchDetails?.FootballB[index],
          nameA: foulName,
          nameB: foulName,
          keyA: footballStates[index] + "A",
          keyB: footballStates[index] + "B",
        };
        return index;
      });
      console.log("Inside the foot court : ", footballScores);
      setParticipantsScores(footballScores);
      return matchDetails;
    }
    setNoOfSets(matchDetails?.noOfSets);
    const obj = {};
    obj.winByNumber = matchDetails?.sportStatusNumber;
    obj.winByName = matchDetails?.sportStatusName;
    setCricketResultOb(obj);
    setCricketWinnerStatus(matchDetails?.sportResult);
    // setWinsA(matchDetails?.winsA)
    // setWinsB(matchDetails?.winsB)
    const scoreA = matchDetails?.scoreA;
    const scoreB = matchDetails?.scoreB;
    const scoreAB = scoreA?.map((value, index) => {
      let setWinner =
        value > scoreB[index] ? matchDetails?.teamA : matchDetails?.teamB;
      if (value === scoreB[index]) {
        setWinner = "Draw";
      }
      return { pointsA: value, pointsB: scoreB[index], winner: setWinner };
    });
    console.log("scoreAB : ", scoreAB);
    console.log("lets test it");
    let cricketScore = [];

    cricketScore = matchDetails?.scoreA?.map((value, index) => {
      if (index === 0) {
        const winTeam =
          value > scoreB[index]
            ? matchDetails?.teamA
            : value < scoreB[index]
              ? matchDetails?.teamB
              : "";
        setCricketWinner(winTeam);
      }
      return { pointsA: value, pointsB: scoreB[index] };
    });
    console.log("cricket data 1 : ", cricketScore);
    if (matchDetails?.wicketsA.length === 0) {
      cricketScore = cricketScore?.map((value) => {
        // if(value?.pointsA > value?.pointsB){
        //     setCricketWinner(matchDetails?.teamA);
        // }
        // if(value?.pointsB > value?.pointsA){
        //     setCricketWinner(matchDetails?.teamB);
        // }
        return {
          pointsA: value?.pointsA,
          pointsB: value?.pointsB,
          wicketsA: 0,
          wicketsB: 0,
          oversA: 0,
          oversB: 0,
          totalOversA: 0,
          totalOversB: 0,
        };
      });
    } else {
      matchDetails?.wicketsA?.map((value, index) => {
        if (!cricketScore[index]) {
          cricketScore[index] = { pointsA: 0, pointsB: 0 };
        }
        cricketScore[index].wicketsA = value;
        cricketScore[index].wicketsB = matchDetails?.wicketsB[index];
        cricketScore[index].oversA = matchDetails?.oversA[index];
        cricketScore[index].oversB = matchDetails?.oversB[index];
        cricketScore[index].totalOversA = matchDetails?.totalOvers[index];
        cricketScore[index].totalOversB = matchDetails?.totalOvers[index];
        return value;
      });
    }
    // matchDetails?.wicketsA?.map((value))
    console.log("after score added for cricket : ", cricketScore);
    if (matchDetails?.scoreType === "Best Of") {
      const bestOfWins = Math.round(matchDetails?.noOfSets / 2);
      if (scoreAB.length >= matchDetails?.noOfSets) {
        console.log("best of equal :", bestOfWins);
        if (matchDetails?.winsA >= bestOfWins) {
          console.log("WINNER :", matchDetails?.teamA);
          setWinnerPlaceholder(matchDetails?.teamA);
        }
        if (matchDetails?.winsB >= bestOfWins) {
          console.log("WINNER :", matchDetails?.teamA);
          setWinnerPlaceholder(matchDetails?.teamB);
        }
        if (
          matchDetails?.winsA < bestOfWins &&
          matchDetails?.winsB < bestOfWins
        ) {
          setWinnerPlaceholder("");
        }
      }
      if (
        matchDetails?.winsA < bestOfWins &&
        matchDetails?.winsB < bestOfWins
      ) {
        console.log(
          "not equal : ",
          bestOfWins,
          matchDetails?.winsA,
          matchDetails?.winsB
        );
        setWinnerPlaceholder("");
      }
    }
    if (matchDetails?.scoreType === "Race To") {
      if (matchDetails?.winsA === matchDetails?.noOfSets) {
        setWinnerPlaceholder(matchDetails?.teamA);
      }
      if (matchDetails?.winsB === matchDetails?.noOfSets) {
        setWinnerPlaceholder(matchDetails?.teamB);
      }
      if (
        matchDetails?.winsA !== matchDetails?.noOfSets &&
        matchDetails?.winsB !== matchDetails?.noOfSets
      ) {
        setWinnerPlaceholder("");
      }
    }
    if (matchDetails?.scoreType === "Top Score") {
      const participantA_TopScore = scoreAB.reduce(
        (acc, setObj) => (acc += setObj?.pointsA),
        0
      );
      const participantB_TopScore = scoreAB.reduce(
        (acc, setObj) => (acc += setObj?.pointsB),
        0
      );
      console.log("teamA :", participantA_TopScore);
      console.log("teamB :", participantB_TopScore);
      if (participantA_TopScore > participantB_TopScore) {
        setWinnerPlaceholder(matchDetails?.teamA);
      }
      if (participantA_TopScore < participantB_TopScore) {
        setWinnerPlaceholder(matchDetails?.teamB);
      }
      if (participantA_TopScore === participantB_TopScore) {
        setWinnerPlaceholder("");
      }
      setWinsA(participantA_TopScore);
      setWinsB(participantB_TopScore);
    }
    if (matchDetails?.scoreType !== "Top Score") {
      setWinsA(matchDetails?.winsA);
      setWinsB(matchDetails?.winsB);
    }
    if (matchDetails?.scoreType === "Cricket") {
      if (matchDetails?.sportName?.toLowerCase().includes("cricket")) {
        console.log("cricket score : ", cricketScore);
        if (cricketScore[0]["pointsA"] < cricketScore[0]["pointsB"]) {
          setWinnerPlaceholder(matchDetails?.teamB);
        }
        if (cricketScore[0]["pointsA"] > cricketScore[0]["pointsB"]) {
          setWinnerPlaceholder(matchDetails?.teamA);
        }
        if (cricketScore[0]["pointsA"] === cricketScore[0]["pointsB"]) {
          setWinnerPlaceholder("");
        }
        setParticipantsScores(cricketScore);
      }
    } else {
      setParticipantsScores(scoreAB);
    }
    return result?.response?.matchDetails;
  };

  const { data: matchDetailsData, refetch } = useQuery(
    "fetchMatchData",
    fetchMatchDetails
  );

  const handleSaveSetNumber = async () => {
    try {
      setIsSaving(true);
      const token = await getAccessToken();
      console.log('"token : ', token);
      console.log('"matchID : ', matchDetailsData?.id);
      const response = await fetch(
        `${APIurls.updateTournamentMatchSetsNumber}/${matchDetailsData?.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ numberOfSets: noOfSets }),
        }
      );

      const result = await response.json();
      console.log("on save number");
      if (result?.code > 200) {
        console.log("noOfSets not updated ", result?.message);
        toast.error(result?.message);
        // setAddScoreModal(false);
        return;
      }
      console.log("noOfSets Updated Successfully", result);
      toast.success("Number Of Sets Updated Successfully");
      setIsSaving(false);
      // setIsRefetch(true);
      // setAddScoreModal(false);
      setChangesSaveModel(false);
      refetch();
      return;
    } catch (error) {
      setIsSaving(false);
      toast.error(error?.message);
      console.log("Error in saving set Number" + error?.message);
    }
  };
  const handleAnnounceWinner = async (winner) => {
    if (!winner) {
      setShowError(true);
      return;
    }
    try {
      setAnnounceWinner(true);
      const token = await getAccessToken();
      const url =
        tournamentType === "Knockout"
          ? APIurls.updateWinnerById
          : tournamentType === "Double Elimination Bracket"
            ? APIurls.updateWinnerForDoubleKnockoutById
            : APIurls.updateWinnerForRoundRobbinById;
      const response = await fetch(`${url}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ matchID: matchID, winnerID: winner }),
      });
      const result = await response.json();
      if (result?.code > 201) {
        console.log("Winner not updated ", result?.message);
        toast.error(result?.message);
        setAddScoreModal(false);
        return;
      }
      console.log("Winner Updated Successfully", result);
      toast.success("Winner Updated Successfully");
      setAnnounceWinner(false);
      setIsRefetch(true);
      setAddScoreModal(false);
      return;
    } catch (error) {
      setAnnounceWinner(false);
      console.log("Error in Updating Winner", error);
    }
  };

  const handleAddSetScore = () => {
    const obj = {
      pointsA: 0,
      pointsB: 0,
      winner: "",
    };
    console.log("before adding length : ", participantsScores.length);
    setParticipantsScores((prevScore) => [...prevScore, obj]);
    setChangesSaveModel(true);
  };

  const handleScoreInput = (e, i, side) => {
    console.log("Input for--->", i, e.target.value);
    const updatedScore = [...participantsScores];
    if (side === "A") {
      updatedScore[i].pointsA = parseInt(e.target.value);
    } else if (side === "B") {
      updatedScore[i].pointsB = parseInt(e.target.value);
    }
    if (updatedScore[i]?.pointsA > updatedScore[i]?.pointsB) {
      updatedScore[i].winner = matchDetailsData?.teamA;
    } else {
      updatedScore[i].winner = matchDetailsData?.teamB;
    }
    if (updatedScore[i]?.pointsA === updatedScore[i]?.pointsB) {
      updatedScore[i].winner = "Draw";
    }
    const AWins = updatedScore?.reduce((acc, obj) => {
      if (obj.winner === matchDetailsData?.teamA) {
        acc++;
      }
      return acc;
    }, 0);
    const BWins = updatedScore?.reduce((acc, obj) => {
      if (obj.winner === matchDetailsData?.teamB) {
        acc++;
      }
      return acc;
    }, 0);
    if (matchDetailsData?.scoreType === "Best Of") {
      const bestOfWins = Math.round(matchDetailsData?.noOfSets / 2);
      if (updatedScore.length >= matchDetailsData?.noOfSets) {
        console.log("best of equal :", bestOfWins);
        if (AWins >= bestOfWins) {
          console.log("WINNER :", matchDetailsData?.teamA);
          setWinnerPlaceholder(matchDetailsData?.teamA);
        }
        if (BWins >= bestOfWins) {
          console.log("WINNER :", matchDetailsData?.teamA);
          setWinnerPlaceholder(matchDetailsData?.teamB);
        }
        if (AWins < bestOfWins && BWins < bestOfWins) {
          setWinnerPlaceholder("");
        }
      }
      if (AWins < bestOfWins && BWins < bestOfWins) {
        console.log("not equal : ", bestOfWins, AWins, BWins);
        setWinnerPlaceholder("");
      }
    }
    if (matchDetailsData?.scoreType === "Race To") {
      if (AWins === matchDetailsData?.noOfSets) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (BWins === matchDetailsData?.noOfSets) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (
        AWins !== matchDetailsData?.noOfSets &&
        BWins !== matchDetailsData?.noOfSets
      ) {
        setWinnerPlaceholder("");
      }
    }
    if (matchDetailsData?.scoreType === "Top Score") {
      const participantA_TopScore = updatedScore.reduce(
        (acc, setObj) => (acc += setObj?.pointsA),
        0
      );
      const participantB_TopScore = updatedScore.reduce(
        (acc, setObj) => (acc += setObj?.pointsB),
        0
      );
      console.log("teamA :", participantA_TopScore);
      console.log("teamB :", participantB_TopScore);
      if (participantA_TopScore > participantB_TopScore) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (participantA_TopScore < participantB_TopScore) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (participantA_TopScore === participantB_TopScore) {
        setWinnerPlaceholder("");
      }
      setWinsA(participantA_TopScore);
      setWinsB(participantB_TopScore);
    }
    if (matchDetailsData?.scoreType !== "Top Score") {
      setWinsA(AWins);
      setWinsB(BWins);
    }
    setParticipantsScores(updatedScore);
    setChangesSaveModel(true);
  };

  const handleSetDelete = (i) => {
    const filteredSetData = participantsScores?.filter(
      (value, index) => index !== i
    );
    const AWins = filteredSetData?.reduce((acc, obj) => {
      if (obj.winner === matchDetailsData?.teamA) {
        acc++;
      }
      return acc;
    }, 0);
    const BWins = filteredSetData?.reduce((acc, obj) => {
      if (obj.winner === matchDetailsData?.teamB) {
        acc++;
      }
      return acc;
    }, 0);
    if (matchDetailsData?.scoreType === "Best Of") {
      const bestOfWins = Math.round(matchDetailsData?.noOfSets / 2);
      if (filteredSetData.length >= matchDetailsData?.noOfSets) {
        if (AWins >= bestOfWins) {
          setWinnerPlaceholder(matchDetailsData?.teamA);
        }
        if (BWins >= bestOfWins) {
          setWinnerPlaceholder(matchDetailsData?.teamB);
        }
        if (AWins < bestOfWins && BWins < bestOfWins) {
          console.log("not equal : ", bestOfWins, AWins, BWins);
          setWinnerPlaceholder("");
        }
      }
      if (AWins < bestOfWins && BWins < bestOfWins) {
        console.log("not equal : ", bestOfWins, AWins, BWins);
        setWinnerPlaceholder("");
      }
    }
    if (matchDetailsData?.scoreType === "Race To") {
      if (AWins === matchDetailsData?.noOfSets) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (BWins === matchDetailsData?.noOfSets) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (
        AWins !== matchDetailsData?.noOfSets &&
        BWins !== matchDetailsData?.noOfSets
      ) {
        setWinnerPlaceholder("");
      }
    }
    if (matchDetailsData?.scoreType === "Top Score") {
      const participantA_TopScore = filteredSetData.reduce(
        (acc, setObj) => (acc += setObj?.pointsA),
        0
      );
      const participantB_TopScore = filteredSetData.reduce(
        (acc, setObj) => (acc += setObj?.pointsB),
        0
      );
      console.log("teamA :", participantA_TopScore);
      console.log("teamB :", participantB_TopScore);
      if (participantA_TopScore > participantB_TopScore) {
        setWinnerPlaceholder(matchDetailsData?.teamA);
      }
      if (participantA_TopScore < participantB_TopScore) {
        setWinnerPlaceholder(matchDetailsData?.teamB);
      }
      if (participantA_TopScore === participantB_TopScore) {
        setWinnerPlaceholder("");
      }
      setWinsA(participantA_TopScore);
      setWinsB(participantB_TopScore);
    }
    if (matchDetailsData?.scoreType !== "Top Score") {
      setWinsA(AWins);
      setWinsB(BWins);
    }
    setParticipantsScores(filteredSetData);
    setChangesSaveModel(true);
  };

  const onSubmitScoreHandler = async () => {
    try {
      console.log("saving score");

      let participantsA_score = [];
      let participantsB_score = [];
      participantsScores?.map((scores) => {
        participantsA_score.push(scores?.pointsA);
        participantsB_score.push(scores?.pointsB);
        return scores;
      });
      setIsSavingScores(true);
      const token = await getAccessToken();
      const payload = {
        scoreA: participantsA_score,
        scoreB: participantsB_score,
        winsA: winsA,
        winsB: winsB,
        scoreType: matchDetailsData?.scoreType,
      };
      const response = await fetch(
        `${APIurls.updateTournamentMatchScores}/${matchID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      if (result?.code > 200) {
        setIsSavingScores(false);
        console.log("scores not updated ", result?.message);
        toast.error(result?.message);
        return;
      }
      console.log("scores Updated Successfully", result);
      toast.success("Scores Updated Successfully");
      setIsSavingScores(false);
      setChangesSaveModel(false);
      return;
    } catch (error) {
      toast.error(error?.message);
      setIsSavingScores(false);
      console.log("error in saving score" + error?.message);
    }
  };

  const handleCricketScoreInput = (event, index, participant) => {
    // setParticipantsScores
    const newScores = [...participantsScores];
    newScores[index][event.target.name] = +event.target.value;
    if (newScores[index]["pointsA"] > newScores[index]["pointsB"]) {
      setCricketWinner(matchDetailsData?.teamA);
      setWinnerPlaceholder(matchDetailsData?.teamA);
    }
    if (newScores[index]["pointsA"] < newScores[index]["pointsB"]) {
      setCricketWinner(matchDetailsData?.teamB);
      setWinnerPlaceholder(matchDetailsData?.teamB);
    }
    if (newScores[index]["pointsA"] === newScores[index]["pointsB"]) {
      setCricketWinner("");
      setWinnerPlaceholder("");
    }
    if (event.target.name === "pointsA" || event.target.name === "pointsB") {
      setCricketWinnerStatus("");
    }

    setParticipantsScores([...newScores]);
    setChangesSaveModel(true);
  };

  const finalResultHandler = (event, position) => {
    console.log("event of w and R : ", event, position);
    const obj = { ...cricketResultObj };
    if (position === "i") {
      obj.winByNumber = +event.target.value;
    }
    if (position === "W") {
      obj.winByName = "Wickets";
    }
    if (position === "R") {
      obj.winByName = "Runs";
    }
    // cricketWinner
    // setCricketWinnerStatus
    if (cricketWinner.length > 0) {
      if (obj?.winByNumber > 0) {
        if (obj?.winByName.length > 0) {
          setCricketWinnerStatus(
            `${cricketWinner} Won By ${obj?.winByNumber} ${obj?.winByName} `
          );
        }
      } else {
        setCricketWinnerStatus("");
      }
    } else {
      setCricketWinnerStatus("");
    }
    setCricketResultOb(obj);
    setChangesSaveModel(true);
  };
  const onSubmitCricketScoreHandler = async () => {
    const scoresData = {
      scoreA: +participantsScores[0].pointsA,
      scoreB: +participantsScores[0].pointsB,
      wicketsA: +participantsScores[0].wicketsA,
      wicketsB: +participantsScores[0].wicketsB,
      oversA: +participantsScores[0].oversA,
      oversB: +participantsScores[0].oversB,
      totalOvers: +participantsScores[0].totalOversA,
      sportStatusNumber: +cricketResultObj?.winByNumber,
      sportStatusName: cricketResultObj?.winByName,
      sportResult: cricketWinnerStatus,
      scoreType: matchDetailsData?.scoreType,
    };
    try {
      console.log("payload : ", scoresData);
      setIsSavingScores(true);
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.updateTournamentMatchCricketScores}/${matchID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(scoresData),
        }
      );
      const result = await response.json();
      console.log("result : ", result);
      if (result.code > 200) {
        setIsSavingScores(false);
        throw new Error(result.message);
      }
      console.log("scores Updated Successfully", result);
      toast.success("Scores Updated Successfully");
      setIsSavingScores(false);
      setChangesSaveModel(false);
      return;
    } catch (error) {
      toast.error(error?.message);
      setIsSavingScores(false);
      console.log("Error in saving cricket scores :" + error?.message);
    }
  };
  const onCancelScoreModel = () => {
    if (changesSaveModel) {
      setOnSaveModel(true);
    } else {
      setAddScoreModal(false);
    }
    return;
  };

  const footballTossWinner = (events, position) => {
    if (events.target.name === "participant") {
      const tossWinObj = { ...footballResultObj };
      switch (position) {
        case "AF":
          tossWinObj.winTeam = matchDetailsData?.teamA;
          break;
        case "BF":
          tossWinObj.winTeam = matchDetailsData?.teamB;
          break;
        case "AC":
          tossWinObj.winTeam = matchDetailsData?.teamA;
          break;
        case "BC":
          tossWinObj.winTeam = matchDetailsData?.teamB;
          break;
        default:
          console.log("Invalid position");
          break;
      }
      console.log("Football participant : ", tossWinObj);
      setFootballResultObj(tossWinObj);
    }
    if (events.target.name === "winnerChoice") {
      const tossWinObj = { ...footballResultObj };
      // tossWinObj.choice =
      //   position === "A" ? "Choice Of Court Side" : "Kick Off";
      switch (position) {
        case "AF":
          tossWinObj.choice = "Choice Of Court Side";
          break;
        case "BF":
          tossWinObj.choice = "Kick Off";
          break;
        case "AC":
          tossWinObj.choice = "Bat";
          break;
        case "BC":
          tossWinObj.choice = "Bowl";
          break;
        default:
          console.log("Invalid position");
          break;
      }
      setFootballResultObj(tossWinObj);
      console.log("Football Choice : ", tossWinObj);
    }
    // console.log("cricket score data : ", participantsScores);
  };
  const handleSaveFootballToss = async () => {
    try {
      if (!footballResultObj?.winTeam || !footballResultObj?.choice) {
        // toast.error("Toss Winner is not Declared");
        return;
      }
      const winnerID =
        footballResultObj?.winTeam === matchDetailsData?.teamA
          ? matchDetailsData?.teamA_id
          : matchDetailsData?.teamB_id;
      let winnerChoice =
        footballResultObj?.choice === "Choice Of Court Side"
          ? "Choice of Side"
          : footballResultObj?.choice;
      setIsSaving(true);
      console.log("options : ", { winnerID, winnerChoice });
      const token = await getAccessToken();
      const response = await fetch(
        `${APIurls.saveMatchTossWinner}/${matchID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ winnerID, winnerChoice }),
        }
      );
      const result = await response.json();
      console.log("result Football: ", result);
      if (!response.ok) {
        setIsSavingScores(false);
        toast.error(result?.message);
        throw new Error(result.message);
      }

      toast.success("Toss Winner Saved");
      setIsSaving(false);
      refetch();
    } catch (error) {
      console.log("Error in saving Toss winner of Football : ", error?.message);
      setIsSaving(false);
    }
  };
  const onChangeFootballScores = (events, index) => {
    console.log("name : ", events?.target.name, ", Position : ", index);
    const footballScores = [...participantsScores];
    if (footballScores[index]?.keyA === events?.target.name) {
      footballScores[index].valueA = +events?.target.value;
    }
    if (footballScores[index]?.keyB === events?.target.name) {
      footballScores[index].valueB = +events?.target.value;
    }
    if (footballScores[0].valueA > footballScores[0].valueB) {
      setWinnerPlaceholder(matchDetailsData?.teamA);
    }
    if (footballScores[0].valueB > footballScores[0].valueA) {
      setWinnerPlaceholder(matchDetailsData?.teamB);
    }
    if (footballScores[0].valueB === footballScores[0].valueA) {
      setWinnerPlaceholder("");
    }
    console.log("On Change Football : ", footballScores);
    setParticipantsScores(footballScores);
    setChangesSaveModel(true);
  };
  const saveFootballScoreHandler = async () => {
    try {
      const GoalsA = participantsScores[0]?.valueA;
      const GoalsB = participantsScores[0]?.valueB;
      const matchDuration = footballTime;
      const footballB = [];
      const footballA = participantsScores?.map((participant) => {
        footballB.push(participant?.valueB);
        return participant?.valueA;
      });
      footballA.shift();
      footballB.shift();
      const payload = {
        GoalsA,
        GoalsB,
        duration: matchDuration,
        participantA: footballA,
        participantB: footballB,
      };
      setIsSavingScores(true);
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.saveFootballScores}/${matchID}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      console.log("Payload : ", payload);
      console.log("result Football: ", result?.response?.match);
      if (!response.ok) {
        setIsSavingScores(false);
        toast.error(result?.message);
        throw new Error(result.message);
      }
      toast.success("Scores Saved Successfully");
      setIsSavingScores(false);
      refetch();
      setChangesSaveModel(false);
    } catch (error) {
      console.log("Error in  Saving scores of Football : ", error);
      setIsSavingScores(false);
    }
  };
  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-[#000000E6] w-[100%]">
      <div
        className={`${status === false ? "w-[30%]" : "w-[60%]"
          } flex flex-col gap-2 bg-white rounded-sm px-6 py-8`}
      >
        <div className="flex justify-end items-center">
          <IconButton
            // onClick={() => setAddScoreModal(false)}
            onClick={() => onCancelScoreModel()}
            className={
              "shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-sm"
            }
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>
        <div className="text-center font-semibold text-primary ">
          {matchDetailsData?.sportName}
        </div>
        {/* ScoreType */}
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <p className="text-lg font-semibold px-2">
              {matchDetailsData?.scoreType}
            </p>
            {matchDetailsData?.scoreType === "Best Of" && (
              <div>
                :-{" "}
                <input
                  type="number"
                  className="w-[70px] rounded-lg custom-number text-center border-[3px] border-[#D9D9D9] bg-white outline-none px-2 py-1 "
                  value={noOfSets}
                  onChange={(e) => setNoOfSets(+e.target.value)}
                />
              </div>
            )}
            {matchDetailsData?.scoreType === "Race To" && (
              <div>
                :-{" "}
                <input
                  type="number"
                  className="w-[70px] rounded-lg custom-number text-center border-[3px] border-[#D9D9D9] bg-white outline-none px-2 py-1 "
                  value={noOfSets}
                  onChange={(e) => setNoOfSets(+e.target.value)}
                />
              </div>
            )}
          </div>
          {matchDetailsData?.scoreType === "Best Of" && (
            <button
              onClick={handleSaveSetNumber}
              className={`flex justify-center items-center text-white px-3 py-1 ${matchDetailsData?.noOfSets !== noOfSets
                  ? "bg-green-600"
                  : "bg-primary"
                }   rounded-md`}
            >
              {matchDetailsData?.noOfSets === noOfSets ? "" : "*"}
              {isSaving ? "Saving... " : "Save "} <PiGreaterThan />
            </button>
          )}
          {matchDetailsData?.scoreType === "Race To" && (
            <button
              onClick={handleSaveSetNumber}
              className={`flex justify-center items-center text-white px-3 py-1 ${matchDetailsData?.noOfSets !== noOfSets
                  ? "bg-green-600"
                  : "bg-primary"
                } rounded-md`}
            >
              {matchDetailsData?.noOfSets === noOfSets ? "" : "*"}
              {isSaving ? "Saving... " : "Save "} <PiGreaterThan />
            </button>
          )}
          {matchDetailsData?.scoreType === "Football" && (
            <button
              onClick={handleSaveFootballToss}
              className={`flex justify-center items-center text-white px-3 py-1 ${matchDetailsData?.tossWinnerName !==
                  footballResultObj?.winTeam ||
                  matchDetailsData?.tossWinnerChoice !== footballResultObj?.choice
                  ? "bg-green-600"
                  : "bg-primary"
                } rounded-md`}
            >
              {matchDetailsData?.tossWinnerName !==
                footballResultObj?.winTeam ||
                matchDetailsData?.tossWinnerChoice !== footballResultObj?.choice
                ? "*"
                : ""}
              {isSaving ? "Saving... " : "Save Toss  "}
              <PiGreaterThan />
            </button>
          )}
          {matchDetailsData?.scoreType === "Cricket" && (
            <button
              onClick={handleSaveFootballToss}
              className={`flex justify-center items-center text-white px-3 py-1 ${matchDetailsData?.tossWinnerName !==
                  footballResultObj?.winTeam ||
                  matchDetailsData?.tossWinnerChoice !== footballResultObj?.choice
                  ? "bg-green-600"
                  : "bg-primary"
                } rounded-md`}
            >
              {matchDetailsData?.tossWinnerName !==
                footballResultObj?.winTeam ||
                matchDetailsData?.tossWinnerChoice !== footballResultObj?.choice
                ? "*"
                : ""}
              {isSaving ? "Saving... " : "Save Toss  "}
              <PiGreaterThan />
            </button>
          )}
        </div>

        {/* For Football */}
        {matchDetailsData?.scoreType === "Football" &&
          matchDetailsData?.sportName?.toLowerCase().includes("football") && (
            <div className="px-4 mt-[-0.5rem] w-full">
              {/* Football Toss */}
              <div className="">
                <div className="flex mb-2 items-center gap-3">
                  <p className="text-md font-semibold w-[120px] ">
                    Toss Winner :{" "}
                  </p>
                  <div className="flex gap-2">
                    <div className="flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                      <input
                        type="radio"
                        checked={
                          footballResultObj?.winTeam === matchDetailsData?.teamA
                            ? true
                            : false
                        }
                        onChange={(e) => footballTossWinner(e, "AF")}
                        name="participant"
                      />
                      <p className="text-[14px] text-[#858585]">
                        {matchDetailsData?.teamA}
                      </p>
                    </div>
                    <div className=" flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                      <input
                        type="radio"
                        checked={
                          footballResultObj?.winTeam === matchDetailsData?.teamB
                            ? true
                            : false
                        }
                        onChange={(e) => footballTossWinner(e, "BF")}
                        name="participant"
                      />
                      <p className="text-[14px] text-[#858585]">
                        {matchDetailsData?.teamB}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-md font-semibold w-[120px] ">
                    Winner Choice :{" "}
                  </p>
                  <div className="flex gap-2">
                    <div className="flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                      <input
                        type="radio"
                        checked={
                          footballResultObj?.choice === "Choice Of Court Side"
                            ? true
                            : false
                        }
                        onChange={(e) => footballTossWinner(e, "AF")}
                        name="winnerChoice"
                      />
                      <p className="text-[14px] text-[#858585]">
                        Choice Of Court Side
                      </p>
                    </div>
                    <div className="flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                      <input
                        type="radio"
                        checked={
                          footballResultObj?.choice === "Kick Off"
                            ? true
                            : false
                        }
                        onChange={(e) => footballTossWinner(e, "BF")}
                        name="winnerChoice"
                      />
                      <p className="text-[14px] text-[#858585]">Kick Off</p>
                    </div>
                  </div>
                </div>
                <p className="h-5">
                  {footballResultObj?.winTeam.length > 0 &&
                    footballResultObj?.choice.length > 0 && (
                      <p className="text-center">
                        <span className="font-semibold">
                          {footballResultObj?.winTeam}
                        </span>{" "}
                        Won the Toss and Choose to{" "}
                        <span className="font-semibold">
                          {footballResultObj?.choice}
                        </span>
                        {" ."}
                      </p>
                    )}
                </p>
              </div>
              {/* Football score */}
              <div className="px-2 lg:px-16 xl:20 ">
                <div className="flex justify-center mt-2 border-t-2 pt-2 items-center">
                  <div className="flex gap-1 items-center mb-1">
                    {" "}
                    <label className="font-semibold">
                      Match Duration :
                    </label>{" "}
                    <input
                      type="number"
                      min={0}
                      value={footballTime}
                      name="duration"
                      onChange={(e) => setFootballTime(+e.target.value)}
                      className="w-[70px] rounded-lg custom-number text-center border-[3px] border-[#D9D9D9] bg-white outline-none px-2 py-1 "
                    />
                    Min.
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <div className=" w-[50%]  flex flex-col justify-center items-center">
                    {participantsScores?.map((setObj, index) => {
                      return (
                        <div
                          className={`w-full py-1 ${index === 0 ? "border-y-2" : "border-b-2"
                            } border-r-2 pr-3 flex justify-between items-center`}
                          key={index}
                        >
                          <label
                            className={`${index === 0
                                ? "text-lg font-semibold"
                                : "text-[#858585] font-medium"
                              }`}
                          >
                            {!footballResultObj?.winTeam
                              ? setObj?.nameA
                              : footballResultObj?.winTeam ===
                                matchDetailsData?.teamA
                                ? setObj?.nameA
                                : setObj?.nameB}
                          </label>
                          <input
                            type="number"
                            min={0}
                            value={
                              !footballResultObj?.winTeam
                                ? setObj?.valueA
                                : footballResultObj?.winTeam ===
                                  matchDetailsData?.teamA
                                  ? setObj?.valueA
                                  : setObj?.valueB
                            }
                            name={
                              !footballResultObj?.winTeam
                                ? setObj?.keyA
                                : footballResultObj?.winTeam ===
                                  matchDetailsData?.teamA
                                  ? setObj?.keyA
                                  : setObj?.keyB
                            }
                            onChange={(e) => onChangeFootballScores(e, index)}
                            className="border-[3px] border-[#D9D9D9] bg-white w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className=" w-[50%] flex flex-col justify-between items-center">
                    {participantsScores?.map((setObj, index) => {
                      return (
                        <div
                          className={`w-full py-1 ${index === 0 ? "border-y-2" : "border-b-2"
                            }  pl-3 flex justify-between items-center`}
                          key={index}
                        >
                          <input
                            type="number"
                            min={0}
                            value={`${!footballResultObj?.winTeam
                                ? setObj?.valueB
                                : footballResultObj?.winTeam ===
                                  matchDetailsData?.teamA
                                  ? setObj?.valueB
                                  : setObj?.valueA
                              }`}
                            name={`${!footballResultObj?.winTeam
                                ? setObj?.keyB
                                : footballResultObj?.winTeam ===
                                  matchDetailsData?.teamA
                                  ? setObj?.keyB
                                  : setObj?.keyA
                              }`}
                            onChange={(e) => onChangeFootballScores(e, index)}
                            className=" border-[3px] border-[#D9D9D9] bg-white] w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                          />
                          <label
                            className={`${index === 0
                                ? "text-lg font-semibold"
                                : "text-[#858585] font-medium"
                              }`}
                          >
                            {!footballResultObj?.winTeam
                              ? setObj?.nameB
                              : footballResultObj?.winTeam ===
                                matchDetailsData?.teamA
                                ? setObj?.nameB
                                : setObj?.nameA}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="flex justify-end items-center mt-1">
                  <button
                    className={`flex justify-center items-center text-white px-3 py-1 ${isSavingScores ? "bg-green-600" : "bg-primary"
                      }   rounded-md`}
                    onClick={saveFootballScoreHandler}
                  >
                    {isSavingScores ? "Saving score ..." : "Save score"}{" "}
                    <PiGreaterThan />
                  </button>
                </div>
              </div>
            </div>
          )}
        {/* For Cricket */}
        {matchDetailsData?.scoreType === "Cricket" &&
          matchDetailsData?.sportName?.toLowerCase().includes("cricket") && (
            <div className="px-4 mt-[-0.5rem] w-full">
              {/* Cricket Toss */}
              <div className="">
                <div className="flex mb-2 items-center gap-3">
                  <p className="text-md font-semibold w-[120px] ">
                    Toss Winner :{" "}
                  </p>
                  <div className="flex gap-2">
                    <div className="flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                      <input
                        type="radio"
                        checked={
                          footballResultObj?.winTeam === matchDetailsData?.teamA
                            ? true
                            : false
                        }
                        onChange={(e) => footballTossWinner(e, "AC")}
                        name="participant"
                      />
                      <p className="text-[14px] text-[#858585]">
                        {matchDetailsData?.teamA}
                      </p>
                    </div>
                    <div className=" flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                      <input
                        type="radio"
                        checked={
                          footballResultObj?.winTeam === matchDetailsData?.teamB
                            ? true
                            : false
                        }
                        onChange={(e) => footballTossWinner(e, "BC")}
                        name="participant"
                      />
                      <p className="text-[14px] text-[#858585]">
                        {matchDetailsData?.teamB}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-md font-semibold w-[120px] ">
                    Winner Choice :{" "}
                  </p>
                  <div className="flex gap-2">
                    <div className="w-[60px] flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                      <input
                        type="radio"
                        checked={
                          footballResultObj?.choice === "Bat" ? true : false
                        }
                        onChange={(e) => footballTossWinner(e, "AC")}
                        name="winnerChoice"
                      />
                      <p className="text-[14px] text-[#858585]">Bat</p>
                    </div>
                    <div className="w-[60px] flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                      <input
                        type="radio"
                        checked={
                          footballResultObj?.choice === "Bowl" ? true : false
                        }
                        onChange={(e) => footballTossWinner(e, "BC")}
                        name="winnerChoice"
                      />
                      <p className="text-[14px] text-[#858585]">Bowl</p>
                    </div>
                  </div>
                </div>
                <p className="h-5">
                  {footballResultObj?.winTeam.length > 0 &&
                    footballResultObj?.choice.length > 0 && (
                      <p className="text-center">
                        <span className="font-semibold">
                          {footballResultObj?.winTeam}
                        </span>{" "}
                        Won the Toss and Choose to{" "}
                        <span className="font-semibold">
                          {footballResultObj?.choice}
                        </span>{" "}
                        first.
                      </p>
                    )}
                </p>
              </div>
              {/* Cricket score */}
              <div className="px-2 lg:px-16 xl:20 ">
                <div className="flex  justify-center mt-2 border-t-2 pt-2 items-center">
                  <div className="flex gap-1 items-center mb-1">
                    {" "}
                    <label className="font-semibold">Total Overs :</label>{" "}
                    <input
                      type="number"
                      min={5}
                      value={participantsScores[0]?.totalOversA}
                      name="totalOversA"
                      onChange={(e) => handleCricketScoreInput(e, 0)}
                      className="w-[70px] rounded-lg custom-number text-center border-[3px] border-[#D9D9D9] bg-white outline-none px-2 py-1 "
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <div className=" w-[50%]  flex flex-col justify-center items-center">
                    <div className="w-full  py-1 border-y-2 border-r-2 pr-3 flex justify-between items-center">
                      <label className="text-lg font-semibold">
                        {!footballResultObj?.winTeam && matchDetailsData?.teamA}
                        {footballResultObj?.winTeam}
                      </label>
                      {/* Runs : */}
                      <input
                        type="number"
                        min={1}
                        value={`${!footballResultObj?.winTeam
                            ? participantsScores[0]?.pointsA
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? participantsScores[0]?.pointsA
                              : participantsScores[0]?.pointsB
                          }`}
                        name={`${!footballResultObj?.winTeam
                            ? "pointsA"
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? "pointsA"
                              : "pointsB"
                          }`}
                        onChange={(e) => handleCricketScoreInput(e, 0)}
                        className="border-[3px] border-[#D9D9D9] bg-white w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                      />
                    </div>
                    <div className="w-full  py-1 border-b-2 border-r-2 pr-3 flex justify-between items-center">
                      <label className="text-[#858585] font-medium">
                        Wickets{" "}
                      </label>
                      <input
                        type="number"
                        min={1}
                        value={`${!footballResultObj?.winTeam
                            ? participantsScores[0]?.wicketsA
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? participantsScores[0]?.wicketsA
                              : participantsScores[0]?.wicketsB
                          }`}
                        name={`${!footballResultObj?.winTeam
                            ? "wicketsA"
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? "wicketsA"
                              : "wicketsB"
                          }`}
                        onChange={(e) => handleCricketScoreInput(e, 0)}
                        className=" border-[3px] border-[#D9D9D9] bg-white w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                      />
                    </div>
                    <div className=" w-full  py-1 border-b-2 border-r-2 pr-3 flex justify-between items-center">
                      <label className="text-[#858585] font-medium">
                        Overs{" "}
                      </label>
                      <input
                        type="number"
                        // max={10}
                        value={`${!footballResultObj?.winTeam
                            ? participantsScores[0]?.oversA
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? participantsScores[0]?.oversA
                              : participantsScores[0]?.oversB
                          }`}
                        name={`${!footballResultObj?.winTeam
                            ? "oversA"
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? "oversA"
                              : "oversB"
                          }`}
                        // name="oversA"
                        onChange={(e) => handleCricketScoreInput(e, 0)}
                        className=" border-[3px] border-[#D9D9D9] bg-white w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                      />
                    </div>
                  </div>
                  <div className=" w-[50%] flex flex-col justify-between items-center">
                    <div className="w-full py-1 border-y-2   pl-3 flex justify-between items-center">
                      <input
                        type="number"
                        // max={10}
                        value={`${!footballResultObj?.winTeam
                            ? participantsScores[0]?.pointsB
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? participantsScores[0]?.pointsB
                              : participantsScores[0]?.pointsA
                          }`}
                        // name="pointsB"
                        name={`${!footballResultObj?.winTeam
                            ? "pointsB"
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? "pointsB"
                              : "pointsA"
                          }`}
                        onChange={(e) => handleCricketScoreInput(e, 0)}
                        className=" border-[3px] border-[#D9D9D9] bg-white] w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                      />
                      <label className="text-lg font-medium">
                        {footballResultObj?.winTeam &&
                          footballResultObj?.winTeam === matchDetailsData?.teamB
                          ? matchDetailsData?.teamA
                          : matchDetailsData?.teamB}
                      </label>
                    </div>
                    <div className="w-full py-1 border-b-2  pl-3 flex justify-between items-center">
                      <input
                        type="number"
                        // max={10}
                        value={`${!footballResultObj?.winTeam
                            ? participantsScores[0]?.wicketsB
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? participantsScores[0]?.wicketsB
                              : participantsScores[0]?.wicketsA
                          }`}
                        // name="wicketsB"
                        name={`${!footballResultObj?.winTeam
                            ? "wicketsB"
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? "wicketsB"
                              : "wicketsA"
                          }`}
                        onChange={(e) => handleCricketScoreInput(e, 0)}
                        className=" border-[3px] border-[#D9D9D9] bg-white w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                      />
                      <label className="text-[#858585] font-medium">
                        Wickets{" "}
                      </label>
                    </div>
                    <div className="w-full py-1 border-b-2  pl-3 flex justify-between items-center">
                      <input
                        type="number"
                        // min={10}
                        value={`${!footballResultObj?.winTeam
                            ? participantsScores[0]?.oversB
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? participantsScores[0]?.oversB
                              : participantsScores[0]?.oversA
                          }`}
                        // name="oversB"
                        name={`${!footballResultObj?.winTeam
                            ? "oversB"
                            : footballResultObj?.winTeam ===
                              matchDetailsData?.teamA
                              ? "oversB"
                              : "oversA"
                          }`}
                        onChange={(e) => handleCricketScoreInput(e, 0)}
                        className=" border-[3px] border-[#D9D9D9] bg-white w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                      />
                      <label className="text-[#858585] font-medium">
                        Overs{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-center flex mt-2 items-center justify-center gap-2">
                  <p className="text-center font-semibold">
                    {" "}
                    {cricketWinner.length > 0
                      ? `${cricketWinner}   `
                      : "Participant "}
                  </p>
                  <span>Won By</span>
                  <input
                    type="number"
                    value={cricketResultObj.winByNumber}
                    defaultValue={0}
                    min="0"
                    onChange={(e) => finalResultHandler(e, "i")}
                    className="border-[3px] border-[#D9D9D9] bg-white w-[70px] rounded-lg custom-number text-center outline-none px-2 py-1 "
                  />
                  <div className="w-[100px] flex justify-center items-center rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                    <input
                      type="radio"
                      checked={
                        cricketResultObj?.winByName === "Wickets" ? true : false
                      }
                      onChange={(e) => finalResultHandler(e, "W")}
                      name="defaultSponsor"
                      placeholder="Enter result"
                    />
                    <p className="text-[14px] text-[#858585]">Wickets</p>
                  </div>
                  <div className="w-[100px] flex justify-center items-center  rounded-md border border-[#D9D9D9] gap-1 px-2 py-2">
                    <input
                      type="radio"
                      checked={
                        cricketResultObj?.winByName === "Runs" ? true : false
                      }
                      onChange={(e) => finalResultHandler(e, "R")}
                      name="defaultSponsor"
                      placeholder="Enter result"
                    />
                    <p className="text-[14px] text-[#858585]">Runs</p>
                  </div>
                </div>
                <div className="text-center font-semibold mt-2 h-6">
                  {cricketWinnerStatus ? cricketWinnerStatus : ""}
                </div>
                <div className="flex justify-end items-center mt-1">
                  <button
                    className={`flex justify-center items-center text-white px-3 py-1 ${isSavingScores ? "bg-green-600" : "bg-primary"
                      }   rounded-md`}
                    onClick={onSubmitCricketScoreHandler}
                  >
                    {isSavingScores ? "Saving score ..." : "Save score"}{" "}
                    <PiGreaterThan />
                  </button>
                </div>
              </div>
            </div>
          )}

        {/* Others Sports Score Apart from Cricket */}
        {matchDetailsData?.scoreType !== "Cricket" &&
          matchDetailsData?.scoreType !== "Football" && (
            <div className="flex flex-col gap-[1rem] w-[100%]">
              <div className="flex justify-end items-center">
                <button
                  className="w-[160px] flex justify-center items-center text-white bg-primary px-3 py-1 rounded-md"
                  onClick={handleAddSetScore}
                >
                  Add Set Score <LuPlus />
                </button>
              </div>
              <div className="px-4 py-4 rounded-md border border-[#EAECF0] w-[100%]">
                <div className="flex justify-center gap-[3rem] pb-3">
                  <div className="flex items-center flex-col gap-[1rem]">
                    <p className="text-lg font-semibold">Participants</p>
                    <p className="text-base py-1">{matchDetailsData?.teamA}</p>
                    <p className="text-base py-1">{matchDetailsData?.teamB}</p>
                  </div>
                  <div className="overflow-x-auto w-[80%]">
                    <div className="flex w-[200px] gap-[1rem] ">
                      {participantsScores?.map((div, i) => {
                        return (
                          <div className="w-[100px] flex flex-col gap-[1rem] ">
                            <div className="flex justify-evenly items-center">
                              <p className="text-lg font-semibold text-start">
                                Set{" "}
                                {participantsScores.length === 1 ? "" : i + 1}
                              </p>
                              {participantsScores.length > 1 && (
                                <RiDeleteBinFill
                                  className="text-[1.3rem] text-primary cursor-pointer"
                                  onClick={() => handleSetDelete(i)}
                                ></RiDeleteBinFill>
                              )}
                            </div>
                            <input
                              type="number"
                              name="score"
                              value={div?.pointsA}
                              placeholder="Enter Score"
                              className="w-[70px] rounded-lg custom-number text-center border-[3px] border-[#D9D9D9] bg-white outline-none px-2 py-1 "
                              onChange={(e) => handleScoreInput(e, i, "A")}
                            />
                            <input
                              type="number"
                              value={div?.pointsB}
                              placeholder="Enter Score"
                              className=" w-[70px] rounded-lg custom-number text-center border-[3px] border-[#D9D9D9] bg-white outline-none px-2 py-1 "
                              onChange={(e) => handleScoreInput(e, i, "B")}
                            />
                            {matchDetailsData?.scoreType !== "Cricket" && (
                              <p className="text-center text-sm">
                                {div?.winner}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {matchDetailsData?.scoreType !== "Cricket" && (
                  <div>
                    <p className="text-center text-base font-semibold">
                      {" "}
                      {matchDetailsData?.scoreType === "Top Score"
                        ? "Top Scores"
                        : "Set Wins"}
                    </p>
                    <div className="flex justify-center items-center gap-[3rem]">
                      <p>
                        {`${matchDetailsData?.teamA} : `}
                        <span className="text-lg font-semibold">{winsA}</span>
                      </p>
                      <p>
                        {`${matchDetailsData?.teamB} : `}
                        <span className="text-lg font-semibold">{winsB}</span>
                      </p>
                    </div>
                  </div>
                )}
                <div className="flex justify-end items-center mt-4">
                  <button
                    className={`flex justify-center items-center text-white px-3 py-1 ${isSavingScores ? "bg-green-600" : "bg-primary"
                      }   rounded-md`}
                    onClick={onSubmitScoreHandler}
                  >
                    {isSavingScores ? "Saving score ..." : "Save score"}{" "}
                    <PiGreaterThan />
                  </button>
                </div>
              </div>
            </div>
          )}
        {status === false ? (
          <div className="flex flex-col items-center justify-center gap-3">
            <img src={trophy} className="w-[40%]" alt="img" />
            <h3 className="text-white bg-[#3CB047] w-[30%] text-center font-semibold py-2 rounded-md">
              {matchDetailsData?.winner}
            </h3>
          </div>
        ) : (
          ""
        )}
        {status && (
          <div className="flex flex-col py-3 gap-3">
            <h2 className="font-semibold">Announce Winner</h2>
            <div className="flex items-center justify-evenly">
              <div className="w-[40%] ">
                <p
                  className={`${winner === matchDetailsData?.teamA_id
                      ? "text-white bg-[#3CB047]"
                      : "bg-[#EFF1F9]"
                    } text-center font-semibold py-2 mb-2 rounded-md cursor-pointer`}
                  onClick={() => setWinner(matchDetailsData?.teamA_id)}
                >
                  {matchDetailsData?.teamA
                    ? matchDetailsData?.teamA
                    : "No Participant"}
                </p>
                <hr
                  className={`border-2 h-1 ${winnerPlaceholder === matchDetailsData?.teamA &&
                    " border-primary bg-primary rounded"
                    }`}
                />
              </div>
              <div className="w-[40%]">
                <p
                  className={`${winner === matchDetailsData?.teamB_id
                      ? "text-white bg-[#3CB047]"
                      : "bg-[#EFF1F9]"
                    } text-center font-semibold py-2 mb-2 rounded-md cursor-pointer`}
                  onClick={() => setWinner(matchDetailsData?.teamB_id)}
                >
                  {matchDetailsData?.teamB
                    ? matchDetailsData?.teamB
                    : "No Participant"}
                </p>
                <hr
                  className={`border-2 h-1 ${winnerPlaceholder === matchDetailsData?.teamB &&
                    " border-primary bg-primary rounded"
                    }`}
                />
              </div>
            </div>
            {!winner && showError && (
              <p className="text-red-500 text-sm text-center">
                *This field is required
              </p>
            )}
            <div className="flex justify-end">
              <button
                className={`flex items-center text-white ${announceWinner
                    ? "bg-green-700 cursor-not-allowed"
                    : "bg-[#FB6108] cursor-pointer"
                  }  px-4 py-2 rounded-md gap-1 `}
                onClick={() => handleAnnounceWinner(winner)}
                disabled={announceWinner ? true : false}
              >
                {announceWinner ? "Announcing. . ." : "Announce Winner "}
                <PiGreaterThan />
              </button>
            </div>
          </div>
        )}
      </div>
      {changesSaveModel && onSaveModel && (
        <ChangesSavePopupModel
          setAddScoreModal={setAddScoreModal}
          setOnSaveModel={setOnSaveModel}
        />
      )}
    </div>
  );
}
