import { ThemeProvider } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react'
import { selectCustomTheme } from '../../../helpers/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from "@iconify/react";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import DownloadExcel from '../../../exportData/ExportExcel'
import pdfLogo from '../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../../exportData/ExportPDF'

export default function ViewSubscriptions() {

    const [specificClubSubscription, setSpecificClubSubscription] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adminName, setAdminName] = useState("");

    const navigate = useNavigate();
    const { getAccessToken } = useAuth();
    const { _id } = useParams();

    const getSpecificClubSubscription = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchSpecificClubSubscription}/${_id}?page=${currentPage}&limit=${recordsPerPage}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const result = await response.json();
                throw new Error(result?.message);
            }
            const result = await response.json();
            console.log("Specific Club Subscriptions--->", result?.response);
            setSpecificClubSubscription(result?.response);
        } catch (error) {
            console.log("Error while fetching Specific Club Subscriptions", error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getSpecificClubSubscription();
    }, [])

    useEffect(() => {
        getSpecificClubSubscription();
    }, [currentPage, recordsPerPage])

    const fetchAdminDetails = async () => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message)
            }
            setAdminName(result?.response?.name)
        } catch (error) {
            console.log("Error in fetching admin details", error);
        }
    }

    useEffect(() => {
        fetchAdminDetails();
    }, [])

    const columns = [
        {
            name: "SL",
            selector: (row, i) => i + 1,
            center: true,
            width: "80px"
        },
        {
            name: "Customer Name",
            selector: row => (
                row?.userId?.FirstName + " " + row?.userId?.LastName
            ),
            center: true
        },
        {
            name: "Mobile Number",
            selector: row => (
                row?.userId?.mobileNumber
            ),
            center: true
        },
        {
            name: "Subscription ID",
            selector: row => (
                row?.customId || "-"
            ),
            center: true
        },
        {
            name: "Name of Subscription",
            selector: row => (
                <div>{row?.subscriptionId?.name || "-"}</div>
            ),
            center: true
        },
        {
            name: "Amount",
            selector: row => (
                row?.amount
            ),
            center: true
        },
        {
            name: "Date of Payment",
            selector: row => (
                <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
            ),
            center: true
        },
        {
            name: "Renewal Date",
            selector: row => (
                <div>{moment(row?.willRenewed).format("MMM Do YY")}</div>
            ),
            center: true
        },
        {
            name: "Status",
            selector: row => (
                <div className={`${row?.isActive === true ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"} flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
              py-[0.4rem] w-[7rem]`}>
                    <span className={`${row?.isActive === true ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                    {row?.isActive === true ? "Active" : "Inactive"}
                </div>
            ),
            center: true
        },
    ]

    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                // padding: "10px 10px",
                color: "#4D4D4D",
                fontSize: "13px",
            },
        },
        head: {
            style: {
                background: "#FFF",
            },
        },

        rows: {
            style: {
                background: "#FFF",
                color: "#565656",
                padding: "5px 0px",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
            },
        },
        table: {
            style: {
                overflow: "visible",
                minWidth: "1100px",
                maxWidth: "1400px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto", // table scroll on x axis
            },
        },
    };

    const handleDirection = (direction) => {
        if (direction === "right") {
            const currPg = currentPage + 1;
            if (currentPage === specificClubSubscription?.pagination?.totalPage) {
                setCurrentPage(specificClubSubscription?.pagination?.totalPage);
            } else {
                setCurrentPage(currPg);
            }
        } else if (direction === "left") {
            const currPg = currentPage - 1;
            if (currentPage === 1) {
                setCurrentPage(1);
            } else {
                setCurrentPage(currPg);
            }
        }
    };

    const convertImageToBase64 = async (imagePath) => {
        try {
            const response = await fetch(imagePath);
            const blob = await response.blob();

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error converting image to base64:', error);
            throw error;
        }
    };

    const handleDownloadPDF = async () => {
        setLoading(true);
        try {
            const base64String = await convertImageToBase64(gamebeezLogo);

            const pdfData = specificClubSubscription?.subscriptions?.map((ele, index) => [
                index + 1,
                ele?.userId?.FirstName + " " + ele?.userId?.LastName,
                ele?.userId?.mobileNumber,
                ele?.customId || "-",
                ele?.subscriptionId?.name || "-",
                ele?.amount,
                moment(ele?.createdAt).format("MMM Do YY"),
                moment(ele?.willRenewed).format("MMM Do YY"),
                ele?.isActive === true ? "Active" : "Inactive"
            ]);

            const pdfColumns = ["SL", 'Customer Name', "Mobile Number", "Subscription ID", 'Name of Subscription', 'Amount', "Date of Payment", "Renewal Date", "Status"];

            await DownloadPDF(pdfData, pdfColumns, "Specific Club Subscription.pdf", base64String, adminName);
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setLoading(false);
        }
    };

    const excelData = specificClubSubscription?.subscriptions?.map((item, index) => [
        index + 1,
        item?.userId?.FirstName + " " + item?.userId?.LastName,
        item?.userId?.mobileNumber,
        item?.customId || "-",
        item?.subscriptionId?.name || "-",
        item?.amount,
        moment(item?.createdAt).format("MMM Do YY"),
        moment(item?.willRenewed).format("MMM Do YY"),
        item?.isActive === true ? "Active" : "Inactive"
    ])

    const excelColumns = ["SL", 'Customer Name', "Mobile Number", "Subscription ID", 'Name of Subscription', 'Amount', "Date of Payment", "Renewal Date", "Status"];

    return (
        <ThemeProvider value={selectCustomTheme}>
            <div className="bg-[#E6E6E6] px-[1.5rem] py-[1rem]">
                <div className="rounded-md bg-[#FFFFFF] px-[1rem] py-[0.5rem]">
                    <button
                        className='text-primary flex items-center border border-primary rounded-md px-2 py-1 mt-2'
                        onClick={() => navigate("/subscriptions")}
                    >
                        <Icon icon="ic:twotone-less-than" className='text-[1.2rem]' />
                        Back
                    </button>
                    <div className='flex items-center justify-between border-b border-[#EAECF0]'>
                        <h3 className="mt-6 ml-3 text-[1.2rem] sm:text-[1.3rem] font-semibold pb-[0.5rem]">
                            Specific Club Subscriptions
                        </h3>
                        <div className='flex gap-3 items-center rounded-md'>
                            {loading ? (
                                <p className="text-xs">Generating PDF...</p>
                            ) : (
                                <img
                                    src={pdfLogo}
                                    className='w-[25px] cursor-pointer'
                                    onClick={handleDownloadPDF}
                                    alt="Generate PDF"
                                />
                            )}
                            <FaFileCsv
                                className='w-[25px] h-[30px] text-green-800 cursor-pointer'
                                onClick={() => DownloadExcel({ excelData, excelColumns })}
                            />
                        </div>
                    </div>
                    <div className='px-[2rem] py-[2.5rem]'>
                        {isLoading ? <p className='text-xl font-semibold text-center'>Loading...</p>
                            :
                            <DataTable
                                columns={columns}
                                data={specificClubSubscription?.subscriptions}
                                customStyles={customStyles}
                                selectableRows
                                selectableRowsComponent={CustomCheckbox}
                            />
                        }
                        {specificClubSubscription?.pagination?.totalDocuments > 0 &&
                            <div className="flex justify-center items-center gap-2 py-2">
                                <button
                                    className={`${currentPage === 1 ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                                    onClick={() => handleDirection("left")}
                                >Prev</button>
                                <span>
                                    {currentPage} / {Math.ceil(specificClubSubscription?.pagination?.totalDocuments / recordsPerPage)}
                                </span>
                                <button
                                    className={`${currentPage === specificClubSubscription?.pagination?.totalPage ? "cursor-not-allowed text-gray-500" : "cursor-pointer"} rounded-md px-2 border-2`}
                                    onClick={() => handleDirection("right")}
                                >Next</button>
                                <div className="flex items-center">
                                    <span>Per Page:</span>
                                    <select
                                        value={recordsPerPage}
                                        onChange={(e) => { setRecordsPerPage(+e.target.value); setCurrentPage(1); }}
                                        className="border outline-none ml-2"
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={specificClubSubscription?.pagination?.totalDocuments}>{specificClubSubscription?.pagination?.totalDocuments}</option>
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}
