import React, { useEffect, useState } from "react";
import {
    IconButton,
} from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import CustomCheckbox from "../../../../helpers/CustomCheckbox";
import { toast, ToastContainer } from "react-toastify";
import AddCoClubOwner from "./AddCoClubOwner";
import { useOutletContext } from "react-router-dom";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import moment from "moment";
import EditCoClubOwner from "./EditCoClubOwner";
import CoClubOwnerDeleteModal from "./CoClubOwnerDeleteModal";
import { ThreeDots } from 'react-loader-spinner';

export default function ViewCoClubOwner() {

    const [addCoClubOwnerModal, setAddCoClubOwnerModal] = useState(false);
    const [editCoClubOwnerModal, setEditCoClubOwnerModal] = useState(false);
    const [coClubOwnerId, setCoClubOwnerId] = useState("");
    const [coClubOwnerEmail, setCoClubOwnerEmail] = useState("");
    const [coClubOwnersData, setCoClubOwnersData] = useState([]);
    const [refetch, setRefetch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [coClubOwnerDeleteModal, setCoClubOwnerDeleteModal] = useState(false);
    const [archiveCoClubOwnerData, setArchiveCoClubOwnerData] = useState([]);
    const [refetchArchiveData, setRefetchArchiveData] = useState(true);
    const [isCoClubOwnerRetrive, setIsCoClubOwnerRetrive] = useState({});
    const [isDeleteCoDataLoading, setIsDeleteCoDataLoading] = useState(false);

    const [Id] = useOutletContext();
    const { getAccessToken } = useAuth();

    const fetchCoClubOwners = async () => {
        try {
            setIsLoading(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAllCoClubOwners}/${Id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const result = await response.json();
                throw new Error("Error while fetching Co-Club Owners", result.message);
            }
            const result = await response.json();
            console.log("Co-Club Owners Data--->", result?.response?.coOwners)
            setCoClubOwnersData(result?.response?.coOwners);
            setIsLoading(false);
            return result?.response?.coOwners
        } catch (error) {
            console.log(error.message);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchCoClubOwners();
    }, [Id])

    useEffect(() => {
        if (refetch) {
            fetchCoClubOwners();
            setRefetch(false);
        }
    }, [refetch])

    const fetchDeletedCoClubOwners = async () => {
        try {
            setIsDeleteCoDataLoading(true);
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.fetchAllDeletedCoClubOwners}/${Id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const result = await response.json();
                throw new Error(result?.message);
            }
            const result = await response.json();
            console.log("Deleted Co-Club Owners Data", result?.response?.archives)
            setArchiveCoClubOwnerData(result?.response?.archives);
        } catch (error) {
            console.log("Error in fetching deleted Co-Club Owners", error)
        }
        setIsDeleteCoDataLoading(false);
    }

    useEffect(() => {
        fetchDeletedCoClubOwners();
    }, [Id])

    const handleRecoverCoClubOwner = async (id) => {
        try {
            setIsCoClubOwnerRetrive((prevState) => ({
                ...prevState,
                [id]: true,
            }))
            const token = await getAccessToken();
            const response = await fetch(`${APIurls.recoverCoClubOwnerFromArchive}/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const result = await response.json();
                toast.error(result?.message);
                throw new Error(result.message);
            }
            const result = await response.json();
            console.log("Co-Club Owner Recovered Successfully", result?.response);
            setRefetch(true);
            fetchDeletedCoClubOwners();
            toast.success(result?.message);
        } catch (error) {
            console.log(error.message)
        }
        setIsCoClubOwnerRetrive((prevState) => ({
            ...prevState,
            [id]: false,
        }))
    }

    useEffect(() => {
        if (refetchArchiveData) {
            fetchDeletedCoClubOwners();
            setRefetchArchiveData(false);
        }
    }, [refetchArchiveData])

    const handleAddCoClubOwner = () => {
        setAddCoClubOwnerModal(true);
    }

    const handleEditCoClubOwnerModal = (id, email) => {
        setCoClubOwnerEmail(email);
        setCoClubOwnerId(id);
        setEditCoClubOwnerModal(true);
    }

    const handleDeleteCoClubOwnerModal = (id) => {
        setCoClubOwnerDeleteModal(true);
        setCoClubOwnerId(id);
    }


    const customStyles = {
        headRow: {
            style: {
                fontWeight: 600,
                color: "#4D4D4D",
                fontSize: "13px",
            },
        },
        head: {
            style: {
                background: "#FFF",
            },
        },

        rows: {
            style: {
                background: "#FFF",
                color: "#565656",
                fontSize: "14px",
                fontWeight: 400,
                width: "auto",
                padding: "5px 0px",
            },
        },
        table: {
            style: {
                overflow: "visible",
                minWidth: "1100px",
            },
        },
        tableWrapper: {
            style: {
                overflow: "visible",
            },
        },
        responsiveWrapper: {
            style: {
                overflowX: "auto", // table scroll on x axis
            },
        },
    };

    const columns1 = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            center: true,
            maxWidth: "0.5rem",
        },
        {
            name: "Co-Club Owner Name",
            selector: (row) => (
                <div>{row?.name}</div>
            ),
            center: true,
            //   grow: 1,
        },
        {
            name: "Mobile Number",
            selector: (row) => (
                <div>{row?.mobile}</div>
            ),
            center: true,
            //   grow: 1,
        },
        {
            name: "Email Address",
            selector: (row) => (
                <div>{row?.email}</div>
            ),
            center: true,
            //   grow: 1,
        },
        {
            name: "Date Added",
            selector: (row) => (
                <div>{moment(row?.createdAt).format("MMM Do YY")}</div>
            ),
            center: true,
            // grow: 1,
        },
        {
            name: "Updated Date",
            selector: row => (
                <div>{moment(row?.updatedAt).format("MMM Do YY")}</div>
            ),
            center: true
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-[0.5rem]">
                    <IconButton
                        className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black rounded-lg border border-[#D9D9D9]"
                        onClick={() => handleEditCoClubOwnerModal(row?._id, row?.email)}
                    >
                        <Icon
                            icon="material-symbols:edit-rounded"
                            className="text-[1.5rem] text-black"
                        />
                    </IconButton>
                    <IconButton
                        className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] rounded-lg border border-[#D9D9D9]"
                        onClick={() => handleDeleteCoClubOwnerModal(row?._id)}
                    >
                        <Icon
                            icon="material-symbols:delete-rounded"
                            className="text-[1.5rem] text-black"
                        />
                    </IconButton>
                </div>
            ),
            center: true,
            //   grow: 1,
        },
    ];

    const columns2 = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            center: true,
            width: "80px"
        },
        {
            name: "Co-Club Owner Name",
            selector: (row) => (
                <div>{row?.data?.name}</div>
            ),
            center: true,
            grow: 2,
        },
        {
            name: "Mobile Number",
            selector: (row) => (
                <div>{row?.data?.mobile}</div>
            ),
            center: true,
            //   grow: 1,
        },
        {
            name: "Email Address",
            selector: (row) => (
                <div>{row?.data?.email}</div>
            ),
            center: true,
            //   grow: 1,
        },
        {
            name: "Date Added",
            selector: (row) => (
                <div>{moment(row?.data?.createdAt).format("MMM Do YY")}</div>
            ),
            center: true,
            // grow: 1,
        },
        {
            name: "Updated Date",
            selector: row => (
                <div>{moment(row?.data?.updatedAt).format("MMM Do YY")}</div>
            ),
            center: true
        },
        {
            name: "Deleted Remark",
            selector: row => (
                <div>{row?.reason}</div>
            )
        },
        {
            name: "Actions",
            selector: (row) => (
                <div>
                    {
                        isCoClubOwnerRetrive[row?._id] ? (
                            <ThreeDots
                                height="40"
                                width="40"
                                radius="9"
                                color="#FB6108"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        ) : (
                            <div
                                className=""
                                title="Retrive"
                                onClick={() => handleRecoverCoClubOwner(row?._id)}
                            >
                                <IconButton className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg">
                                    <Icon icon="ooui:restore" className="text-[1.2rem] text-black" />
                                </IconButton>
                            </div>
                        )}
                </div>
            ),
            center: true,
            //   grow: 1,
        },
    ];

    return (
        <div className="bg-white rounded-md flex flex-col gap-[0.5rem]">
            <div className="flex items-center justify-between">
                <h2 className="text-[1.3rem] font-semibold">Co-Club Owner</h2>
                <div>
                    <button
                        className="font-medium flex items-center justify-center gap-[0.3rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md"
                        onClick={handleAddCoClubOwner}
                    >
                        <Icon
                            icon="material-symbols:add"
                            className=""
                        />
                        <span className="">Add Co-Club Owner</span>
                    </button>
                </div>
            </div>
            {isLoading ?
                <p className="text-xl font-semibold text-center">Loading...</p>
                :
                <div className="overflow-x-auto">
                    <DataTable
                        columns={columns1}
                        customStyles={customStyles}
                        data={coClubOwnersData}
                        pagination
                        selectableRows
                        selectableRowsComponent={CustomCheckbox}
                    />
                </div>
            }
            <div>
                <header className="text-[1.3rem] font-semibold pb-4">Deleted Co-Club Owners List</header>
                {isDeleteCoDataLoading ? <p className="text-xl font-semibold text-center">Loading...</p>
                    :
                    <DataTable
                        columns={columns2}
                        data={archiveCoClubOwnerData}
                        customStyles={customStyles}
                        pagination
                        selectableRows
                        selectableRowsComponent={CustomCheckbox}
                    />
                }
            </div>
            <ToastContainer />
            {addCoClubOwnerModal && (
                <AddCoClubOwner
                    setAddCoClubOwnerModal={setAddCoClubOwnerModal}
                    clubMongoId={Id}
                    setRefetch={setRefetch}
                />
            )}
            {editCoClubOwnerModal && (
                <EditCoClubOwner
                    setEditCoClubOwnerModal={setEditCoClubOwnerModal}
                    coClubOwnerId={coClubOwnerId}
                    setRefetch={setRefetch}
                    existingEmail={coClubOwnerEmail}
                />
            )}
            {coClubOwnerDeleteModal && (
                <CoClubOwnerDeleteModal
                    setCoClubOwnerDeleteModal={setCoClubOwnerDeleteModal}
                    setRefetch={setRefetch}
                    coClubOwnerId={coClubOwnerId}
                    fetchDeletedCoClubOwners={fetchDeletedCoClubOwners}
                />
            )}
        </div>
    );
}
