import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, IconButton, Menu, MenuHandler, MenuItem, MenuList, Option, Select } from '@material-tailwind/react';
import { Icon } from '@iconify/react';
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../../helpers/CustomCheckbox';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import parkingIcon from '../../../assets/parking-icon.svg';
import lockerIcon from '../../../assets/lockers-icon.svg';
import drinkingWaterIcon from '../../../assets/drinking-water-icon.svg';
import DownloadExcel from '../../../exportData/ExportExcel'
import pdfLogo from '../../../assets/pdfImage.png'
import { FaFileCsv } from "react-icons/fa6";
import gamebeezLogo from '../../../assets/GB logo For PDF.jpg'
import DownloadPDF from '../../../exportData/ExportPDF'
import CircleLoader from 'react-spinners/CircleLoader';
import { RxCross2 } from 'react-icons/rx';

export default function ManageAmenities({ updateAmenitiesList, setUpdateAmenitiesList }) {

  const [loadingStates, setLoadingStates] = useState({});
  const [toggleLoadingStates, setToggleLoadingStates] = useState({});
  const [selectedRows, setSelectedRows] = useState([])
  const [status, setStatus] = useState("")
  const [statusdata, setStatusData] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [base64Logo, setBase64Logo] = useState("");
  const [loading, setLoading] = useState(false);
  const [adminName, setAdminName] = useState("")

  const { getAccessToken, userRole } = useAuth();

  const fetchAmenitiesData = async () => {
    const token = await getAccessToken();
    const response = await fetch(`${APIurls.fetchAmenities}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.message);
    }
    // console.log("Amenities", result?.response?.amenities);
    setStatusData(result?.response?.amenities.reverse())
    return result?.response?.amenities.reverse();
  }
  const { data: amenitiesData, isLoading, refetch } = useQuery('amenities', fetchAmenitiesData);

  console.log("AMENITIES", amenitiesData);

  const fetchAdminDetails = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${APIurls.fetchAdminOrSubAdminDetails}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message)
      }
      setAdminName(result?.response?.name)
    } catch (error) {
      console.log("Error in fetching admin details", error);
    }
  }

  useEffect(() => {
    fetchAdminDetails();
  }, [])

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        // padding: "10px 10px",
        color: "#4D4D4D",
        fontSize: "13px",
      },
    },
    head: {
      style: {
        background: "#FFF",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 0px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1100px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Title",
      id: "title",
      selector: (row) => row.title,
      center: true,
      grow: 2,
    },
    {
      name: "Date and Time",
      id: "dateTime",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY, hh:mm A"),
      center: true,
      grow: 2,
    },
    {
      name: "Icon",
      id: "icon",
      selector: (row) => (
        <img src={row.icon} alt="Icon"
          className="h-[50px] w-[50px]" />
      ),
      center: true,
      grow: 1,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
          py-[0.4rem] w-[6rem] ${row.status === "active" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row.status === "active" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex items-center gap-[0.5rem]">

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("UPDATE_AMENITIES")) ?
            <Link to={`edit/${row._id}`}>
              <IconButton
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
              >
                <Icon
                  icon="material-symbols:edit-rounded"
                  className="text-[1.5rem]"
                />
              </IconButton>
            </Link>
            :
            "-"
          }

          {
            toggleLoadingStates[row._id] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#FB6108"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => toggleAmenityStatusHandler(row._id)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
              >
                <Icon
                  icon="ph:power-fill"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )
          }

          {(userRole?.role?.admin || userRole?.role?.subAdmin?.permissions?.includes("DELETE_AMENITIES")) ?
            (loadingStates[row._id] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#FB6108"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => archiveAmenityHandler(row._id)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-black border border-[#D9D9D9] rounded-lg"
              >
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem]"
                />
              </IconButton>
            ))
            :
            "-"
          }
        </div>
      ),
      center: true,
      grow: 1,
    },
  ]

  const archiveAmenityHandler = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    let isConfirm = confirm("Are you sure you want to Archive this Amenity?");
    if (isConfirm) {
      const token = await getAccessToken();
      try {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: true,
        }))
        const response = await fetch(`${APIurls.archiveAmenity}/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        if (!response.ok) {
          toast.error("Failed to Archive Amenity");
          const result = await response.json();
          throw new Error(result);
        }
        const result = await response.json();
        console.log("Amenity Archive Response", result);
        toast.success("Amenity Archived Successfully");
        refetch();
      } catch (error) {
        console.error(error.message);
      }
      setLoadingStates((prevState) => (
        {
          ...prevState,
          [id]: false,
        }))
    }
  }

  const toggleAmenityStatusHandler = async (id) => {
    const token = await getAccessToken();
    try {
      setToggleLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }))
      const response = await fetch(`${APIurls.toggleAmenityStatus}/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to Update Amenity Status");
        const result = await response.json();
        throw new Error(result);
      }
      const result = await response.json();
      console.log("Amenity Status Update Response", result);
      toast.success("Amenity Status Updated Successfully");
      refetch();
    } catch (error) {
      console.error(error.message);
    }
    setToggleLoadingStates((prevState) => (
      {
        ...prevState,
        [id]: false,
      }))
  }

  if (updateAmenitiesList) {
    refetch();
    setUpdateAmenitiesList(false);
  }

  // const handleSelectStatus = (value) => {
  //   setStatus(value)
  //   setStatusData(amenitiesData?.filter((data) => data.status === value))
  // }

  // useEffect(() => {
  //   if (status === "clear_all") {
  //     setStatusData(amenitiesData)
  //   }
  // }, [status])

  useEffect(() => {
    let mapData = amenitiesData?.map((item) => {
      return { ...item };
    });
    if (searchInput !== "") {
      mapData = mapData.filter((item) => {
        return item?.title?.toLowerCase().includes(searchInput.toLowerCase());
      });
    }
    if (status !== "") {
      if (status === "clear_all") {
        setFilteredData(mapData);
      }
      else if (status === "active") {
        mapData = mapData?.filter((item) => item?.status === "active");
      } else if (status === "inactive") {
        mapData = mapData?.filter((item) => item?.status === "inactive");
      }
    }
    setFilteredData(mapData);
  }, [amenitiesData, searchInput, status]);

  const handleSelectedRows = ({ selectedRows }) => {
    if (amenitiesData && selectedRows.length === amenitiesData.length) {
      setSelectedRows(selectedRows)
    } else if (selectedRows.length > 0) {
      setSelectedRows(selectedRows.reverse())
    } else {
      setSelectedRows(selectedRows)
    }
    console.log("Selected Rows", selectedRows)
  }

  const convertImageToBase64 = async (imagePath) => {
    try {
      const response = await fetch(imagePath);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting image to base64:', error);
      throw error;
    }
  };

  const handleDownloadPDF = async () => {
    setLoading(true);
    try {
      const base64String = await convertImageToBase64(gamebeezLogo);
      setBase64Logo(base64String);

      const pdfData = amenitiesData?.map((item, index) =>
        [
          index + 1,
          item?.title,
          moment(item?.createdAt).format("DD-MM-YYYY, hh:mm A"),
          item?.status,
        ]
      )

      const pdfColumns = ["SL", "Title", "Date and Time", "Status"]

      await DownloadPDF(pdfData, pdfColumns, "Amenities.pdf", base64String, adminName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const excelData = amenitiesData?.map((item, index) => [
    index + 1,
    item?.title,
    moment(item?.createdAt).format("DD-MM-YYYY, hh:mm A"),
    item?.status,
  ])

  const excelColumns = ["SL", "Title", "Date and Time", "Status"]

  return (
    <div className='flex flex-col gap-[0.5rem]'>
      <div className='flex justify-between items-center border-t border-[#EAECF0] pt-[1.5rem]'>
        <div className='flex items-center gap-[3rem]'>
          <h2 className='text-black text-[1.3rem] font-semibold'>Manage Amenities</h2>
          <div className="flex py-[0.4rem] px-[1rem] bg-[#FFF] border border-[#DFDFDF] rounded-md">
            <input
              type="text"
              className="w-[18rem] placeholder-[#8F8F8F] text-black text-sm font-semibold outline-none"
              placeholder="Search by Title"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput !== "" ?
              <RxCross2
                className='text-[1.4rem] bg-primary text-white rounded-md cursor-pointer'
                onClick={() => setSearchInput("")}
              />
              :
              <Icon
                icon="ri:search-2-fill"
                className="p-[0.2rem] bg-primary text-white text-[1.5rem] rounded-[5px] cursor-pointer"
              />
            }
          </div>
        </div>
        <div className='flex items-center gap-[2rem]'>
          <div className='flex gap-3 items-center rounded-md'>
            {loading ? (
              <p className="text-xs">Generating PDF...</p>
            ) : (
              <img
                src={pdfLogo}
                className='w-[25px] cursor-pointer'
                onClick={handleDownloadPDF}
                alt="Generate PDF"
              />
            )}
            <FaFileCsv
              className='w-[25px] h-[30px] text-green-800 cursor-pointer'
              onClick={() => DownloadExcel({ excelData, excelColumns })}
            />
          </div>
          <div className='flex items-center gap-[0.3rem] hover:text-primary cursor-pointer'>
            <Select label='Change Status'
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value='active' disabled={status === "active"}>Active</Option>
              <Option value='inactive' disabled={status === "inactive"}>Inactive</Option>
              <Option value='clear_all' disabled={status === "clear_all"}>Clear All</Option>
            </Select>
          </div>
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={filteredData}
          pagination
          selectableRows
          paginationPerPage={10}
          progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
          onSelectedRowsChange={handleSelectedRows}
        />
      </div>
      <ToastContainer />
    </div>
  )
}



